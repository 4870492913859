import * as y from "react";
import { forwardRef as ee, createElement as V, useState as q, useEffect as $e, Fragment as Ge, useMemo as Yt, createContext as Rn } from "react";
import { jsx as l, jsxs as g, Fragment as P } from "react/jsx-runtime";
import { Transition as Le, Dialog as Ot, Combobox as Me, Listbox as Be, Menu as Pe } from "@headlessui/react";
import { Link as An } from "react-router-dom";
import { useDropzone as Zn } from "react-dropzone";
const Kr = "M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z";
function Xr({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "-32 -32 580 580",
        strokeWidth: 16,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: r,
        "aria-labelledby": e
      },
      n
    ),
    t ? /* @__PURE__ */ V("title", { id: e }, t) : null,
    /* @__PURE__ */ V("path", {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: Kr
    })
  );
}
const Gt = ee(Xr);
function k(...t) {
  return t.filter(Boolean).join(" ");
}
let Jt;
function Ao(t) {
  Jt = t;
}
function Zo(t, e) {
  return t == null || typeof t != "string" || t.length <= e ? t : t.slice(0, e - 3) + "...";
}
function Hn(t) {
  switch (t) {
    case 6:
      return "grid grid-cols-1 sm:grid-cols-6";
    case 12:
      return "grid grid-cols-1 sm:grid-cols-12";
    case 1:
      return "grid grid-cols-1 sm:grid-cols-1";
    case 2:
      return "grid grid-cols-1 sm:grid-cols-2";
    case 3:
      return "grid grid-cols-1 sm:grid-cols-3";
    case 4:
      return "grid grid-cols-1 sm:grid-cols-4";
    case 5:
      return "grid grid-cols-1 sm:grid-cols-5";
    case 7:
      return "grid grid-cols-1 sm:grid-cols-7";
    case 8:
      return "grid grid-cols-1 sm:grid-cols-8";
    case 9:
      return "grid grid-cols-1 sm:grid-cols-9";
    case 10:
      return "grid grid-cols-1 sm:grid-cols-10";
    case 11:
      return "grid grid-cols-1 sm:grid-cols-11";
    default:
      return "grid grid-cols-1 sm:grid-cols-12";
  }
}
function Ho(t) {
  switch (t) {
    case 1:
      return "col-span-1";
    case 2:
      return "col-span-2";
    case 3:
      return "col-span-3";
    case 4:
      return "col-span-4";
    case 5:
      return "col-span-5";
    case 6:
      return "col-span-6";
    case 7:
      return "col-span-7";
    case 8:
      return "col-span-8";
    case 9:
      return "col-span-9";
    case 10:
      return "col-span-10";
    case 11:
      return "col-span-11";
    default:
      return "col-span-12";
  }
}
function ae(t) {
  switch (t) {
    case 1:
      return "sm:col-span-1";
    case 2:
      return "sm:col-span-2";
    case 3:
      return "sm:col-span-3";
    case 4:
      return "sm:col-span-4";
    case 5:
      return "sm:col-span-5";
    case 6:
      return "sm:col-span-6";
    case 7:
      return "sm:col-span-7";
    case 8:
      return "sm:col-span-8";
    case 9:
      return "sm:col-span-9";
    case 10:
      return "sm:col-span-10";
    case 11:
      return "sm:col-span-11";
    default:
      return "sm:col-span-12";
  }
}
function Bo(t) {
  switch (t) {
    case 1:
      return "md:col-span-1";
    case 2:
      return "md:col-span-2";
    case 3:
      return "md:col-span-3";
    case 4:
      return "md:col-span-4";
    case 5:
      return "md:col-span-5";
    case 6:
      return "md:col-span-6";
    case 7:
      return "md:col-span-7";
    case 8:
      return "md:col-span-8";
    case 9:
      return "md:col-span-9";
    case 10:
      return "md:col-span-10";
    case 11:
      return "md:col-span-11";
    default:
      return "md:col-span-12";
  }
}
function Po(t) {
  switch (t) {
    case 1:
      return "lg:col-span-1";
    case 2:
      return "lg:col-span-2";
    case 3:
      return "lg:col-span-3";
    case 4:
      return "lg:col-span-4";
    case 5:
      return "lg:col-span-5";
    case 6:
      return "lg:col-span-6";
    case 7:
      return "lg:col-span-7";
    case 8:
      return "lg:col-span-8";
    case 9:
      return "lg:col-span-9";
    case 10:
      return "lg:col-span-10";
    case 11:
      return "lg:col-span-11";
    default:
      return "lg:col-span-12";
  }
}
async function jo(t) {
  return new Promise((e) => setTimeout(e, t));
}
async function Uo(t) {
  return t == null ? void 0 : await new Promise((n, r) => {
    const s = new FileReader();
    s.addEventListener(
      "load",
      async () => {
        var a;
        n((a = s.result) == null ? void 0 : a.toString());
      },
      !1
    ), s.addEventListener(
      "error",
      (a) => {
        r(a);
      },
      !1
    ), t ? s.readAsDataURL(t) : n(void 0);
  });
}
const es = () => {
}, ts = (t, e) => {
  const { children: n, onClick: r = es, href: s, target: a, label: i, title: o, ariaLabel: c, Icon: u, className: d, iconClassName: m, small: f = !1, rounded: h = !1, waiting: x = !1, disabled: S = x, primary: w = !1, warning: v = !1, transparent: C = !1, cancel: T = !1, autofocus: F = !1 } = t, O = k(
    "twc-button",
    S ? "twc-disabled opacity-50 disabled:cursor-not-allowed cursor-not-allowed hover:ring-0" : "",
    f ? "text-xs" : "text-sm",
    v ? "twc-warning" : T ? "twc-cancel" : w ? "twc-primary" : C ? "twc-transparent" : "twc-secondary",
    h ? "!rounded-full" : "",
    h ? f ? "p-1" : "p-2" : f ? "px-1.5 py-1" : "px-3 py-2",
    d
  ), z = k(
    f ? "w-3 h-3" : "w-5 h-5",
    i == null ? "" : f ? "mr-1" : "mr-2",
    x ? "spin" : "",
    m
  ), W = u == null ? "" : f ? "pl-0.5" : "pl-2", Z = i != null || u != null ? /* @__PURE__ */ g("div", { className: "flex items-center", children: [
    x ? /* @__PURE__ */ l(Gt, { className: z }) : u != null && /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ l(u, { "aria-hidden": "true", className: z }),
      c != null && /* @__PURE__ */ l("span", { className: "sr-only", children: c })
    ] }),
    i != null && /* @__PURE__ */ l("div", { className: W, children: i })
  ] }) : /* @__PURE__ */ l(P, { children: n });
  return s == null || s.length == 0 ? /* @__PURE__ */ l(
    "button",
    {
      type: "button",
      disabled: S,
      onClick: r,
      autoFocus: F,
      className: O,
      title: o,
      ref: e,
      children: Z
    }
  ) : /* @__PURE__ */ l(
    "a",
    {
      href: S ? void 0 : s,
      autoFocus: F,
      target: S ? void 0 : a,
      onClick: r,
      className: O,
      title: o,
      ref: e,
      children: Z
    }
  );
}, A = ee(ts);
function qo(t) {
  const { small: e = !1, transparent: n = !1, white: r = !1, src: s, onClick: a, className: i = e ? "p-1" : "p-2", iconClassName: o = e ? "w-3 h-3" : "w-5 h-5", disabled: c = !1, primary: u = !1, warning: d = !1, rounded: m = !1, cancel: f = !1 } = t, h = k(
    "twc-button",
    c ? "opacity-50 cursor-not-allowed" : "",
    m ? "!rounded-full" : "",
    n ? "twc-transparent" : r ? "twc-white" : d ? "twc-warning" : f ? "twc-cancel" : u ? "twc-primary" : "twc-secondary",
    i
  );
  return /* @__PURE__ */ l(
    "button",
    {
      type: "button",
      onClick: a,
      className: h,
      children: /* @__PURE__ */ l("img", { src: s, className: o })
    }
  );
}
function ns({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
  }));
}
const rs = y.forwardRef(ns), Zt = rs;
function ss({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
  }), /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
  }));
}
const as = y.forwardRef(ss), is = as;
function os({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
  }));
}
const ls = y.forwardRef(os), Dt = ls;
function cs({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
  }));
}
const us = y.forwardRef(cs), It = us;
function ds({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "m19.5 8.25-7.5 7.5-7.5-7.5"
  }));
}
const ms = y.forwardRef(ds), hs = ms;
function fs({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M15.75 19.5 8.25 12l7.5-7.5"
  }));
}
const gs = y.forwardRef(fs), Je = gs;
function ws({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "m8.25 4.5 7.5 7.5-7.5 7.5"
  }));
}
const ys = y.forwardRef(ws), Fe = ys;
function ps({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
  }));
}
const ks = y.forwardRef(ps), xs = ks;
function vs({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
  }));
}
const bs = y.forwardRef(vs), Bn = bs;
function Ns({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
  }));
}
const Ts = y.forwardRef(Ns), _t = Ts;
function Ss({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
  }));
}
const Cs = y.forwardRef(Ss), Ms = Cs;
function Os({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
  }));
}
const Ds = y.forwardRef(Os), Is = Ds;
function Es({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
  }));
}
const Ls = y.forwardRef(Es), Fs = Ls;
function Ws({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
  }));
}
const Vs = y.forwardRef(Ws), $s = Vs;
function zs({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
  }));
}
const Rs = y.forwardRef(zs), As = Rs;
function Zs({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
  }));
}
const Hs = y.forwardRef(Zs), Bs = Hs;
function Ps({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    strokeWidth: 1.5,
    stroke: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    d: "M6 18 18 6M6 6l12 12"
  }));
}
const js = y.forwardRef(Ps), Pn = js;
function st(t) {
  const { openBinding: e, open: n, hFull: r = !1, hideClose: s = !1, onClose: a, autofocus: i, title: o, Icon: c, wide: u = !1, message: d, iconClassName: m = "w-10 h-10 text-gray-900 dark:text-white", children: f, buttons: h } = t, [x, S] = e ?? [n ?? !1, () => {
    a != null && a();
  }], w = 0.75, [v, C] = q(Math.floor((window.innerHeight ?? 0) * w)), [T, F] = q(Math.floor((window.innerWidth ?? 0) * w)), O = () => {
    C(Math.floor((window.innerHeight ?? 0) * w)), F(Math.floor((window.innerWidth ?? 0) * w));
  };
  $e(() => (window.addEventListener("resize", O), () => {
    window.removeEventListener("resize", O);
  }), []), $e(() => {
    x && i != null && setTimeout(() => {
      i != null && i.current != null && i.current.focus();
    }, 0);
  }, [x, i]);
  const z = k(
    "relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-dark-800 sm:my-8 sm:w-full sm:p-6",
    u ? "sm:w-full" : "sm:max-w-lg sm:w-full"
  );
  return /* @__PURE__ */ l(Le.Root, { show: x, as: Ge, children: /* @__PURE__ */ g(Ot, { as: "div", className: "relative z-10", onClose: () => {
    S(!1);
  }, children: [
    /* @__PURE__ */ l(
      Le.Child,
      {
        as: Ge,
        enter: "ease-out duration-300",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
        children: /* @__PURE__ */ l("div", { className: "fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" })
      }
    ),
    /* @__PURE__ */ l("div", { className: "fixed inset-0 flex items-center justify-center w-screen p-4", children: /* @__PURE__ */ l("div", { className: "max-w-sm p-4 mx-auto text-center sm:max-w-md md:max-w-xl lg:max-w-[64rem] sm:p-0", children: /* @__PURE__ */ l(
      Le.Child,
      {
        as: Ge,
        enter: "ease-out duration-300",
        enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        enterTo: "opacity-100 translate-y-0 sm:scale-100",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
        leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        children: /* @__PURE__ */ g(Ot.Panel, { className: z, style: u ? { height: `${v}px`, width: `${T}px` } : void 0, children: [
          !s && /* @__PURE__ */ l("div", { className: "absolute top-0 right-0 hidden pt-4 pr-4 -mt-2 -mr-2 sm:block", children: /* @__PURE__ */ l(A, { Icon: Pn, transparent: !0, rounded: !0, ariaLabel: "Close", onClick: () => {
            S(!1);
          } }) }),
          /* @__PURE__ */ g("div", { className: "sm:flex sm:items-center", children: [
            c != null && /* @__PURE__ */ l("div", { className: "flex items-center justify-center flex-shrink-0 mx-auto rounded-full sm:mr-3 sm:mx-0 sm:h-10 sm:w-10", children: /* @__PURE__ */ l(c, { className: m, "aria-hidden": "true" }) }),
            /* @__PURE__ */ l("div", { className: "mt-3 text-center sm:mt-0 sm:text-left", children: /* @__PURE__ */ l(Ot.Title, { as: "h3", className: "text-xl font-semibold leading-6 text-gray-900 dark:text-white", children: o }) })
          ] }),
          d != null ? /* @__PURE__ */ l("div", { className: "pt-2 text-sm text-gray-700 dark:text-dark-300", children: d }) : /* @__PURE__ */ l("div", { className: k("pt-2 text-sm text-gray-700 dark:text-dark-300", r ? "h-full" : ""), children: f }),
          h != null && /* @__PURE__ */ l(Js, { children: h })
        ] })
      }
    ) }) })
  ] }) });
}
function Yo(t) {
  const { children: e, noGrid: n = !1, autofocus: r, className: s = "gap-x-6 gap-y-8", columns: a = 12 } = t, i = k("twc-form", n ? "" : Hn(a), s);
  return $e(() => {
    r != null && setTimeout(() => {
      r != null && r.current != null && r.current.focus();
    }, 0);
  }, [r]), /* @__PURE__ */ l("form", { className: i, onSubmit: (o) => o.preventDefault(), children: e });
}
const Us = (t, e) => {
  const { id: n, name: r, type: s = "text", outerClassName: a, binding: i, label: o, onBlur: c, updateOnBlur: u = !1, autoComplete: d, value: m, placeholder: f, className: h, autofocus: x = !1, invalid: S = !1, disabled: w = !1, Icon: v, columns: C = 6, onChange: T } = t, [F, O] = q(m ?? ""), z = u ? F : m ?? (i != null ? i[0] : void 0), W = T ?? (i != null ? i[1] : void 0), Z = k(ae(C), a);
  $e(() => {
    O(m ?? "");
  }, [m]);
  const b = k(
    w ? "opacity-50 cursor-not-allowed disabled:cursor-not-allowed" : "",
    // invalid ? 'border focus-visible:border-2 bg-red-100 ring-red-500 border-red-500 focus-visible:border-red-500 focus-visible:ring-red-600' : '',
    S && v == null ? "twc-invalid" : "",
    h,
    v != null ? "twc-inner-input" : ""
  ), R = k(
    "flex items-center twc-pseudoinput",
    S ? "twc-invalid" : ""
  ), $ = {};
  return n != null && ($.id = n), r != null && ($.name = r), b !== "" && ($.className = b), z != null && ($.value = z), d != null && ($.autoComplete = d), u ? $.onChange = (E) => {
    O(E.target.value);
  } : !u && W != null && ($.onChange = (E) => {
    W(E.target.value);
  }), f != null && ($.placeholder = f), x && ($.autoFocus = x), u && W != null ? $.onBlur = (E) => {
    E.target.value !== m && W(E.target.value);
  } : c != null && ($.onBlur = (E) => {
    c(E.target.value);
  }), /* @__PURE__ */ g("div", { className: Z, children: [
    o != null && /* @__PURE__ */ l("label", { htmlFor: n, children: o }),
    /* @__PURE__ */ l("div", { className: o != null ? "mt-2" : "", children: v == null ? /* @__PURE__ */ l(
      "input",
      {
        ref: e,
        type: s,
        disabled: w,
        ...$
      }
    ) : /* @__PURE__ */ g("div", { className: R, children: [
      /* @__PURE__ */ l(v, { className: "w-5 h-5 mr-2 text-gray-500 dark:text-dark-300" }),
      /* @__PURE__ */ l(
        "input",
        {
          ref: e,
          type: s,
          disabled: w,
          ...$
        }
      )
    ] }) })
  ] });
}, jn = ee(Us), qs = (t, e) => {
  const { show: n = !1, ...r } = t;
  return /* @__PURE__ */ l(jn, { type: n ? "text" : "password", ref: e, ...r });
}, Go = ee(qs), Ys = (t, e) => {
  const { ...n } = t;
  return /* @__PURE__ */ l(jn, { ref: e, type: "email", ...n });
}, Jo = ee(Ys), Gs = (t, e) => {
  const { id: n, name: r, rows: s = 3, label: a, binding: i, style: o, autoComplete: c, value: u, placeholder: d, helpText: m, onBlur: f, className: h, autofocus: x = !1, code: S = !1, updateOnBlur: w = !1, invalid: v = !1, disabled: C = !1, columns: T = 6, onChange: F, readonly: O = i == null && F == null } = t, [z, W] = q(u ?? ""), Z = w ? z : u ?? (i != null ? i[0] : void 0), b = F ?? (i != null ? i[1] : void 0), R = ae(T);
  $e(() => {
    W(u ?? "");
  }, [u]);
  const $ = k(
    C ? "opacity-50 cursor-not-allowed" : "",
    // invalid ? 'border-1 focus:border-2 bg-red-100 ring-red-500 border-red-500 focus:border-red-500 focus:ring-red-600' : '',
    v ? "twc-invalid" : "",
    S ? "font-mono" : "",
    h
  ), E = {};
  return n != null && (E.id = n), r != null && (E.name = r), $ !== "" && (E.className = $), c != null && (E.autoComplete = c), Z != null && (E.value = Z), w ? E.onChange = (H) => {
    W(H.target.value);
  } : !w && b != null && (E.onChange = (H) => {
    b(H.target.value);
  }), d != null && (E.placeholder = d), x && (E.autoFocus = x), w && b != null ? E.onBlur = (H) => {
    H.target.value !== u && b(H.target.value);
  } : f != null && (E.onBlur = (H) => {
    f(H.target.value);
  }), /* @__PURE__ */ g("div", { className: R, children: [
    a != null && /* @__PURE__ */ l("label", { htmlFor: n, children: a }),
    /* @__PURE__ */ l("div", { className: "mt-2", children: /* @__PURE__ */ l(
      "textarea",
      {
        ref: e,
        rows: s,
        disabled: C,
        readOnly: O,
        style: o,
        ...E
      }
    ) }),
    m != null && /* @__PURE__ */ l("p", { className: "mt-0 text-sm leading-6 text-gray-600 dark:text-dark-300", children: m })
  ] });
}, _o = ee(Gs);
function Js(t) {
  const { children: e, columns: n = 12, className: r = "mt-5 sm:mt-4 sm:flex sm:justify-end" } = t, s = ae(n), a = k(s, r, "twc-button-wrapper");
  return /* @__PURE__ */ l("div", { className: a, children: e });
}
function Qo(t) {
  const { id: e, name: n, label: r, value: s, binding: a, helpText: i, className: o, disabled: c = !1, columns: u = 6, onChange: d, children: m } = t, f = s ?? (a != null ? a[0] : void 0), h = d ?? (a != null ? a[1] : void 0), x = ae(u), S = k(
    "w-4 h-4",
    c ? "opacity-50 cursor-not-allowed" : "",
    o
  ), w = {};
  return e != null && (w.id = e), n != null && (w.name = n), S !== "" && (w.className = S), f != null && (w.checked = f), h != null && (w.onChange = (v) => {
    h(v.target.checked);
  }), /* @__PURE__ */ g("div", { className: x, children: [
    r != null && /* @__PURE__ */ l("label", { htmlFor: e, children: r }),
    /* @__PURE__ */ g("div", { className: "flex items-center h-8 mt-2", children: [
      /* @__PURE__ */ l(
        "input",
        {
          ...w,
          type: "checkbox",
          onChange: (v) => {
            d != null && d(v.target.checked);
          }
        }
      ),
      i != null && /* @__PURE__ */ l("label", { htmlFor: e, className: "ml-3", children: i })
    ] }),
    m
  ] });
}
function Ko(t) {
  const { id: e, name: n, type: r = "url", label: s, autoComplete: a, value: i, placeholder: o, className: c, disabled: u = !1, columns: d = 6, onChange: m } = t, f = ae(d), h = k(
    u ? "opacity-50 cursor-not-allowed" : "",
    "twc-inner-input",
    c
  );
  return /* @__PURE__ */ g("div", { className: f, children: [
    s != null && /* @__PURE__ */ l("label", { htmlFor: e, children: s }),
    /* @__PURE__ */ l("div", { className: "mt-2", children: /* @__PURE__ */ g("div", { className: "!flex items-center twc-pseudoinput", children: [
      /* @__PURE__ */ l("img", { className: "w-8 h-8 mr-2 mt-0.5 text-gray-300 rounded-full", "aria-hidden": "true", src: i }),
      /* @__PURE__ */ l(
        "input",
        {
          type: "url",
          name: "picture",
          id: "picture",
          autoComplete: "picture",
          className: h,
          placeholder: "picture url",
          value: i,
          onChange: (x) => {
            m != null && m(x.target.value);
          }
        }
      )
    ] }) })
  ] });
}
function Xo(t) {
  const { children: e, className: n = "gap-x-6 gap-y-8", columns: r = 12 } = t, s = k("twc-form", Hn(r), n);
  return /* @__PURE__ */ l("div", { className: s, children: e });
}
function el(t) {
  const { children: e, buttons: n, divider: r = !1, border: s = !1, title: a, subtitle: i, className: o = "", columns: c = 12 } = t, u = k(
    "overflow-hidden py-6",
    s ? "border border-gray-100 bg-gray-50 dark:border-dark-600 dark:bg-dark-700 sm:rounded-lg px-4 py-5 sm:px-6" : "",
    ae(c),
    o
  ), d = k(
    r ? "border-b border-gray-200 dark:border-dark-600" : "",
    s ? "-mx-4 -mt-4 sm:-mx-6" : "",
    "flex flex-wrap items-center justify-between sm:flex-nowrap"
    // w-full'
  ), m = k(
    // divider ? 'border-b border-gray-200 dark:border-dark-700' : '',
    s ? "mx-4 mt-4 sm:mx-6" : ""
    // 'w-full'
  ), f = k(
    // divider ? 'border-b border-gray-200 dark:border-dark-700' : '',
    s ? "mx-4 mt-4 sm:mx-6 flex-shrink-0" : ""
    // 'w-full'
  ), h = k(
    "text-base font-semibold leading-6 text-gray-900 dark:text-white mb-3 text-left"
    // border ? 'mx-4' : ''
  ), x = k(
    "text-sm text-gray-500 text-left mb-3 -mt-1"
    // border ? 'mx-4' : ''
  );
  return /* @__PURE__ */ g("div", { className: u, children: [
    /* @__PURE__ */ g("div", { className: d, children: [
      /* @__PURE__ */ g("div", { className: m, children: [
        a != null && /* @__PURE__ */ l("h3", { className: h, children: a }),
        i != null && /* @__PURE__ */ l("p", { className: x, children: i })
      ] }),
      n != null && /* @__PURE__ */ l("div", { className: f, children: n })
    ] }),
    /* @__PURE__ */ l("div", { className: "pt-3", children: e })
  ] });
}
function Et(t) {
  return /* @__PURE__ */ l("div", { className: "flex flex-col flex-wrap items-center w-full py-4 text-sm text-gray-500 justify-items-center sm:flex-nowrap bg-gray-50 dark:bg-dark-700 dark:text-dark-400 sm:rounded-lg", children: /* @__PURE__ */ l("div", { className: "flex flex-row items-center justify-between w-auto align-center", children: t.children }) });
}
function _s(t) {
  var e, n;
  return t == null ? "" : typeof t == "string" ? t : t.error != null && typeof t.error == "string" ? t.originalStatus != null && t.data != null ? `${t.originalStatus} (${t.data})` : t.error : t.message != null && typeof t.message == "string" ? t.message : ((e = t.data) == null ? void 0 : e.message) != null && Number.isInteger(t.status) ? `${t.status} (${(n = t.data) == null ? void 0 : n.message})` : (console.log("unserializable error", t), "null");
}
function tl(t) {
  const { children: e, isLoading: n = !1, isError: r = !1, data: s, error: a, emptyMessage: i = "Empty" } = t, { isEmpty: o = s != null && s.length === 0 } = t;
  return /* @__PURE__ */ l(P, { children: n ? /* @__PURE__ */ g(Et, { children: [
    /* @__PURE__ */ l(Gt, { className: "w-10 h-10 text-brand-500 dark:text-brand-400 spin" }),
    /* @__PURE__ */ l("div", { className: "ml-2", children: "Loading" })
  ] }) : r ? /* @__PURE__ */ g(Et, { children: [
    /* @__PURE__ */ l(Bn, { className: "w-10 h-10 text-red-400 dark:text-red-600" }),
    /* @__PURE__ */ g("div", { className: "ml-2", children: [
      "Error",
      a != null ? ": " : "",
      _s(a)
    ] })
  ] }) : o ? /* @__PURE__ */ g(Et, { children: [
    /* @__PURE__ */ l(_t, { className: "w-10 h-10" }),
    /* @__PURE__ */ l("div", { className: "ml-2", children: i })
  ] }) : /* @__PURE__ */ l(P, { children: e }) });
}
function Qs(t) {
  switch (t) {
    case "red":
      return "text-red-400 dark:text-red-600";
    case "yellow":
      return "text-yellow-400 dark:text-yellow-600";
    case "green":
      return "text-green-400 dark:text-green-600";
    case "blue":
      return "text-blue-400 dark:text-blue-600";
    case "indigo":
      return "text-indigo-400 dark:text-indigo-600";
    case "purple":
      return "text-purple-400 dark:text-purple-600";
    case "pink":
      return "text-pink-400 dark:text-pink-600";
    default:
      return "text-gray-400 dark:text-dark-600";
  }
}
function on(t) {
  const { Icon: e = _t, iconColor: n = "", title: r, className: s = "" } = t, a = k("flex flex-col flex-wrap items-center w-full py-4 text-sm text-gray-500 justify-items-center sm:flex-nowrap bg-gray-50 dark:bg-dark-700 dark:text-dark-400 sm:rounded-lg", s), i = k("w-10 h-10", Qs(n));
  return /* @__PURE__ */ l("div", { className: a, children: /* @__PURE__ */ g("div", { className: "flex flex-row items-center justify-between w-auto align-center", children: [
    /* @__PURE__ */ l(e, { className: i }),
    /* @__PURE__ */ l("div", { className: "ml-2", children: r })
  ] }) });
}
function nl(t) {
  const { title: e, subtitle: n, breadcrumbs: r, Icon: s, children: a, className: i = "mb-6" } = t, o = k("sm:flex sm:items-start sm:justify-between", i);
  return /* @__PURE__ */ g(P, { children: [
    r != null && /* @__PURE__ */ l(P, { children: r }),
    /* @__PURE__ */ g("div", { className: o, children: [
      /* @__PURE__ */ g("div", { className: "flex-1 min-w-0", children: [
        /* @__PURE__ */ g("h2", { className: "flex items-center mt-2 text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-2xl sm:tracking-tight", children: [
          s != null && /* @__PURE__ */ l(s, { className: "inline w-6 h-6 mr-1.5" }),
          e
        ] }),
        n != null && /* @__PURE__ */ l("div", { className: "flex flex-col mt-1 text-sm text-gray-500 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 dark:text-dark-400", children: n })
      ] }),
      a != null && /* @__PURE__ */ l("div", { className: "flex pt-2 sm:mt-0 twc-button-wrapper", children: a })
    ] })
  ] });
}
function rl(t) {
  const { backRoute: e, routes: n, Link: r = Jt ?? An } = t;
  return /* @__PURE__ */ g("div", { children: [
    e != null && /* @__PURE__ */ l(P, { children: n == null || n.length === 0 ? /* @__PURE__ */ l("nav", { "aria-label": "Back", children: /* @__PURE__ */ g(r, { to: e, className: "flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-dark-300 dark:hover:text-white", children: [
      /* @__PURE__ */ l(Je, { className: "flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-gray-400 dark:text-dark-500", "aria-hidden": "true" }),
      "Back"
    ] }) }) : /* @__PURE__ */ l("nav", { className: "sm:hidden", "aria-label": "Back", children: /* @__PURE__ */ g(r, { to: e, className: "flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-dark-300 dark:hover:text-white", children: [
      /* @__PURE__ */ l(Je, { className: "flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-gray-400 dark:text-dark-500", "aria-hidden": "true" }),
      "Back"
    ] }) }) }),
    n != null && n.length > 0 && /* @__PURE__ */ l("nav", { className: "hidden sm:flex", "aria-label": "Breadcrumb", children: /* @__PURE__ */ l("ol", { role: "list", className: "flex items-center space-x-2", children: n.map((s, a) => /* @__PURE__ */ l("li", { children: a === 0 ? /* @__PURE__ */ l("div", { className: "flex", children: s.route != null ? /* @__PURE__ */ g(r, { to: s.route, className: "flex items-center text-sm font-medium text-gray-500 hover:text-brand-600 dark:text-dark-300 dark:hover:text-white", children: [
      s.Icon != null && /* @__PURE__ */ l(s.Icon, { className: "flex-shrink-0 inline w-5 h-5 mr-1", "aria-hidden": "true" }),
      s.name != null && s.name
    ] }) : /* @__PURE__ */ g("div", { className: "flex items-center text-sm font-medium text-gray-400 dark:text-dark-400", children: [
      s.Icon != null && /* @__PURE__ */ l(s.Icon, { className: "flex-shrink-0 inline w-5 h-5 mr-1", "aria-hidden": "true" }),
      s.name != null && s.name
    ] }) }) : /* @__PURE__ */ l("div", { className: "flex", children: s.route != null ? /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ l(Fe, { className: "flex-shrink-0 w-5 h-5 -mr-0.5 text-gray-400 dark:text-dark-500", "aria-hidden": "true" }),
      /* @__PURE__ */ g(r, { to: s.route, className: "flex items-center ml-2 text-sm font-medium text-gray-500 hover:text-brand-600 dark:text-dark-300 dark:hover:text-white", children: [
        s.Icon != null && /* @__PURE__ */ l(s.Icon, { className: "flex-shrink-0 w-5 h-5 mr-1", "aria-hidden": "true" }),
        s.name != null && s.name
      ] })
    ] }) : /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ l(Fe, { className: "flex-shrink-0 w-5 h-5 -mr-0.5 text-gray-400 dark:text-dark-500", "aria-hidden": "true" }),
      /* @__PURE__ */ g("div", { className: "flex items-center ml-2 text-sm font-medium text-gray-400 dark:text-dark-400", children: [
        s.Icon != null && /* @__PURE__ */ l(s.Icon, { className: "flex-shrink-0 inline w-5 h-5 mr-1", "aria-hidden": "true" }),
        s.name != null && s.name
      ] })
    ] }) }) }, a)) }) })
  ] });
}
function sl(t) {
  const { className: e = "", text: n = "Drop file or click here", onDrop: r, onCancel: s, accept: a } = t, i = k("!py-0 !p-0 text-sm gap-x-6 gap-y-8 twc-pseudoinput relative h-full flex flex-row items-center", e), o = async (m) => (console.log(m), m.length !== 1 ? (console.log("expected 1 file, got", m.length), !1) : (console.log("calling onDrop", m[0], r), r != null ? await r(m[0]) : !1)), { isDragActive: c, getRootProps: u, getInputProps: d } = Zn({
    onDrop: (m) => {
      o(m);
    },
    accept: a
  });
  return /* @__PURE__ */ g("div", { className: i, children: [
    !c && /* @__PURE__ */ l("div", { className: "absolute top-0 right-0 hidden pt-1 pr-1 sm:block", children: /* @__PURE__ */ l(
      A,
      {
        Icon: Pn,
        onClick: s,
        rounded: !0,
        small: !0
      }
    ) }),
    /* @__PURE__ */ g("div", { ...u({ className: "dropzone" }), children: [
      /* @__PURE__ */ l("input", { ...d() }),
      c ? /* @__PURE__ */ l(on, { className: "sm:!rounded-md ring-1 ring-inset dark:ring-dark-600 ring-gray-300", Icon: Zt, title: "Drop file here" }) : /* @__PURE__ */ l(on, { className: "sm:!rounded-md ring-1 ring-inset dark:ring-dark-600 ring-gray-300", Icon: Zt, title: n })
    ] })
  ] });
}
const Ks = {
  "image/svg+xml": [".svg"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/webp": [".webp"]
};
function Xs(t) {
  const { className: e = "flex-none w-16 h-16 twc-secondary", round: n = !1, disabled: r = !1, defaultUrl: s, imageClassName: a = "w-16 h-16", svgClassName: i = "w-12 h-12", onDrop: o, url: c, accept: u = Ks, DefaultIcon: d = is, AcceptIcon: m = Zt, RejectIcon: f = Fs, id: h, name: x } = t, [S, w] = q(!1), v = k(
    n ? "!rounded-full" : "",
    r ? "opacity-50 cursor-not-allowed disabled:cursor-not-allowed" : "",
    "twc-button dropzone group !flex flex-row items-center justify-center p-0",
    e
  ), C = k(
    n ? "rounded-full" : "",
    "object-cover",
    a
  ), T = k(
    //round ? 'rounded-full' : '',
    i
  );
  o == null && console.log("Wrning: ImageFileDropButton: onDrop is null");
  const F = async (b) => {
    if (console.log(b), b.length !== 1) {
      console.log("expected 1 file, got", b.length);
      return;
    }
    console.log("calling onDrop", b[0], o), o != null && (w(!0), await o(b[0]), w(!1));
  }, { isDragActive: O, getRootProps: z, getInputProps: W, isDragReject: Z } = Zn({
    onDrop: (b) => {
      F(b);
    },
    accept: u
  });
  return /* @__PURE__ */ g("button", { id: h, name: x, ...z({ className: v }), children: [
    /* @__PURE__ */ l("input", { ...W() }),
    S ? /* @__PURE__ */ l(Gt, { className: k("spin block", T) }) : Z ? /* @__PURE__ */ l(f, { className: k("block text-red-500 group-hover:hidden", T) }) : O ? /* @__PURE__ */ l(m, { className: k("block group-hover:hidden", T) }) : c != null && c !== "" ? /* @__PURE__ */ g("div", { className: "relative", children: [
      /* @__PURE__ */ l("div", { className: "absolute z-10 flex items-center justify-center w-full h-full", children: /* @__PURE__ */ l(m, { className: k("hidden group-hover:block", T) }) }),
      /* @__PURE__ */ l("img", { src: c, className: k("block group-hover:opacity-30", C) })
    ] }) : s != null && s !== "" ? /* @__PURE__ */ g("div", { className: "relative", children: [
      /* @__PURE__ */ l("div", { className: "absolute z-10 flex items-center justify-center w-full h-full", children: /* @__PURE__ */ l(m, { className: T }) }),
      /* @__PURE__ */ l("img", { src: s, className: k("opacity-30", C) })
    ] }) : /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ l(d, { className: k("block group-hover:hidden", T) }),
      /* @__PURE__ */ l(m, { className: k("hidden group-hover:block", T) })
    ] })
  ] });
}
const ea = "M160 80c0 26.5 21.5 48 48 48h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-44.2 0-80-35.8-80-80s35.8-80 80-80H328c66.3 0 120 53.7 120 120s-53.7 120-120 120H272v24c0 8.8-7.2 16-16 16s-16-7.2-16-16V237.5c0-16.3 13.2-29.5 29.5-29.5H328c48.6 0 88-39.4 88-88s-39.4-88-88-88H208c-26.5 0-48 21.5-48 48zm-9 237.4c13.1-8.8 28.6-13.4 44.4-13.4H344c30.9 0 56 25.1 56 56c0 8.6-1.9 16.7-5.4 24h5.6l94.7-56.4c8.3-4.9 17.8-7.6 27.5-7.6h1.3c28.9 0 52.3 23.4 52.3 52.3c0 17.7-9 34.2-23.8 43.8L432.6 493.9c-18.2 11.8-39.4 18.1-61 18.1H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H371.5c15.5 0 30.6-4.5 43.6-12.9l119.6-77.8c5.8-3.7 9.2-10.2 9.2-17c0-11.2-9.1-20.3-20.3-20.3h-1.3c-3.9 0-7.7 1.1-11.1 3l-98.5 58.7c-2.5 1.5-5.3 2.3-8.2 2.3H344 320 256c-8.8 0-16-7.2-16-16s7.2-16 16-16h64 24c13.3 0 24-10.7 24-24s-10.7-24-24-24H195.4c-9.5 0-18.7 2.8-26.6 8.1L88.9 397.3c-2.6 1.8-5.7 2.7-8.9 2.7H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H75.2L151 317.4z";
function ta({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "0 0 576 512",
        strokeWidth: 1,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: r,
        "aria-labelledby": e
      },
      n
    ),
    t ? /* @__PURE__ */ V("title", { id: e }, t) : null,
    /* @__PURE__ */ V("path", {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: ea
    })
  );
}
const na = ee(ta), ra = "M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z";
function sa({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "-32 -32 580 580",
        strokeWidth: 16,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: r,
        "aria-labelledby": e
      },
      n
    ),
    t ? /* @__PURE__ */ V("title", { id: e }, t) : null,
    /* @__PURE__ */ V("path", {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: ra
    })
  );
}
const aa = ee(sa), ia = (t, e) => {
  const { title: n, titleId: r } = t;
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "-32 -32 580 580",
        strokeWidth: 16,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: e,
        "aria-labelledby": r
      },
      t
    ),
    n ? /* @__PURE__ */ V("title", { id: r }, n) : null,
    /* @__PURE__ */ V("path", {
      d: "M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
    })
  );
}, oa = ee(ia), la = (t, e) => {
  const { title: n, titleId: r } = t;
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "aria-hidden": "true",
        ref: e,
        "aria-labelledby": r
      },
      t
    ),
    n ? /* @__PURE__ */ V("title", { id: r }, n) : null,
    /* @__PURE__ */ V("circle", {
      className: "opacity-25",
      cx: 12,
      cy: 12,
      r: 10,
      stroke: "currentColor",
      strokeWidth: 4
    }),
    /* @__PURE__ */ V("path", {
      className: "opacity-75",
      fill: "currentColor",
      d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    })
  );
}, ca = ee(la), ua = "M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM461.4 48L496 82.6 386.2 192.3l-34.6-34.6L461.4 48zM80 429.4L317.7 191.7l34.6 34.6L114.6 464 80 429.4zM427.4 14.1L46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z";
function da({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "0 0 576 512",
        strokeWidth: 1,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: r,
        "aria-labelledby": e
      },
      n
    ),
    t ? /* @__PURE__ */ V("title", { id: e }, t) : null,
    /* @__PURE__ */ V("path", {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: ua
    })
  );
}
const ma = ee(da);
function al(t) {
  const { id: e, url: n, value: r, columns: s = 6, label: a, outerClassName: i, ...o } = t, c = k(ae(s), i);
  return /* @__PURE__ */ g("div", { className: c, children: [
    a != null && /* @__PURE__ */ l("label", { htmlFor: e, children: a }),
    /* @__PURE__ */ l("div", { className: a != null ? "mt-2" : "", children: /* @__PURE__ */ l(
      Xs,
      {
        id: e,
        url: r ?? n,
        ...o
      }
    ) })
  ] });
}
function il(t) {
  const { children: e, columns: n = 12, className: r } = t, s = k(
    ae(n),
    r
  );
  return /* @__PURE__ */ l("div", { className: s, children: e });
}
function ha() {
  localStorage.theme = "dark", document.documentElement.classList.add("dark");
}
function fa() {
  localStorage.theme = "light", document.documentElement.classList.remove("dark");
}
function ga() {
  localStorage.removeItem("theme"), window.matchMedia("(prefers-color-scheme: dark)").matches ? document.documentElement.classList.add("dark") : document.documentElement.classList.remove("dark");
}
function ol({ bottom: t = !1, className: e = "" }) {
  const [n, r] = q(!1);
  return /* @__PURE__ */ g(P, { children: [
    /* @__PURE__ */ l("button", { className: "text-gray-400 dark:text-dark-500", onClick: () => {
      r(!n);
    }, onBlur: () => {
      r(!1);
    }, children: /* @__PURE__ */ l(Ms, { className: k("w-4 h-4", t ? "" : "-mb-0.5", e) }) }),
    /* @__PURE__ */ l("div", { className: "relative", children: n && /* @__PURE__ */ l(
      "div",
      {
        className: k(
          "absolute z-10 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          t ? "bottom-12 origin-bottom-left -left-10" : "origin-top-right right-0"
        ),
        role: "menu",
        "aria-orientation": "vertical",
        "aria-labelledby": "menu-button",
        tabIndex: -1,
        children: /* @__PURE__ */ g("div", { className: "py-1", role: "none", children: [
          /* @__PURE__ */ l("button", { className: "block px-4 py-2 text-sm text-dark-700 hover:text-brand-500", role: "menuitem", onMouseDown: () => {
            r(!1), fa();
          }, children: "Light" }),
          /* @__PURE__ */ l("button", { className: "block px-4 py-2 text-sm text-dark-700 hover:text-brand-500", role: "menuitem", onMouseDown: () => {
            r(!1), ha();
          }, children: "Dark" }),
          /* @__PURE__ */ l("button", { className: "block px-4 py-2 text-sm text-dark-700 hover:text-brand-500", role: "menuitem", onMouseDown: () => {
            r(!1), ga();
          }, children: "System" })
        ] })
      }
    ) })
  ] });
}
const wa = "M64 32C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32h64V416c0-35.3 28.7-64 64-64s64 28.7 64 64v64h64c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H64zM224 416c0-17.7-14.3-32-32-32s-32 14.3-32 32v64h64V416zm-96 96H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256 224 160 128zM64 120c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24V120zm32 8v32h32V128H96zM248 96h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H248c-13.3 0-24-10.7-24-24V120c0-13.3 10.7-24 24-24zm8 64h32V128H256v32zM64 248c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24V248zm32 8v32h32V256H96zm152-32h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H248c-13.3 0-24-10.7-24-24V248c0-13.3 10.7-24 24-24zm8 64h32V256H256v32z";
function ya({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "0 0 384 512",
        strokeWidth: 1,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: r,
        "aria-labelledby": e
      },
      n
    ),
    t ? /* @__PURE__ */ V("title", { id: e }, t) : null,
    /* @__PURE__ */ V("path", {
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: wa
    })
  );
}
const pa = ee(ya);
function ll(t) {
  const { dayInMonth: e, selectedDay: n = /* @__PURE__ */ new Date(), hideTime: r = !1, events: s } = t, a = Yt(() => {
    const c = new Date(e.getFullYear(), e.getMonth(), 1), u = c.getDay(), d = new Date(c.getTime() - u * 864e5), m = [];
    let f = 0;
    for (let h = 0; h < 42; h++) {
      const x = new Date(d.getTime() + h * 864e5), S = x.getMonth() === e.getMonth(), w = x.toDateString() === (/* @__PURE__ */ new Date()).toDateString(), v = n.toDateString() === x.toDateString(), C = s.filter((T) => T.time.toDateString() === x.toDateString()).map((T) => ({
        id: f++,
        name: T.title,
        time: r ? void 0 : T.time.toLocaleTimeString("en-US", {
          hour: "numeric"
          /* , minute: '2-digit' */
        }),
        datetime: T.time.toISOString()
      }));
      m.push({ date: x.toISOString().split("T")[0], isCurrentMonth: S, isToday: w, isSelected: v, events: C });
    }
    return m;
  }, [e, n, s, r]), i = (o) => {
    var c;
    return ((c = o.date.split("-").pop()) == null ? void 0 : c.replace(/^0/, "")) ?? "";
  };
  return /* @__PURE__ */ l("div", { className: "lg:flex lg:h-full lg:flex-col", children: /* @__PURE__ */ g("div", { className: "shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col", children: [
    /* @__PURE__ */ g("div", { className: "grid grid-cols-7 gap-px text-xs font-semibold leading-6 text-center text-gray-700 bg-gray-200 border-b border-gray-300 dark:text-dark-200 dark:bg-dark-800 dark:border-dark-500 lg:flex-none", children: [
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "S",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "un" })
      ] }),
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "M",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "on" })
      ] }),
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "T",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "ue" })
      ] }),
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "W",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "ed" })
      ] }),
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "T",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "hu" })
      ] }),
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "F",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "ri" })
      ] }),
      /* @__PURE__ */ g("div", { className: "py-2 bg-white dark:bg-dark-900", children: [
        "S",
        /* @__PURE__ */ l("span", { className: "sr-only sm:not-sr-only", children: "at" })
      ] })
    ] }),
    /* @__PURE__ */ g("div", { className: "flex text-xs leading-6 text-gray-700 bg-gray-200 dark:bg-dark-700 dark:text-dark-300 lg:flex-auto", children: [
      /* @__PURE__ */ l("div", { className: "hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px", children: a.map((o) => /* @__PURE__ */ g(
        "div",
        {
          className: k(
            o.isCurrentMonth ? "bg-white dark:bg-dark-900" : "bg-gray-50 text-gray-500 dark:bg-dark-800 dark:text-dark-500",
            "relative px-3 py-2"
          ),
          children: [
            /* @__PURE__ */ l(
              "time",
              {
                dateTime: o.date,
                className: o.isToday ? "flex h-6 w-6 items-center justify-center rounded-full bg-brand-600 font-semibold text-white" : void 0,
                children: i(o)
              }
            ),
            o.events.length > 0 && /* @__PURE__ */ g("ol", { className: "mt-2", children: [
              o.events.slice(0, 2).map((c) => /* @__PURE__ */ l("li", { children: /* @__PURE__ */ g("a", { href: c.href, className: "flex group", children: [
                /* @__PURE__ */ l("p", { className: "flex-auto font-medium text-gray-900 truncate dark:text-dark-100 group-hover:text-brand-600", children: c.name }),
                c.time != null && /* @__PURE__ */ l(
                  "time",
                  {
                    dateTime: c.datetime,
                    className: "flex-none hidden ml-3 text-gray-500 dark:text-dark-500 group-hover:text-brand-600 xl:block",
                    children: c.time
                  }
                )
              ] }) }, c.id)),
              o.events.length > 2 && /* @__PURE__ */ g("li", { className: "text-gray-500 dark:text-dark-500", children: [
                "+ ",
                o.events.length - 2,
                " more"
              ] })
            ] })
          ]
        },
        o.date
      )) }),
      /* @__PURE__ */ l("div", { className: "grid w-full grid-cols-7 grid-rows-6 gap-px isolate lg:hidden", children: a.map((o) => /* @__PURE__ */ g(
        "button",
        {
          type: "button",
          className: k(
            o.isCurrentMonth ? "bg-white dark:bg-dark-800" : "bg-gray-50 dark:bg-dark-700",
            (o.isSelected || o.isToday) && "font-semibold",
            o.isSelected && "text-white dark:text-dark-100",
            !o.isSelected && o.isToday && "text-brand-600",
            !o.isSelected && o.isCurrentMonth && !o.isToday && "text-gray-900 dark:text-dark-200",
            !o.isSelected && !o.isCurrentMonth && !o.isToday && "text-gray-500 dark:text-dark-500",
            "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
          ),
          children: [
            /* @__PURE__ */ l(
              "time",
              {
                dateTime: o.date,
                className: k(
                  o.isSelected && "flex h-6 w-6 items-center justify-center rounded-full",
                  o.isSelected && o.isToday && "bg-brand-600",
                  o.isSelected && !o.isToday && "bg-gray-900 dark:bg-dark-100",
                  "ml-auto"
                ),
                children: i(o)
              }
            ),
            /* @__PURE__ */ g("span", { className: "sr-only", children: [
              o.events.length,
              " events"
            ] }),
            o.events.length > 0 && /* @__PURE__ */ l("span", { className: "-mx-0.5 mt-auto flex flex-wrap-reverse", children: o.events.map((c) => /* @__PURE__ */ l("span", { className: "mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400 dark:bg-dark-600" }, c.id)) })
          ]
        },
        o.date
      )) })
    ] })
  ] }) });
}
function ka({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    fillRule: "evenodd",
    d: "M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z",
    clipRule: "evenodd"
  }));
}
const xa = y.forwardRef(ka), Un = xa;
function va({
  title: t,
  titleId: e,
  ...n
}, r) {
  return /* @__PURE__ */ y.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: r,
    "aria-labelledby": e
  }, n), t ? /* @__PURE__ */ y.createElement("title", {
    id: e
  }, t) : null, /* @__PURE__ */ y.createElement("path", {
    fillRule: "evenodd",
    d: "M10.53 3.47a.75.75 0 0 0-1.06 0L6.22 6.72a.75.75 0 0 0 1.06 1.06L10 5.06l2.72 2.72a.75.75 0 1 0 1.06-1.06l-3.25-3.25Zm-4.31 9.81 3.25 3.25a.75.75 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.06-1.06L10 14.94l-2.72-2.72a.75.75 0 0 0-1.06 1.06Z",
    clipRule: "evenodd"
  }));
}
const ba = y.forwardRef(va), qn = ba, cl = (t) => {
  const { id: e, outerClassName: n, binding: r, label: s, value: a, options: i = [], className: o, showClear: c = !1, invalid: u = !1, disabled: d = !1, columns: m = 6, onChange: f, displayProperty: h = "name" } = t, x = r != null ? r[0] : a ?? void 0, S = r != null ? r[1] : f ?? void 0, w = k(ae(m), n), [v, C] = q(""), T = v === "" ? i : i.filter(
    (O) => (O[h] ?? "").toLowerCase().replace(/\s+/g, "").includes(v.toLowerCase().replace(/\s+/g, ""))
  ), F = k(
    d ? "opacity-50 cursor-not-allowed disabled:cursor-not-allowed" : "",
    "flex items-center twc-pseudoinput focus:outline-none",
    u ? "twc-invalid" : "",
    o
  );
  return /* @__PURE__ */ l("div", { className: w, children: /* @__PURE__ */ g(Me, { id: e, as: "div", value: x, onChange: S, children: [
    s != null && s !== "" && /* @__PURE__ */ l(Me.Label, { children: s }),
    /* @__PURE__ */ g("div", { className: k("relative", s != null ? "mt-2" : "", c && "mr-8"), children: [
      /* @__PURE__ */ l(
        Me.Input,
        {
          className: F,
          onChange: (O) => C(O.target.value),
          displayValue: (O) => (O == null ? void 0 : O[h]) ?? ""
        }
      ),
      /* @__PURE__ */ l(Me.Button, { className: "absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none", children: /* @__PURE__ */ l(qn, { className: "w-5 h-5 text-gray-400", "aria-hidden": "true" }) }),
      c && /* @__PURE__ */ l(A, { transparent: !0, disabled: x == null, className: "absolute inset-y-0 -right-8 !py-0 !px-1", Icon: Bs, onClick: () => {
        S == null || S(null), C("");
      } }),
      T.length > 0 && /* @__PURE__ */ l(Me.Options, { className: "absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-dark-900 max-h-60 ring-1 ring-black dark:ring-dark-700 ring-opacity-5 focus:outline-none sm:text-sm", children: T.map((O, z) => /* @__PURE__ */ l(
        Me.Option,
        {
          value: O,
          className: ({ active: W }) => k(
            "relative cursor-default select-none py-2 pl-3 pr-9",
            W ? "bg-indigo-600 text-white" : "text-gray-900 dark:text-white"
          ),
          children: ({ active: W, selected: Z }) => /* @__PURE__ */ g(P, { children: [
            /* @__PURE__ */ l("span", { className: k("block truncate", Z && "font-semibold"), children: O[h] }),
            Z && /* @__PURE__ */ l(
              "span",
              {
                className: k(
                  "absolute inset-y-0 right-0 flex items-center pr-4",
                  W ? "text-white" : "text-indigo-600"
                ),
                children: /* @__PURE__ */ l(Un, { className: "w-5 h-5", "aria-hidden": "true" })
              }
            )
          ] })
        },
        z
      )) })
    ] })
  ] }) });
}, ul = (t) => {
  const { outerClassName: e, binding: n, label: r, value: s, options: a = [], className: i, invalid: o = !1, disabled: c = !1, columns: u = 6, onChange: d, displayProperty: m = "name" } = t, f = n != null ? n[0] : s ?? void 0, h = n != null ? n[1] : d ?? void 0, x = k(ae(u), e), S = k(
    c ? "opacity-50 cursor-not-allowed disabled:cursor-not-allowed" : "",
    "flex items-center twc-pseudoinput focus:outline-none",
    o ? "twc-invalid" : "",
    i
  );
  return /* @__PURE__ */ l("div", { className: x, children: /* @__PURE__ */ l(Be, { value: f, onChange: h, children: ({ open: w }) => /* @__PURE__ */ g(P, { children: [
    r != null && r !== "" && /* @__PURE__ */ l(Be.Label, { className: "mb-2", children: r }),
    /* @__PURE__ */ g("div", { className: "relative", children: [
      /* @__PURE__ */ g(Be.Button, { className: S, children: [
        /* @__PURE__ */ l("span", { className: "block truncate min-h-[24px] pr-4", children: (f == null ? void 0 : f[m]) ?? "" }),
        /* @__PURE__ */ l("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none", children: /* @__PURE__ */ l(qn, { className: "w-5 h-5 text-gray-400", "aria-hidden": "true" }) })
      ] }),
      /* @__PURE__ */ l(
        Le,
        {
          show: w,
          as: Ge,
          leave: "transition ease-in duration-100",
          leaveFrom: "opacity-100",
          leaveTo: "opacity-0",
          children: /* @__PURE__ */ l(Be.Options, { className: "absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-dark-900 max-h-60 ring-1 ring-black dark:ring-dark-700 ring-opacity-5 focus:outline-none sm:text-sm", children: a.map((v, C) => /* @__PURE__ */ l(
            Be.Option,
            {
              className: ({ active: T }) => k(
                T ? "bg-brand-600 text-white" : "",
                "relative cursor-default select-none py-2 pl-3 pr-9"
              ),
              value: v,
              children: ({ selected: T, active: F }) => /* @__PURE__ */ g(P, { children: [
                /* @__PURE__ */ l("span", { className: k(T ? "font-semibold" : "font-normal", "block truncate"), children: v[m] }),
                T ? /* @__PURE__ */ l(
                  "span",
                  {
                    className: k(
                      F ? "text-white" : "text-brand-600",
                      "absolute inset-y-0 right-0 flex items-center pr-4"
                    ),
                    children: /* @__PURE__ */ l(Un, { className: "w-5 h-5", "aria-hidden": "true" })
                  }
                ) : null
              ] })
            },
            C
          )) })
        }
      )
    ] })
  ] }) }) });
};
function dl(t) {
  const { label: e, value: n, binding: r, helpText: s, className: a, columns: i = 6, onChange: o } = t, c = n ?? (r != null ? r[0] : void 0), u = o ?? (r != null ? r[1] : void 0), d = ae(i), m = k(
    d,
    a
  );
  return /* @__PURE__ */ g("div", { className: m, children: [
    e != null && /* @__PURE__ */ l("label", { children: e }),
    /* @__PURE__ */ g("div", { className: "flex items-center flex-wrap gap-2 mt-2", children: [
      Object.entries(t.options).map(([f, h]) => /* @__PURE__ */ l(A, { className: "text-wrap", label: h, primary: c === f, onClick: () => {
        u && u(f);
      } }, f)),
      s != null && /* @__PURE__ */ l("label", { className: "ml-3", children: s })
    ] })
  ] });
}
function ml(t) {
  const { children: e, show: n = !0, message: r = "Loading...", Icon: s = ca } = t;
  return /* @__PURE__ */ g(P, { children: [
    /* @__PURE__ */ l("div", { className: "absolute inset-0 top-0 bottom-0 left-0 right-0 z-10 items-center justify-center bg-gray-100/70 dark:bg-dark-800/70 " + (n ? "flex" : "hidden"), children: /* @__PURE__ */ g("div", { className: "flex items-center p-6 bg-brand-200/80 dark:bg-brand-700/80 rounded-xl gap-x-4", children: [
      /* @__PURE__ */ l(s, { className: "w-12 h-12 animate-spin" }),
      /* @__PURE__ */ l("div", { className: "text-xl", children: r })
    ] }) }),
    e
  ] });
}
function Na(t) {
  const { open: e, className: n, style: r, children: s } = t;
  return /* @__PURE__ */ l(
    Le,
    {
      as: "div",
      show: e,
      enter: "ease-out duration-100",
      enterFrom: "opacity-0",
      enterTo: "opacity-100",
      leave: "ease-in duration-100",
      leaveFrom: "opacity-100",
      leaveTo: "opacity-0",
      children: /* @__PURE__ */ l("div", { style: r, className: k(n, "absolute z-10 mt-1 overflow-auto px-2 pb-2 bg-white dark:bg-dark-700 rounded-md shadow-lg ring-1 ring-black dark:ring-dark-600 ring-opacity-5 focus:outline-none "), children: s })
    }
  );
}
class Se extends Error {
}
class Ta extends Se {
  constructor(e) {
    super(`Invalid DateTime: ${e.toMessage()}`);
  }
}
class Sa extends Se {
  constructor(e) {
    super(`Invalid Interval: ${e.toMessage()}`);
  }
}
class Ca extends Se {
  constructor(e) {
    super(`Invalid Duration: ${e.toMessage()}`);
  }
}
class Ee extends Se {
}
class Yn extends Se {
  constructor(e) {
    super(`Invalid unit ${e}`);
  }
}
class X extends Se {
}
class we extends Se {
  constructor() {
    super("Zone is an abstract class");
  }
}
const p = "numeric", de = "short", te = "long", gt = {
  year: p,
  month: p,
  day: p
}, Gn = {
  year: p,
  month: de,
  day: p
}, Ma = {
  year: p,
  month: de,
  day: p,
  weekday: de
}, Jn = {
  year: p,
  month: te,
  day: p
}, _n = {
  year: p,
  month: te,
  day: p,
  weekday: te
}, Qn = {
  hour: p,
  minute: p
}, Kn = {
  hour: p,
  minute: p,
  second: p
}, Xn = {
  hour: p,
  minute: p,
  second: p,
  timeZoneName: de
}, er = {
  hour: p,
  minute: p,
  second: p,
  timeZoneName: te
}, tr = {
  hour: p,
  minute: p,
  hourCycle: "h23"
}, nr = {
  hour: p,
  minute: p,
  second: p,
  hourCycle: "h23"
}, rr = {
  hour: p,
  minute: p,
  second: p,
  hourCycle: "h23",
  timeZoneName: de
}, sr = {
  hour: p,
  minute: p,
  second: p,
  hourCycle: "h23",
  timeZoneName: te
}, ar = {
  year: p,
  month: p,
  day: p,
  hour: p,
  minute: p
}, ir = {
  year: p,
  month: p,
  day: p,
  hour: p,
  minute: p,
  second: p
}, or = {
  year: p,
  month: de,
  day: p,
  hour: p,
  minute: p
}, lr = {
  year: p,
  month: de,
  day: p,
  hour: p,
  minute: p,
  second: p
}, Oa = {
  year: p,
  month: de,
  day: p,
  weekday: de,
  hour: p,
  minute: p
}, cr = {
  year: p,
  month: te,
  day: p,
  hour: p,
  minute: p,
  timeZoneName: de
}, ur = {
  year: p,
  month: te,
  day: p,
  hour: p,
  minute: p,
  second: p,
  timeZoneName: de
}, dr = {
  year: p,
  month: te,
  day: p,
  weekday: te,
  hour: p,
  minute: p,
  timeZoneName: te
}, mr = {
  year: p,
  month: te,
  day: p,
  weekday: te,
  hour: p,
  minute: p,
  second: p,
  timeZoneName: te
};
class Ke {
  /**
   * The type of zone
   * @abstract
   * @type {string}
   */
  get type() {
    throw new we();
  }
  /**
   * The name of this zone.
   * @abstract
   * @type {string}
   */
  get name() {
    throw new we();
  }
  get ianaName() {
    return this.name;
  }
  /**
   * Returns whether the offset is known to be fixed for the whole year.
   * @abstract
   * @type {boolean}
   */
  get isUniversal() {
    throw new we();
  }
  /**
   * Returns the offset's common name (such as EST) at the specified timestamp
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the name
   * @param {Object} opts - Options to affect the format
   * @param {string} opts.format - What style of offset to return. Accepts 'long' or 'short'.
   * @param {string} opts.locale - What locale to return the offset name in.
   * @return {string}
   */
  offsetName(e, n) {
    throw new we();
  }
  /**
   * Returns the offset's value as a string
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the offset
   * @param {string} format - What style of offset to return.
   *                          Accepts 'narrow', 'short', or 'techie'. Returning '+6', '+06:00', or '+0600' respectively
   * @return {string}
   */
  formatOffset(e, n) {
    throw new we();
  }
  /**
   * Return the offset in minutes for this zone at the specified timestamp.
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to compute the offset
   * @return {number}
   */
  offset(e) {
    throw new we();
  }
  /**
   * Return whether this Zone is equal to another zone
   * @abstract
   * @param {Zone} otherZone - the zone to compare
   * @return {boolean}
   */
  equals(e) {
    throw new we();
  }
  /**
   * Return whether this Zone is valid.
   * @abstract
   * @type {boolean}
   */
  get isValid() {
    throw new we();
  }
}
let Lt = null;
class kt extends Ke {
  /**
   * Get a singleton instance of the local zone
   * @return {SystemZone}
   */
  static get instance() {
    return Lt === null && (Lt = new kt()), Lt;
  }
  /** @override **/
  get type() {
    return "system";
  }
  /** @override **/
  get name() {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(e, { format: n, locale: r }) {
    return vr(e, n, r);
  }
  /** @override **/
  formatOffset(e, n) {
    return _e(this.offset(e), n);
  }
  /** @override **/
  offset(e) {
    return -new Date(e).getTimezoneOffset();
  }
  /** @override **/
  equals(e) {
    return e.type === "system";
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
let ht = {};
function Da(t) {
  return ht[t] || (ht[t] = new Intl.DateTimeFormat("en-US", {
    hour12: !1,
    timeZone: t,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    era: "short"
  })), ht[t];
}
const Ia = {
  year: 0,
  month: 1,
  day: 2,
  era: 3,
  hour: 4,
  minute: 5,
  second: 6
};
function Ea(t, e) {
  const n = t.format(e).replace(/\u200E/g, ""), r = /(\d+)\/(\d+)\/(\d+) (AD|BC),? (\d+):(\d+):(\d+)/.exec(n), [, s, a, i, o, c, u, d] = r;
  return [i, s, a, o, c, u, d];
}
function La(t, e) {
  const n = t.formatToParts(e), r = [];
  for (let s = 0; s < n.length; s++) {
    const { type: a, value: i } = n[s], o = Ia[a];
    a === "era" ? r[o] = i : M(o) || (r[o] = parseInt(i, 10));
  }
  return r;
}
let at = {};
class fe extends Ke {
  /**
   * @param {string} name - Zone name
   * @return {IANAZone}
   */
  static create(e) {
    return at[e] || (at[e] = new fe(e)), at[e];
  }
  /**
   * Reset local caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCache() {
    at = {}, ht = {};
  }
  /**
   * Returns whether the provided string is a valid specifier. This only checks the string's format, not that the specifier identifies a known zone; see isValidZone for that.
   * @param {string} s - The string to check validity on
   * @example IANAZone.isValidSpecifier("America/New_York") //=> true
   * @example IANAZone.isValidSpecifier("Sport~~blorp") //=> false
   * @deprecated This method returns false for some valid IANA names. Use isValidZone instead.
   * @return {boolean}
   */
  static isValidSpecifier(e) {
    return this.isValidZone(e);
  }
  /**
   * Returns whether the provided string identifies a real zone
   * @param {string} zone - The string to check
   * @example IANAZone.isValidZone("America/New_York") //=> true
   * @example IANAZone.isValidZone("Fantasia/Castle") //=> false
   * @example IANAZone.isValidZone("Sport~~blorp") //=> false
   * @return {boolean}
   */
  static isValidZone(e) {
    if (!e)
      return !1;
    try {
      return new Intl.DateTimeFormat("en-US", { timeZone: e }).format(), !0;
    } catch {
      return !1;
    }
  }
  constructor(e) {
    super(), this.zoneName = e, this.valid = fe.isValidZone(e);
  }
  /** @override **/
  get type() {
    return "iana";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(e, { format: n, locale: r }) {
    return vr(e, n, r, this.name);
  }
  /** @override **/
  formatOffset(e, n) {
    return _e(this.offset(e), n);
  }
  /** @override **/
  offset(e) {
    const n = new Date(e);
    if (isNaN(n))
      return NaN;
    const r = Da(this.name);
    let [s, a, i, o, c, u, d] = r.formatToParts ? La(r, n) : Ea(r, n);
    o === "BC" && (s = -Math.abs(s) + 1);
    const f = vt({
      year: s,
      month: a,
      day: i,
      hour: c === 24 ? 0 : c,
      minute: u,
      second: d,
      millisecond: 0
    });
    let h = +n;
    const x = h % 1e3;
    return h -= x >= 0 ? x : 1e3 + x, (f - h) / (60 * 1e3);
  }
  /** @override **/
  equals(e) {
    return e.type === "iana" && e.name === this.name;
  }
  /** @override **/
  get isValid() {
    return this.valid;
  }
}
let ln = {};
function Fa(t, e = {}) {
  const n = JSON.stringify([t, e]);
  let r = ln[n];
  return r || (r = new Intl.ListFormat(t, e), ln[n] = r), r;
}
let Ht = {};
function Bt(t, e = {}) {
  const n = JSON.stringify([t, e]);
  let r = Ht[n];
  return r || (r = new Intl.DateTimeFormat(t, e), Ht[n] = r), r;
}
let Pt = {};
function Wa(t, e = {}) {
  const n = JSON.stringify([t, e]);
  let r = Pt[n];
  return r || (r = new Intl.NumberFormat(t, e), Pt[n] = r), r;
}
let jt = {};
function Va(t, e = {}) {
  const { base: n, ...r } = e, s = JSON.stringify([t, r]);
  let a = jt[s];
  return a || (a = new Intl.RelativeTimeFormat(t, e), jt[s] = a), a;
}
let Ye = null;
function $a() {
  return Ye || (Ye = new Intl.DateTimeFormat().resolvedOptions().locale, Ye);
}
let cn = {};
function za(t) {
  let e = cn[t];
  if (!e) {
    const n = new Intl.Locale(t);
    e = "getWeekInfo" in n ? n.getWeekInfo() : n.weekInfo, cn[t] = e;
  }
  return e;
}
function Ra(t) {
  const e = t.indexOf("-x-");
  e !== -1 && (t = t.substring(0, e));
  const n = t.indexOf("-u-");
  if (n === -1)
    return [t];
  {
    let r, s;
    try {
      r = Bt(t).resolvedOptions(), s = t;
    } catch {
      const c = t.substring(0, n);
      r = Bt(c).resolvedOptions(), s = c;
    }
    const { numberingSystem: a, calendar: i } = r;
    return [s, a, i];
  }
}
function Aa(t, e, n) {
  return (n || e) && (t.includes("-u-") || (t += "-u"), n && (t += `-ca-${n}`), e && (t += `-nu-${e}`)), t;
}
function Za(t) {
  const e = [];
  for (let n = 1; n <= 12; n++) {
    const r = N.utc(2009, n, 1);
    e.push(t(r));
  }
  return e;
}
function Ha(t) {
  const e = [];
  for (let n = 1; n <= 7; n++) {
    const r = N.utc(2016, 11, 13 + n);
    e.push(t(r));
  }
  return e;
}
function it(t, e, n, r) {
  const s = t.listingMode();
  return s === "error" ? null : s === "en" ? n(e) : r(e);
}
function Ba(t) {
  return t.numberingSystem && t.numberingSystem !== "latn" ? !1 : t.numberingSystem === "latn" || !t.locale || t.locale.startsWith("en") || new Intl.DateTimeFormat(t.intl).resolvedOptions().numberingSystem === "latn";
}
class Pa {
  constructor(e, n, r) {
    this.padTo = r.padTo || 0, this.floor = r.floor || !1;
    const { padTo: s, floor: a, ...i } = r;
    if (!n || Object.keys(i).length > 0) {
      const o = { useGrouping: !1, ...r };
      r.padTo > 0 && (o.minimumIntegerDigits = r.padTo), this.inf = Wa(e, o);
    }
  }
  format(e) {
    if (this.inf) {
      const n = this.floor ? Math.floor(e) : e;
      return this.inf.format(n);
    } else {
      const n = this.floor ? Math.floor(e) : en(e, 3);
      return G(n, this.padTo);
    }
  }
}
class ja {
  constructor(e, n, r) {
    this.opts = r, this.originalZone = void 0;
    let s;
    if (this.opts.timeZone)
      this.dt = e;
    else if (e.zone.type === "fixed") {
      const i = -1 * (e.offset / 60), o = i >= 0 ? `Etc/GMT+${i}` : `Etc/GMT${i}`;
      e.offset !== 0 && fe.create(o).valid ? (s = o, this.dt = e) : (s = "UTC", this.dt = e.offset === 0 ? e : e.setZone("UTC").plus({ minutes: e.offset }), this.originalZone = e.zone);
    } else
      e.zone.type === "system" ? this.dt = e : e.zone.type === "iana" ? (this.dt = e, s = e.zone.name) : (s = "UTC", this.dt = e.setZone("UTC").plus({ minutes: e.offset }), this.originalZone = e.zone);
    const a = { ...this.opts };
    a.timeZone = a.timeZone || s, this.dtf = Bt(n, a);
  }
  format() {
    return this.originalZone ? this.formatToParts().map(({ value: e }) => e).join("") : this.dtf.format(this.dt.toJSDate());
  }
  formatToParts() {
    const e = this.dtf.formatToParts(this.dt.toJSDate());
    return this.originalZone ? e.map((n) => {
      if (n.type === "timeZoneName") {
        const r = this.originalZone.offsetName(this.dt.ts, {
          locale: this.dt.locale,
          format: this.opts.timeZoneName
        });
        return {
          ...n,
          value: r
        };
      } else
        return n;
    }) : e;
  }
  resolvedOptions() {
    return this.dtf.resolvedOptions();
  }
}
class Ua {
  constructor(e, n, r) {
    this.opts = { style: "long", ...r }, !n && kr() && (this.rtf = Va(e, r));
  }
  format(e, n) {
    return this.rtf ? this.rtf.format(e, n) : di(n, e, this.opts.numeric, this.opts.style !== "long");
  }
  formatToParts(e, n) {
    return this.rtf ? this.rtf.formatToParts(e, n) : [];
  }
}
const qa = {
  firstDay: 1,
  minimalDays: 4,
  weekend: [6, 7]
};
class L {
  static fromOpts(e) {
    return L.create(
      e.locale,
      e.numberingSystem,
      e.outputCalendar,
      e.weekSettings,
      e.defaultToEN
    );
  }
  static create(e, n, r, s, a = !1) {
    const i = e || Y.defaultLocale, o = i || (a ? "en-US" : $a()), c = n || Y.defaultNumberingSystem, u = r || Y.defaultOutputCalendar, d = Ut(s) || Y.defaultWeekSettings;
    return new L(o, c, u, d, i);
  }
  static resetCache() {
    Ye = null, Ht = {}, Pt = {}, jt = {};
  }
  static fromObject({ locale: e, numberingSystem: n, outputCalendar: r, weekSettings: s } = {}) {
    return L.create(e, n, r, s);
  }
  constructor(e, n, r, s, a) {
    const [i, o, c] = Ra(e);
    this.locale = i, this.numberingSystem = n || o || null, this.outputCalendar = r || c || null, this.weekSettings = s, this.intl = Aa(this.locale, this.numberingSystem, this.outputCalendar), this.weekdaysCache = { format: {}, standalone: {} }, this.monthsCache = { format: {}, standalone: {} }, this.meridiemCache = null, this.eraCache = {}, this.specifiedLocale = a, this.fastNumbersCached = null;
  }
  get fastNumbers() {
    return this.fastNumbersCached == null && (this.fastNumbersCached = Ba(this)), this.fastNumbersCached;
  }
  listingMode() {
    const e = this.isEnglish(), n = (this.numberingSystem === null || this.numberingSystem === "latn") && (this.outputCalendar === null || this.outputCalendar === "gregory");
    return e && n ? "en" : "intl";
  }
  clone(e) {
    return !e || Object.getOwnPropertyNames(e).length === 0 ? this : L.create(
      e.locale || this.specifiedLocale,
      e.numberingSystem || this.numberingSystem,
      e.outputCalendar || this.outputCalendar,
      Ut(e.weekSettings) || this.weekSettings,
      e.defaultToEN || !1
    );
  }
  redefaultToEN(e = {}) {
    return this.clone({ ...e, defaultToEN: !0 });
  }
  redefaultToSystem(e = {}) {
    return this.clone({ ...e, defaultToEN: !1 });
  }
  months(e, n = !1) {
    return it(this, e, Tr, () => {
      const r = n ? { month: e, day: "numeric" } : { month: e }, s = n ? "format" : "standalone";
      return this.monthsCache[s][e] || (this.monthsCache[s][e] = Za((a) => this.extract(a, r, "month"))), this.monthsCache[s][e];
    });
  }
  weekdays(e, n = !1) {
    return it(this, e, Mr, () => {
      const r = n ? { weekday: e, year: "numeric", month: "long", day: "numeric" } : { weekday: e }, s = n ? "format" : "standalone";
      return this.weekdaysCache[s][e] || (this.weekdaysCache[s][e] = Ha(
        (a) => this.extract(a, r, "weekday")
      )), this.weekdaysCache[s][e];
    });
  }
  meridiems() {
    return it(
      this,
      void 0,
      () => Or,
      () => {
        if (!this.meridiemCache) {
          const e = { hour: "numeric", hourCycle: "h12" };
          this.meridiemCache = [N.utc(2016, 11, 13, 9), N.utc(2016, 11, 13, 19)].map(
            (n) => this.extract(n, e, "dayperiod")
          );
        }
        return this.meridiemCache;
      }
    );
  }
  eras(e) {
    return it(this, e, Dr, () => {
      const n = { era: e };
      return this.eraCache[e] || (this.eraCache[e] = [N.utc(-40, 1, 1), N.utc(2017, 1, 1)].map(
        (r) => this.extract(r, n, "era")
      )), this.eraCache[e];
    });
  }
  extract(e, n, r) {
    const s = this.dtFormatter(e, n), a = s.formatToParts(), i = a.find((o) => o.type.toLowerCase() === r);
    return i ? i.value : null;
  }
  numberFormatter(e = {}) {
    return new Pa(this.intl, e.forceSimple || this.fastNumbers, e);
  }
  dtFormatter(e, n = {}) {
    return new ja(e, this.intl, n);
  }
  relFormatter(e = {}) {
    return new Ua(this.intl, this.isEnglish(), e);
  }
  listFormatter(e = {}) {
    return Fa(this.intl, e);
  }
  isEnglish() {
    return this.locale === "en" || this.locale.toLowerCase() === "en-us" || new Intl.DateTimeFormat(this.intl).resolvedOptions().locale.startsWith("en-us");
  }
  getWeekSettings() {
    return this.weekSettings ? this.weekSettings : xr() ? za(this.locale) : qa;
  }
  getStartOfWeek() {
    return this.getWeekSettings().firstDay;
  }
  getMinDaysInFirstWeek() {
    return this.getWeekSettings().minimalDays;
  }
  getWeekendDays() {
    return this.getWeekSettings().weekend;
  }
  equals(e) {
    return this.locale === e.locale && this.numberingSystem === e.numberingSystem && this.outputCalendar === e.outputCalendar;
  }
}
let Ft = null;
class K extends Ke {
  /**
   * Get a singleton instance of UTC
   * @return {FixedOffsetZone}
   */
  static get utcInstance() {
    return Ft === null && (Ft = new K(0)), Ft;
  }
  /**
   * Get an instance with a specified offset
   * @param {number} offset - The offset in minutes
   * @return {FixedOffsetZone}
   */
  static instance(e) {
    return e === 0 ? K.utcInstance : new K(e);
  }
  /**
   * Get an instance of FixedOffsetZone from a UTC offset string, like "UTC+6"
   * @param {string} s - The offset string to parse
   * @example FixedOffsetZone.parseSpecifier("UTC+6")
   * @example FixedOffsetZone.parseSpecifier("UTC+06")
   * @example FixedOffsetZone.parseSpecifier("UTC-6:00")
   * @return {FixedOffsetZone}
   */
  static parseSpecifier(e) {
    if (e) {
      const n = e.match(/^utc(?:([+-]\d{1,2})(?::(\d{2}))?)?$/i);
      if (n)
        return new K(bt(n[1], n[2]));
    }
    return null;
  }
  constructor(e) {
    super(), this.fixed = e;
  }
  /** @override **/
  get type() {
    return "fixed";
  }
  /** @override **/
  get name() {
    return this.fixed === 0 ? "UTC" : `UTC${_e(this.fixed, "narrow")}`;
  }
  get ianaName() {
    return this.fixed === 0 ? "Etc/UTC" : `Etc/GMT${_e(-this.fixed, "narrow")}`;
  }
  /** @override **/
  offsetName() {
    return this.name;
  }
  /** @override **/
  formatOffset(e, n) {
    return _e(this.fixed, n);
  }
  /** @override **/
  get isUniversal() {
    return !0;
  }
  /** @override **/
  offset() {
    return this.fixed;
  }
  /** @override **/
  equals(e) {
    return e.type === "fixed" && e.fixed === this.fixed;
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
class Ya extends Ke {
  constructor(e) {
    super(), this.zoneName = e;
  }
  /** @override **/
  get type() {
    return "invalid";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName() {
    return null;
  }
  /** @override **/
  formatOffset() {
    return "";
  }
  /** @override **/
  offset() {
    return NaN;
  }
  /** @override **/
  equals() {
    return !1;
  }
  /** @override **/
  get isValid() {
    return !1;
  }
}
function ke(t, e) {
  if (M(t) || t === null)
    return e;
  if (t instanceof Ke)
    return t;
  if (_a(t)) {
    const n = t.toLowerCase();
    return n === "default" ? e : n === "local" || n === "system" ? kt.instance : n === "utc" || n === "gmt" ? K.utcInstance : K.parseSpecifier(n) || fe.create(t);
  } else
    return Te(t) ? K.instance(t) : typeof t == "object" && "offset" in t && typeof t.offset == "function" ? t : new Ya(t);
}
let un = () => Date.now(), dn = "system", mn = null, hn = null, fn = null, gn = 60, wn, yn = null;
class Y {
  /**
   * Get the callback for returning the current timestamp.
   * @type {function}
   */
  static get now() {
    return un;
  }
  /**
   * Set the callback for returning the current timestamp.
   * The function should return a number, which will be interpreted as an Epoch millisecond count
   * @type {function}
   * @example Settings.now = () => Date.now() + 3000 // pretend it is 3 seconds in the future
   * @example Settings.now = () => 0 // always pretend it's Jan 1, 1970 at midnight in UTC time
   */
  static set now(e) {
    un = e;
  }
  /**
   * Set the default time zone to create DateTimes in. Does not affect existing instances.
   * Use the value "system" to reset this value to the system's time zone.
   * @type {string}
   */
  static set defaultZone(e) {
    dn = e;
  }
  /**
   * Get the default time zone object currently used to create DateTimes. Does not affect existing instances.
   * The default value is the system's time zone (the one set on the machine that runs this code).
   * @type {Zone}
   */
  static get defaultZone() {
    return ke(dn, kt.instance);
  }
  /**
   * Get the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultLocale() {
    return mn;
  }
  /**
   * Set the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultLocale(e) {
    mn = e;
  }
  /**
   * Get the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultNumberingSystem() {
    return hn;
  }
  /**
   * Set the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultNumberingSystem(e) {
    hn = e;
  }
  /**
   * Get the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultOutputCalendar() {
    return fn;
  }
  /**
   * Set the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultOutputCalendar(e) {
    fn = e;
  }
  /**
   * @typedef {Object} WeekSettings
   * @property {number} firstDay
   * @property {number} minimalDays
   * @property {number[]} weekend
   */
  /**
   * @return {WeekSettings|null}
   */
  static get defaultWeekSettings() {
    return yn;
  }
  /**
   * Allows overriding the default locale week settings, i.e. the start of the week, the weekend and
   * how many days are required in the first week of a year.
   * Does not affect existing instances.
   *
   * @param {WeekSettings|null} weekSettings
   */
  static set defaultWeekSettings(e) {
    yn = Ut(e);
  }
  /**
   * Get the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   */
  static get twoDigitCutoffYear() {
    return gn;
  }
  /**
   * Set the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   * @example Settings.twoDigitCutoffYear = 0 // cut-off year is 0, so all 'yy' are interpreted as current century
   * @example Settings.twoDigitCutoffYear = 50 // '49' -> 1949; '50' -> 2050
   * @example Settings.twoDigitCutoffYear = 1950 // interpreted as 50
   * @example Settings.twoDigitCutoffYear = 2050 // ALSO interpreted as 50
   */
  static set twoDigitCutoffYear(e) {
    gn = e % 100;
  }
  /**
   * Get whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static get throwOnInvalid() {
    return wn;
  }
  /**
   * Set whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static set throwOnInvalid(e) {
    wn = e;
  }
  /**
   * Reset Luxon's global caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCaches() {
    L.resetCache(), fe.resetCache();
  }
}
class ue {
  constructor(e, n) {
    this.reason = e, this.explanation = n;
  }
  toMessage() {
    return this.explanation ? `${this.reason}: ${this.explanation}` : this.reason;
  }
}
const hr = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334], fr = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
function re(t, e) {
  return new ue(
    "unit out of range",
    `you specified ${e} (of type ${typeof e}) as a ${t}, which is invalid`
  );
}
function Qt(t, e, n) {
  const r = new Date(Date.UTC(t, e - 1, n));
  t < 100 && t >= 0 && r.setUTCFullYear(r.getUTCFullYear() - 1900);
  const s = r.getUTCDay();
  return s === 0 ? 7 : s;
}
function gr(t, e, n) {
  return n + (Xe(t) ? fr : hr)[e - 1];
}
function wr(t, e) {
  const n = Xe(t) ? fr : hr, r = n.findIndex((a) => a < e), s = e - n[r];
  return { month: r + 1, day: s };
}
function Kt(t, e) {
  return (t - e + 7) % 7 + 1;
}
function wt(t, e = 4, n = 1) {
  const { year: r, month: s, day: a } = t, i = gr(r, s, a), o = Kt(Qt(r, s, a), n);
  let c = Math.floor((i - o + 14 - e) / 7), u;
  return c < 1 ? (u = r - 1, c = Qe(u, e, n)) : c > Qe(r, e, n) ? (u = r + 1, c = 1) : u = r, { weekYear: u, weekNumber: c, weekday: o, ...Nt(t) };
}
function pn(t, e = 4, n = 1) {
  const { weekYear: r, weekNumber: s, weekday: a } = t, i = Kt(Qt(r, 1, e), n), o = We(r);
  let c = s * 7 + a - i - 7 + e, u;
  c < 1 ? (u = r - 1, c += We(u)) : c > o ? (u = r + 1, c -= We(r)) : u = r;
  const { month: d, day: m } = wr(u, c);
  return { year: u, month: d, day: m, ...Nt(t) };
}
function Wt(t) {
  const { year: e, month: n, day: r } = t, s = gr(e, n, r);
  return { year: e, ordinal: s, ...Nt(t) };
}
function kn(t) {
  const { year: e, ordinal: n } = t, { month: r, day: s } = wr(e, n);
  return { year: e, month: r, day: s, ...Nt(t) };
}
function xn(t, e) {
  if (!M(t.localWeekday) || !M(t.localWeekNumber) || !M(t.localWeekYear)) {
    if (!M(t.weekday) || !M(t.weekNumber) || !M(t.weekYear))
      throw new Ee(
        "Cannot mix locale-based week fields with ISO-based week fields"
      );
    return M(t.localWeekday) || (t.weekday = t.localWeekday), M(t.localWeekNumber) || (t.weekNumber = t.localWeekNumber), M(t.localWeekYear) || (t.weekYear = t.localWeekYear), delete t.localWeekday, delete t.localWeekNumber, delete t.localWeekYear, {
      minDaysInFirstWeek: e.getMinDaysInFirstWeek(),
      startOfWeek: e.getStartOfWeek()
    };
  } else
    return { minDaysInFirstWeek: 4, startOfWeek: 1 };
}
function Ga(t, e = 4, n = 1) {
  const r = xt(t.weekYear), s = se(
    t.weekNumber,
    1,
    Qe(t.weekYear, e, n)
  ), a = se(t.weekday, 1, 7);
  return r ? s ? a ? !1 : re("weekday", t.weekday) : re("week", t.weekNumber) : re("weekYear", t.weekYear);
}
function Ja(t) {
  const e = xt(t.year), n = se(t.ordinal, 1, We(t.year));
  return e ? n ? !1 : re("ordinal", t.ordinal) : re("year", t.year);
}
function yr(t) {
  const e = xt(t.year), n = se(t.month, 1, 12), r = se(t.day, 1, yt(t.year, t.month));
  return e ? n ? r ? !1 : re("day", t.day) : re("month", t.month) : re("year", t.year);
}
function pr(t) {
  const { hour: e, minute: n, second: r, millisecond: s } = t, a = se(e, 0, 23) || e === 24 && n === 0 && r === 0 && s === 0, i = se(n, 0, 59), o = se(r, 0, 59), c = se(s, 0, 999);
  return a ? i ? o ? c ? !1 : re("millisecond", s) : re("second", r) : re("minute", n) : re("hour", e);
}
function M(t) {
  return typeof t > "u";
}
function Te(t) {
  return typeof t == "number";
}
function xt(t) {
  return typeof t == "number" && t % 1 === 0;
}
function _a(t) {
  return typeof t == "string";
}
function Qa(t) {
  return Object.prototype.toString.call(t) === "[object Date]";
}
function kr() {
  try {
    return typeof Intl < "u" && !!Intl.RelativeTimeFormat;
  } catch {
    return !1;
  }
}
function xr() {
  try {
    return typeof Intl < "u" && !!Intl.Locale && ("weekInfo" in Intl.Locale.prototype || "getWeekInfo" in Intl.Locale.prototype);
  } catch {
    return !1;
  }
}
function Ka(t) {
  return Array.isArray(t) ? t : [t];
}
function vn(t, e, n) {
  if (t.length !== 0)
    return t.reduce((r, s) => {
      const a = [e(s), s];
      return r && n(r[0], a[0]) === r[0] ? r : a;
    }, null)[1];
}
function Xa(t, e) {
  return e.reduce((n, r) => (n[r] = t[r], n), {});
}
function ze(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function Ut(t) {
  if (t == null)
    return null;
  if (typeof t != "object")
    throw new X("Week settings must be an object");
  if (!se(t.firstDay, 1, 7) || !se(t.minimalDays, 1, 7) || !Array.isArray(t.weekend) || t.weekend.some((e) => !se(e, 1, 7)))
    throw new X("Invalid week settings");
  return {
    firstDay: t.firstDay,
    minimalDays: t.minimalDays,
    weekend: Array.from(t.weekend)
  };
}
function se(t, e, n) {
  return xt(t) && t >= e && t <= n;
}
function ei(t, e) {
  return t - e * Math.floor(t / e);
}
function G(t, e = 2) {
  const n = t < 0;
  let r;
  return n ? r = "-" + ("" + -t).padStart(e, "0") : r = ("" + t).padStart(e, "0"), r;
}
function pe(t) {
  if (!(M(t) || t === null || t === ""))
    return parseInt(t, 10);
}
function ve(t) {
  if (!(M(t) || t === null || t === ""))
    return parseFloat(t);
}
function Xt(t) {
  if (!(M(t) || t === null || t === "")) {
    const e = parseFloat("0." + t) * 1e3;
    return Math.floor(e);
  }
}
function en(t, e, n = !1) {
  const r = 10 ** e;
  return (n ? Math.trunc : Math.round)(t * r) / r;
}
function Xe(t) {
  return t % 4 === 0 && (t % 100 !== 0 || t % 400 === 0);
}
function We(t) {
  return Xe(t) ? 366 : 365;
}
function yt(t, e) {
  const n = ei(e - 1, 12) + 1, r = t + (e - n) / 12;
  return n === 2 ? Xe(r) ? 29 : 28 : [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][n - 1];
}
function vt(t) {
  let e = Date.UTC(
    t.year,
    t.month - 1,
    t.day,
    t.hour,
    t.minute,
    t.second,
    t.millisecond
  );
  return t.year < 100 && t.year >= 0 && (e = new Date(e), e.setUTCFullYear(t.year, t.month - 1, t.day)), +e;
}
function bn(t, e, n) {
  return -Kt(Qt(t, 1, e), n) + e - 1;
}
function Qe(t, e = 4, n = 1) {
  const r = bn(t, e, n), s = bn(t + 1, e, n);
  return (We(t) - r + s) / 7;
}
function qt(t) {
  return t > 99 ? t : t > Y.twoDigitCutoffYear ? 1900 + t : 2e3 + t;
}
function vr(t, e, n, r = null) {
  const s = new Date(t), a = {
    hourCycle: "h23",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };
  r && (a.timeZone = r);
  const i = { timeZoneName: e, ...a }, o = new Intl.DateTimeFormat(n, i).formatToParts(s).find((c) => c.type.toLowerCase() === "timezonename");
  return o ? o.value : null;
}
function bt(t, e) {
  let n = parseInt(t, 10);
  Number.isNaN(n) && (n = 0);
  const r = parseInt(e, 10) || 0, s = n < 0 || Object.is(n, -0) ? -r : r;
  return n * 60 + s;
}
function br(t) {
  const e = Number(t);
  if (typeof t == "boolean" || t === "" || Number.isNaN(e))
    throw new X(`Invalid unit value ${t}`);
  return e;
}
function pt(t, e) {
  const n = {};
  for (const r in t)
    if (ze(t, r)) {
      const s = t[r];
      if (s == null)
        continue;
      n[e(r)] = br(s);
    }
  return n;
}
function _e(t, e) {
  const n = Math.trunc(Math.abs(t / 60)), r = Math.trunc(Math.abs(t % 60)), s = t >= 0 ? "+" : "-";
  switch (e) {
    case "short":
      return `${s}${G(n, 2)}:${G(r, 2)}`;
    case "narrow":
      return `${s}${n}${r > 0 ? `:${r}` : ""}`;
    case "techie":
      return `${s}${G(n, 2)}${G(r, 2)}`;
    default:
      throw new RangeError(`Value format ${e} is out of range for property format`);
  }
}
function Nt(t) {
  return Xa(t, ["hour", "minute", "second", "millisecond"]);
}
const ti = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
], Nr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
], ni = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
function Tr(t) {
  switch (t) {
    case "narrow":
      return [...ni];
    case "short":
      return [...Nr];
    case "long":
      return [...ti];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    case "2-digit":
      return ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    default:
      return null;
  }
}
const Sr = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
], Cr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], ri = ["M", "T", "W", "T", "F", "S", "S"];
function Mr(t) {
  switch (t) {
    case "narrow":
      return [...ri];
    case "short":
      return [...Cr];
    case "long":
      return [...Sr];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7"];
    default:
      return null;
  }
}
const Or = ["AM", "PM"], si = ["Before Christ", "Anno Domini"], ai = ["BC", "AD"], ii = ["B", "A"];
function Dr(t) {
  switch (t) {
    case "narrow":
      return [...ii];
    case "short":
      return [...ai];
    case "long":
      return [...si];
    default:
      return null;
  }
}
function oi(t) {
  return Or[t.hour < 12 ? 0 : 1];
}
function li(t, e) {
  return Mr(e)[t.weekday - 1];
}
function ci(t, e) {
  return Tr(e)[t.month - 1];
}
function ui(t, e) {
  return Dr(e)[t.year < 0 ? 0 : 1];
}
function di(t, e, n = "always", r = !1) {
  const s = {
    years: ["year", "yr."],
    quarters: ["quarter", "qtr."],
    months: ["month", "mo."],
    weeks: ["week", "wk."],
    days: ["day", "day", "days"],
    hours: ["hour", "hr."],
    minutes: ["minute", "min."],
    seconds: ["second", "sec."]
  }, a = ["hours", "minutes", "seconds"].indexOf(t) === -1;
  if (n === "auto" && a) {
    const m = t === "days";
    switch (e) {
      case 1:
        return m ? "tomorrow" : `next ${s[t][0]}`;
      case -1:
        return m ? "yesterday" : `last ${s[t][0]}`;
      case 0:
        return m ? "today" : `this ${s[t][0]}`;
    }
  }
  const i = Object.is(e, -0) || e < 0, o = Math.abs(e), c = o === 1, u = s[t], d = r ? c ? u[1] : u[2] || u[1] : c ? s[t][0] : t;
  return i ? `${o} ${d} ago` : `in ${o} ${d}`;
}
function Nn(t, e) {
  let n = "";
  for (const r of t)
    r.literal ? n += r.val : n += e(r.val);
  return n;
}
const mi = {
  D: gt,
  DD: Gn,
  DDD: Jn,
  DDDD: _n,
  t: Qn,
  tt: Kn,
  ttt: Xn,
  tttt: er,
  T: tr,
  TT: nr,
  TTT: rr,
  TTTT: sr,
  f: ar,
  ff: or,
  fff: cr,
  ffff: dr,
  F: ir,
  FF: lr,
  FFF: ur,
  FFFF: mr
};
class Q {
  static create(e, n = {}) {
    return new Q(e, n);
  }
  static parseFormat(e) {
    let n = null, r = "", s = !1;
    const a = [];
    for (let i = 0; i < e.length; i++) {
      const o = e.charAt(i);
      o === "'" ? (r.length > 0 && a.push({ literal: s || /^\s+$/.test(r), val: r }), n = null, r = "", s = !s) : s || o === n ? r += o : (r.length > 0 && a.push({ literal: /^\s+$/.test(r), val: r }), r = o, n = o);
    }
    return r.length > 0 && a.push({ literal: s || /^\s+$/.test(r), val: r }), a;
  }
  static macroTokenToFormatOpts(e) {
    return mi[e];
  }
  constructor(e, n) {
    this.opts = n, this.loc = e, this.systemLoc = null;
  }
  formatWithSystemDefault(e, n) {
    return this.systemLoc === null && (this.systemLoc = this.loc.redefaultToSystem()), this.systemLoc.dtFormatter(e, { ...this.opts, ...n }).format();
  }
  dtFormatter(e, n = {}) {
    return this.loc.dtFormatter(e, { ...this.opts, ...n });
  }
  formatDateTime(e, n) {
    return this.dtFormatter(e, n).format();
  }
  formatDateTimeParts(e, n) {
    return this.dtFormatter(e, n).formatToParts();
  }
  formatInterval(e, n) {
    return this.dtFormatter(e.start, n).dtf.formatRange(e.start.toJSDate(), e.end.toJSDate());
  }
  resolvedOptions(e, n) {
    return this.dtFormatter(e, n).resolvedOptions();
  }
  num(e, n = 0) {
    if (this.opts.forceSimple)
      return G(e, n);
    const r = { ...this.opts };
    return n > 0 && (r.padTo = n), this.loc.numberFormatter(r).format(e);
  }
  formatDateTimeFromString(e, n) {
    const r = this.loc.listingMode() === "en", s = this.loc.outputCalendar && this.loc.outputCalendar !== "gregory", a = (h, x) => this.loc.extract(e, h, x), i = (h) => e.isOffsetFixed && e.offset === 0 && h.allowZ ? "Z" : e.isValid ? e.zone.formatOffset(e.ts, h.format) : "", o = () => r ? oi(e) : a({ hour: "numeric", hourCycle: "h12" }, "dayperiod"), c = (h, x) => r ? ci(e, h) : a(x ? { month: h } : { month: h, day: "numeric" }, "month"), u = (h, x) => r ? li(e, h) : a(
      x ? { weekday: h } : { weekday: h, month: "long", day: "numeric" },
      "weekday"
    ), d = (h) => {
      const x = Q.macroTokenToFormatOpts(h);
      return x ? this.formatWithSystemDefault(e, x) : h;
    }, m = (h) => r ? ui(e, h) : a({ era: h }, "era"), f = (h) => {
      switch (h) {
        case "S":
          return this.num(e.millisecond);
        case "u":
        case "SSS":
          return this.num(e.millisecond, 3);
        case "s":
          return this.num(e.second);
        case "ss":
          return this.num(e.second, 2);
        case "uu":
          return this.num(Math.floor(e.millisecond / 10), 2);
        case "uuu":
          return this.num(Math.floor(e.millisecond / 100));
        case "m":
          return this.num(e.minute);
        case "mm":
          return this.num(e.minute, 2);
        case "h":
          return this.num(e.hour % 12 === 0 ? 12 : e.hour % 12);
        case "hh":
          return this.num(e.hour % 12 === 0 ? 12 : e.hour % 12, 2);
        case "H":
          return this.num(e.hour);
        case "HH":
          return this.num(e.hour, 2);
        case "Z":
          return i({ format: "narrow", allowZ: this.opts.allowZ });
        case "ZZ":
          return i({ format: "short", allowZ: this.opts.allowZ });
        case "ZZZ":
          return i({ format: "techie", allowZ: this.opts.allowZ });
        case "ZZZZ":
          return e.zone.offsetName(e.ts, { format: "short", locale: this.loc.locale });
        case "ZZZZZ":
          return e.zone.offsetName(e.ts, { format: "long", locale: this.loc.locale });
        case "z":
          return e.zoneName;
        case "a":
          return o();
        case "d":
          return s ? a({ day: "numeric" }, "day") : this.num(e.day);
        case "dd":
          return s ? a({ day: "2-digit" }, "day") : this.num(e.day, 2);
        case "c":
          return this.num(e.weekday);
        case "ccc":
          return u("short", !0);
        case "cccc":
          return u("long", !0);
        case "ccccc":
          return u("narrow", !0);
        case "E":
          return this.num(e.weekday);
        case "EEE":
          return u("short", !1);
        case "EEEE":
          return u("long", !1);
        case "EEEEE":
          return u("narrow", !1);
        case "L":
          return s ? a({ month: "numeric", day: "numeric" }, "month") : this.num(e.month);
        case "LL":
          return s ? a({ month: "2-digit", day: "numeric" }, "month") : this.num(e.month, 2);
        case "LLL":
          return c("short", !0);
        case "LLLL":
          return c("long", !0);
        case "LLLLL":
          return c("narrow", !0);
        case "M":
          return s ? a({ month: "numeric" }, "month") : this.num(e.month);
        case "MM":
          return s ? a({ month: "2-digit" }, "month") : this.num(e.month, 2);
        case "MMM":
          return c("short", !1);
        case "MMMM":
          return c("long", !1);
        case "MMMMM":
          return c("narrow", !1);
        case "y":
          return s ? a({ year: "numeric" }, "year") : this.num(e.year);
        case "yy":
          return s ? a({ year: "2-digit" }, "year") : this.num(e.year.toString().slice(-2), 2);
        case "yyyy":
          return s ? a({ year: "numeric" }, "year") : this.num(e.year, 4);
        case "yyyyyy":
          return s ? a({ year: "numeric" }, "year") : this.num(e.year, 6);
        case "G":
          return m("short");
        case "GG":
          return m("long");
        case "GGGGG":
          return m("narrow");
        case "kk":
          return this.num(e.weekYear.toString().slice(-2), 2);
        case "kkkk":
          return this.num(e.weekYear, 4);
        case "W":
          return this.num(e.weekNumber);
        case "WW":
          return this.num(e.weekNumber, 2);
        case "n":
          return this.num(e.localWeekNumber);
        case "nn":
          return this.num(e.localWeekNumber, 2);
        case "ii":
          return this.num(e.localWeekYear.toString().slice(-2), 2);
        case "iiii":
          return this.num(e.localWeekYear, 4);
        case "o":
          return this.num(e.ordinal);
        case "ooo":
          return this.num(e.ordinal, 3);
        case "q":
          return this.num(e.quarter);
        case "qq":
          return this.num(e.quarter, 2);
        case "X":
          return this.num(Math.floor(e.ts / 1e3));
        case "x":
          return this.num(e.ts);
        default:
          return d(h);
      }
    };
    return Nn(Q.parseFormat(n), f);
  }
  formatDurationFromString(e, n) {
    const r = (c) => {
      switch (c[0]) {
        case "S":
          return "millisecond";
        case "s":
          return "second";
        case "m":
          return "minute";
        case "h":
          return "hour";
        case "d":
          return "day";
        case "w":
          return "week";
        case "M":
          return "month";
        case "y":
          return "year";
        default:
          return null;
      }
    }, s = (c) => (u) => {
      const d = r(u);
      return d ? this.num(c.get(d), u.length) : u;
    }, a = Q.parseFormat(n), i = a.reduce(
      (c, { literal: u, val: d }) => u ? c : c.concat(d),
      []
    ), o = e.shiftTo(...i.map(r).filter((c) => c));
    return Nn(a, s(o));
  }
}
const Ir = /[A-Za-z_+-]{1,256}(?::?\/[A-Za-z0-9_+-]{1,256}(?:\/[A-Za-z0-9_+-]{1,256})?)?/;
function Re(...t) {
  const e = t.reduce((n, r) => n + r.source, "");
  return RegExp(`^${e}$`);
}
function Ae(...t) {
  return (e) => t.reduce(
    ([n, r, s], a) => {
      const [i, o, c] = a(e, s);
      return [{ ...n, ...i }, o || r, c];
    },
    [{}, null, 1]
  ).slice(0, 2);
}
function Ze(t, ...e) {
  if (t == null)
    return [null, null];
  for (const [n, r] of e) {
    const s = n.exec(t);
    if (s)
      return r(s);
  }
  return [null, null];
}
function Er(...t) {
  return (e, n) => {
    const r = {};
    let s;
    for (s = 0; s < t.length; s++)
      r[t[s]] = pe(e[n + s]);
    return [r, null, n + s];
  };
}
const Lr = /(?:(Z)|([+-]\d\d)(?::?(\d\d))?)/, hi = `(?:${Lr.source}?(?:\\[(${Ir.source})\\])?)?`, tn = /(\d\d)(?::?(\d\d)(?::?(\d\d)(?:[.,](\d{1,30}))?)?)?/, Fr = RegExp(`${tn.source}${hi}`), nn = RegExp(`(?:T${Fr.source})?`), fi = /([+-]\d{6}|\d{4})(?:-?(\d\d)(?:-?(\d\d))?)?/, gi = /(\d{4})-?W(\d\d)(?:-?(\d))?/, wi = /(\d{4})-?(\d{3})/, yi = Er("weekYear", "weekNumber", "weekDay"), pi = Er("year", "ordinal"), ki = /(\d{4})-(\d\d)-(\d\d)/, Wr = RegExp(
  `${tn.source} ?(?:${Lr.source}|(${Ir.source}))?`
), xi = RegExp(`(?: ${Wr.source})?`);
function Ve(t, e, n) {
  const r = t[e];
  return M(r) ? n : pe(r);
}
function vi(t, e) {
  return [{
    year: Ve(t, e),
    month: Ve(t, e + 1, 1),
    day: Ve(t, e + 2, 1)
  }, null, e + 3];
}
function He(t, e) {
  return [{
    hours: Ve(t, e, 0),
    minutes: Ve(t, e + 1, 0),
    seconds: Ve(t, e + 2, 0),
    milliseconds: Xt(t[e + 3])
  }, null, e + 4];
}
function et(t, e) {
  const n = !t[e] && !t[e + 1], r = bt(t[e + 1], t[e + 2]), s = n ? null : K.instance(r);
  return [{}, s, e + 3];
}
function tt(t, e) {
  const n = t[e] ? fe.create(t[e]) : null;
  return [{}, n, e + 1];
}
const bi = RegExp(`^T?${tn.source}$`), Ni = /^-?P(?:(?:(-?\d{1,20}(?:\.\d{1,20})?)Y)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20}(?:\.\d{1,20})?)W)?(?:(-?\d{1,20}(?:\.\d{1,20})?)D)?(?:T(?:(-?\d{1,20}(?:\.\d{1,20})?)H)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20})(?:[.,](-?\d{1,20}))?S)?)?)$/;
function Ti(t) {
  const [e, n, r, s, a, i, o, c, u] = t, d = e[0] === "-", m = c && c[0] === "-", f = (h, x = !1) => h !== void 0 && (x || h && d) ? -h : h;
  return [
    {
      years: f(ve(n)),
      months: f(ve(r)),
      weeks: f(ve(s)),
      days: f(ve(a)),
      hours: f(ve(i)),
      minutes: f(ve(o)),
      seconds: f(ve(c), c === "-0"),
      milliseconds: f(Xt(u), m)
    }
  ];
}
const Si = {
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function rn(t, e, n, r, s, a, i) {
  const o = {
    year: e.length === 2 ? qt(pe(e)) : pe(e),
    month: Nr.indexOf(n) + 1,
    day: pe(r),
    hour: pe(s),
    minute: pe(a)
  };
  return i && (o.second = pe(i)), t && (o.weekday = t.length > 3 ? Sr.indexOf(t) + 1 : Cr.indexOf(t) + 1), o;
}
const Ci = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|(?:([+-]\d\d)(\d\d)))$/;
function Mi(t) {
  const [
    ,
    e,
    n,
    r,
    s,
    a,
    i,
    o,
    c,
    u,
    d,
    m
  ] = t, f = rn(e, s, r, n, a, i, o);
  let h;
  return c ? h = Si[c] : u ? h = 0 : h = bt(d, m), [f, new K(h)];
}
function Oi(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").trim();
}
const Di = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (\d\d) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (\d{4}) (\d\d):(\d\d):(\d\d) GMT$/, Ii = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday), (\d\d)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d\d) (\d\d):(\d\d):(\d\d) GMT$/, Ei = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ( \d|\d\d) (\d\d):(\d\d):(\d\d) (\d{4})$/;
function Tn(t) {
  const [, e, n, r, s, a, i, o] = t;
  return [rn(e, s, r, n, a, i, o), K.utcInstance];
}
function Li(t) {
  const [, e, n, r, s, a, i, o] = t;
  return [rn(e, o, n, r, s, a, i), K.utcInstance];
}
const Fi = Re(fi, nn), Wi = Re(gi, nn), Vi = Re(wi, nn), $i = Re(Fr), Vr = Ae(
  vi,
  He,
  et,
  tt
), zi = Ae(
  yi,
  He,
  et,
  tt
), Ri = Ae(
  pi,
  He,
  et,
  tt
), Ai = Ae(
  He,
  et,
  tt
);
function Zi(t) {
  return Ze(
    t,
    [Fi, Vr],
    [Wi, zi],
    [Vi, Ri],
    [$i, Ai]
  );
}
function Hi(t) {
  return Ze(Oi(t), [Ci, Mi]);
}
function Bi(t) {
  return Ze(
    t,
    [Di, Tn],
    [Ii, Tn],
    [Ei, Li]
  );
}
function Pi(t) {
  return Ze(t, [Ni, Ti]);
}
const ji = Ae(He);
function Ui(t) {
  return Ze(t, [bi, ji]);
}
const qi = Re(ki, xi), Yi = Re(Wr), Gi = Ae(
  He,
  et,
  tt
);
function Ji(t) {
  return Ze(
    t,
    [qi, Vr],
    [Yi, Gi]
  );
}
const Sn = "Invalid Duration", $r = {
  weeks: {
    days: 7,
    hours: 7 * 24,
    minutes: 7 * 24 * 60,
    seconds: 7 * 24 * 60 * 60,
    milliseconds: 7 * 24 * 60 * 60 * 1e3
  },
  days: {
    hours: 24,
    minutes: 24 * 60,
    seconds: 24 * 60 * 60,
    milliseconds: 24 * 60 * 60 * 1e3
  },
  hours: { minutes: 60, seconds: 60 * 60, milliseconds: 60 * 60 * 1e3 },
  minutes: { seconds: 60, milliseconds: 60 * 1e3 },
  seconds: { milliseconds: 1e3 }
}, _i = {
  years: {
    quarters: 4,
    months: 12,
    weeks: 52,
    days: 365,
    hours: 365 * 24,
    minutes: 365 * 24 * 60,
    seconds: 365 * 24 * 60 * 60,
    milliseconds: 365 * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: 13,
    days: 91,
    hours: 91 * 24,
    minutes: 91 * 24 * 60,
    seconds: 91 * 24 * 60 * 60,
    milliseconds: 91 * 24 * 60 * 60 * 1e3
  },
  months: {
    weeks: 4,
    days: 30,
    hours: 30 * 24,
    minutes: 30 * 24 * 60,
    seconds: 30 * 24 * 60 * 60,
    milliseconds: 30 * 24 * 60 * 60 * 1e3
  },
  ...$r
}, ne = 146097 / 400, Oe = 146097 / 4800, Qi = {
  years: {
    quarters: 4,
    months: 12,
    weeks: ne / 7,
    days: ne,
    hours: ne * 24,
    minutes: ne * 24 * 60,
    seconds: ne * 24 * 60 * 60,
    milliseconds: ne * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: ne / 28,
    days: ne / 4,
    hours: ne * 24 / 4,
    minutes: ne * 24 * 60 / 4,
    seconds: ne * 24 * 60 * 60 / 4,
    milliseconds: ne * 24 * 60 * 60 * 1e3 / 4
  },
  months: {
    weeks: Oe / 7,
    days: Oe,
    hours: Oe * 24,
    minutes: Oe * 24 * 60,
    seconds: Oe * 24 * 60 * 60,
    milliseconds: Oe * 24 * 60 * 60 * 1e3
  },
  ...$r
}, Ne = [
  "years",
  "quarters",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds"
], Ki = Ne.slice(0).reverse();
function ye(t, e, n = !1) {
  const r = {
    values: n ? e.values : { ...t.values, ...e.values || {} },
    loc: t.loc.clone(e.loc),
    conversionAccuracy: e.conversionAccuracy || t.conversionAccuracy,
    matrix: e.matrix || t.matrix
  };
  return new D(r);
}
function zr(t, e) {
  let n = e.milliseconds ?? 0;
  for (const r of Ki.slice(1))
    e[r] && (n += e[r] * t[r].milliseconds);
  return n;
}
function Cn(t, e) {
  const n = zr(t, e) < 0 ? -1 : 1;
  Ne.reduceRight((r, s) => {
    if (M(e[s]))
      return r;
    if (r) {
      const a = e[r] * n, i = t[s][r], o = Math.floor(a / i);
      e[s] += o * n, e[r] -= o * i * n;
    }
    return s;
  }, null), Ne.reduce((r, s) => {
    if (M(e[s]))
      return r;
    if (r) {
      const a = e[r] % 1;
      e[r] -= a, e[s] += a * t[r][s];
    }
    return s;
  }, null);
}
function Xi(t) {
  const e = {};
  for (const [n, r] of Object.entries(t))
    r !== 0 && (e[n] = r);
  return e;
}
class D {
  /**
   * @private
   */
  constructor(e) {
    const n = e.conversionAccuracy === "longterm" || !1;
    let r = n ? Qi : _i;
    e.matrix && (r = e.matrix), this.values = e.values, this.loc = e.loc || L.create(), this.conversionAccuracy = n ? "longterm" : "casual", this.invalid = e.invalid || null, this.matrix = r, this.isLuxonDuration = !0;
  }
  /**
   * Create Duration from a number of milliseconds.
   * @param {number} count of milliseconds
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  static fromMillis(e, n) {
    return D.fromObject({ milliseconds: e }, n);
  }
  /**
   * Create a Duration from a JavaScript object with keys like 'years' and 'hours'.
   * If this object is empty then a zero milliseconds duration is returned.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.years
   * @param {number} obj.quarters
   * @param {number} obj.months
   * @param {number} obj.weeks
   * @param {number} obj.days
   * @param {number} obj.hours
   * @param {number} obj.minutes
   * @param {number} obj.seconds
   * @param {number} obj.milliseconds
   * @param {Object} [opts=[]] - options for creating this Duration
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the custom conversion system to use
   * @return {Duration}
   */
  static fromObject(e, n = {}) {
    if (e == null || typeof e != "object")
      throw new X(
        `Duration.fromObject: argument expected to be an object, got ${e === null ? "null" : typeof e}`
      );
    return new D({
      values: pt(e, D.normalizeUnit),
      loc: L.fromObject(n),
      conversionAccuracy: n.conversionAccuracy,
      matrix: n.matrix
    });
  }
  /**
   * Create a Duration from DurationLike.
   *
   * @param {Object | number | Duration} durationLike
   * One of:
   * - object with keys like 'years' and 'hours'.
   * - number representing milliseconds
   * - Duration instance
   * @return {Duration}
   */
  static fromDurationLike(e) {
    if (Te(e))
      return D.fromMillis(e);
    if (D.isDuration(e))
      return e;
    if (typeof e == "object")
      return D.fromObject(e);
    throw new X(
      `Unknown duration argument ${e} of type ${typeof e}`
    );
  }
  /**
   * Create a Duration from an ISO 8601 duration string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the preset conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromISO('P3Y6M1W4DT12H30M5S').toObject() //=> { years: 3, months: 6, weeks: 1, days: 4, hours: 12, minutes: 30, seconds: 5 }
   * @example Duration.fromISO('PT23H').toObject() //=> { hours: 23 }
   * @example Duration.fromISO('P5Y3M').toObject() //=> { years: 5, months: 3 }
   * @return {Duration}
   */
  static fromISO(e, n) {
    const [r] = Pi(e);
    return r ? D.fromObject(r, n) : D.invalid("unparsable", `the input "${e}" can't be parsed as ISO 8601`);
  }
  /**
   * Create a Duration from an ISO 8601 time string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @example Duration.fromISOTime('11:22:33.444').toObject() //=> { hours: 11, minutes: 22, seconds: 33, milliseconds: 444 }
   * @example Duration.fromISOTime('11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @return {Duration}
   */
  static fromISOTime(e, n) {
    const [r] = Ui(e);
    return r ? D.fromObject(r, n) : D.invalid("unparsable", `the input "${e}" can't be parsed as ISO 8601`);
  }
  /**
   * Create an invalid Duration.
   * @param {string} reason - simple string of why this datetime is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Duration}
   */
  static invalid(e, n = null) {
    if (!e)
      throw new X("need to specify a reason the Duration is invalid");
    const r = e instanceof ue ? e : new ue(e, n);
    if (Y.throwOnInvalid)
      throw new Ca(r);
    return new D({ invalid: r });
  }
  /**
   * @private
   */
  static normalizeUnit(e) {
    const n = {
      year: "years",
      years: "years",
      quarter: "quarters",
      quarters: "quarters",
      month: "months",
      months: "months",
      week: "weeks",
      weeks: "weeks",
      day: "days",
      days: "days",
      hour: "hours",
      hours: "hours",
      minute: "minutes",
      minutes: "minutes",
      second: "seconds",
      seconds: "seconds",
      millisecond: "milliseconds",
      milliseconds: "milliseconds"
    }[e && e.toLowerCase()];
    if (!n)
      throw new Yn(e);
    return n;
  }
  /**
   * Check if an object is a Duration. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDuration(e) {
    return e && e.isLuxonDuration || !1;
  }
  /**
   * Get  the locale of a Duration, such 'en-GB'
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a Duration, such 'beng'. The numbering system is used when formatting the Duration
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Returns a string representation of this Duration formatted according to the specified format string. You may use these tokens:
   * * `S` for milliseconds
   * * `s` for seconds
   * * `m` for minutes
   * * `h` for hours
   * * `d` for days
   * * `w` for weeks
   * * `M` for months
   * * `y` for years
   * Notes:
   * * Add padding by repeating the token, e.g. "yy" pads the years to two digits, "hhhh" pads the hours out to four digits
   * * Tokens can be escaped by wrapping with single quotes.
   * * The duration will be converted to the set of units in the format string using {@link Duration#shiftTo} and the Durations's conversion accuracy setting.
   * @param {string} fmt - the format string
   * @param {Object} opts - options
   * @param {boolean} [opts.floor=true] - floor numerical values
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("y d s") //=> "1 6 2"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("yy dd sss") //=> "01 06 002"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("M S") //=> "12 518402000"
   * @return {string}
   */
  toFormat(e, n = {}) {
    const r = {
      ...n,
      floor: n.round !== !1 && n.floor !== !1
    };
    return this.isValid ? Q.create(this.loc, r).formatDurationFromString(this, e) : Sn;
  }
  /**
   * Returns a string representation of a Duration with all units included.
   * To modify its behavior, use `listStyle` and any Intl.NumberFormat option, though `unitDisplay` is especially relevant.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   * @param {Object} opts - Formatting options. Accepts the same keys as the options parameter of the native `Intl.NumberFormat` constructor, as well as `listStyle`.
   * @param {string} [opts.listStyle='narrow'] - How to format the merged list. Corresponds to the `style` property of the options parameter of the native `Intl.ListFormat` constructor.
   * @example
   * ```js
   * var dur = Duration.fromObject({ days: 1, hours: 5, minutes: 6 })
   * dur.toHuman() //=> '1 day, 5 hours, 6 minutes'
   * dur.toHuman({ listStyle: "long" }) //=> '1 day, 5 hours, and 6 minutes'
   * dur.toHuman({ unitDisplay: "short" }) //=> '1 day, 5 hr, 6 min'
   * ```
   */
  toHuman(e = {}) {
    if (!this.isValid)
      return Sn;
    const n = Ne.map((r) => {
      const s = this.values[r];
      return M(s) ? null : this.loc.numberFormatter({ style: "unit", unitDisplay: "long", ...e, unit: r.slice(0, -1) }).format(s);
    }).filter((r) => r);
    return this.loc.listFormatter({ type: "conjunction", style: e.listStyle || "narrow", ...e }).format(n);
  }
  /**
   * Returns a JavaScript object with this Duration's values.
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toObject() //=> { years: 1, days: 6, seconds: 2 }
   * @return {Object}
   */
  toObject() {
    return this.isValid ? { ...this.values } : {};
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromObject({ years: 3, seconds: 45 }).toISO() //=> 'P3YT45S'
   * @example Duration.fromObject({ months: 4, seconds: 45 }).toISO() //=> 'P4MT45S'
   * @example Duration.fromObject({ months: 5 }).toISO() //=> 'P5M'
   * @example Duration.fromObject({ minutes: 5 }).toISO() //=> 'PT5M'
   * @example Duration.fromObject({ milliseconds: 6 }).toISO() //=> 'PT0.006S'
   * @return {string}
   */
  toISO() {
    if (!this.isValid)
      return null;
    let e = "P";
    return this.years !== 0 && (e += this.years + "Y"), (this.months !== 0 || this.quarters !== 0) && (e += this.months + this.quarters * 3 + "M"), this.weeks !== 0 && (e += this.weeks + "W"), this.days !== 0 && (e += this.days + "D"), (this.hours !== 0 || this.minutes !== 0 || this.seconds !== 0 || this.milliseconds !== 0) && (e += "T"), this.hours !== 0 && (e += this.hours + "H"), this.minutes !== 0 && (e += this.minutes + "M"), (this.seconds !== 0 || this.milliseconds !== 0) && (e += en(this.seconds + this.milliseconds / 1e3, 3) + "S"), e === "P" && (e += "T0S"), e;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration, formatted as a time of day.
   * Note that this will return null if the duration is invalid, negative, or equal to or greater than 24 hours.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example Duration.fromObject({ hours: 11 }).toISOTime() //=> '11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressMilliseconds: true }) //=> '11:00:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressSeconds: true }) //=> '11:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ includePrefix: true }) //=> 'T11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ format: 'basic' }) //=> '110000.000'
   * @return {string}
   */
  toISOTime(e = {}) {
    if (!this.isValid)
      return null;
    const n = this.toMillis();
    return n < 0 || n >= 864e5 ? null : (e = {
      suppressMilliseconds: !1,
      suppressSeconds: !1,
      includePrefix: !1,
      format: "extended",
      ...e,
      includeOffset: !1
    }, N.fromMillis(n, { zone: "UTC" }).toISOTime(e));
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in debugging.
   * @return {string}
   */
  toString() {
    return this.toISO();
  }
  /**
   * Returns a string representation of this Duration appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Duration { values: ${JSON.stringify(this.values)} }` : `Duration { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns an milliseconds value of this Duration.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? zr(this.matrix, this.values) : NaN;
  }
  /**
   * Returns an milliseconds value of this Duration. Alias of {@link toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Make this Duration longer by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  plus(e) {
    if (!this.isValid)
      return this;
    const n = D.fromDurationLike(e), r = {};
    for (const s of Ne)
      (ze(n.values, s) || ze(this.values, s)) && (r[s] = n.get(s) + this.get(s));
    return ye(this, { values: r }, !0);
  }
  /**
   * Make this Duration shorter by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  minus(e) {
    if (!this.isValid)
      return this;
    const n = D.fromDurationLike(e);
    return this.plus(n.negate());
  }
  /**
   * Scale this Duration by the specified amount. Return a newly-constructed Duration.
   * @param {function} fn - The function to apply to each unit. Arity is 1 or 2: the value of the unit and, optionally, the unit name. Must return a number.
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits(x => x * 2) //=> { hours: 2, minutes: 60 }
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits((x, u) => u === "hours" ? x * 2 : x) //=> { hours: 2, minutes: 30 }
   * @return {Duration}
   */
  mapUnits(e) {
    if (!this.isValid)
      return this;
    const n = {};
    for (const r of Object.keys(this.values))
      n[r] = br(e(this.values[r], r));
    return ye(this, { values: n }, !0);
  }
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example Duration.fromObject({years: 2, days: 3}).get('years') //=> 2
   * @example Duration.fromObject({years: 2, days: 3}).get('months') //=> 0
   * @example Duration.fromObject({years: 2, days: 3}).get('days') //=> 3
   * @return {number}
   */
  get(e) {
    return this[D.normalizeUnit(e)];
  }
  /**
   * "Set" the values of specified units. Return a newly-constructed Duration.
   * @param {Object} values - a mapping of units to numbers
   * @example dur.set({ years: 2017 })
   * @example dur.set({ hours: 8, minutes: 30 })
   * @return {Duration}
   */
  set(e) {
    if (!this.isValid)
      return this;
    const n = { ...this.values, ...pt(e, D.normalizeUnit) };
    return ye(this, { values: n });
  }
  /**
   * "Set" the locale and/or numberingSystem.  Returns a newly-constructed Duration.
   * @example dur.reconfigure({ locale: 'en-GB' })
   * @return {Duration}
   */
  reconfigure({ locale: e, numberingSystem: n, conversionAccuracy: r, matrix: s } = {}) {
    const i = { loc: this.loc.clone({ locale: e, numberingSystem: n }), matrix: s, conversionAccuracy: r };
    return ye(this, i);
  }
  /**
   * Return the length of the duration in the specified unit.
   * @param {string} unit - a unit such as 'minutes' or 'days'
   * @example Duration.fromObject({years: 1}).as('days') //=> 365
   * @example Duration.fromObject({years: 1}).as('months') //=> 12
   * @example Duration.fromObject({hours: 60}).as('days') //=> 2.5
   * @return {number}
   */
  as(e) {
    return this.isValid ? this.shiftTo(e).get(e) : NaN;
  }
  /**
   * Reduce this Duration to its canonical representation in its current units.
   * Assuming the overall value of the Duration is positive, this means:
   * - excessive values for lower-order units are converted to higher-order units (if possible, see first and second example)
   * - negative lower-order units are converted to higher order units (there must be such a higher order unit, otherwise
   *   the overall value would be negative, see third example)
   * - fractional values for higher-order units are converted to lower-order units (if possible, see fourth example)
   *
   * If the overall value is negative, the result of this method is equivalent to `this.negate().normalize().negate()`.
   * @example Duration.fromObject({ years: 2, days: 5000 }).normalize().toObject() //=> { years: 15, days: 255 }
   * @example Duration.fromObject({ days: 5000 }).normalize().toObject() //=> { days: 5000 }
   * @example Duration.fromObject({ hours: 12, minutes: -45 }).normalize().toObject() //=> { hours: 11, minutes: 15 }
   * @example Duration.fromObject({ years: 2.5, days: 0, hours: 0 }).normalize().toObject() //=> { years: 2, days: 182, hours: 12 }
   * @return {Duration}
   */
  normalize() {
    if (!this.isValid)
      return this;
    const e = this.toObject();
    return Cn(this.matrix, e), ye(this, { values: e }, !0);
  }
  /**
   * Rescale units to its largest representation
   * @example Duration.fromObject({ milliseconds: 90000 }).rescale().toObject() //=> { minutes: 1, seconds: 30 }
   * @return {Duration}
   */
  rescale() {
    if (!this.isValid)
      return this;
    const e = Xi(this.normalize().shiftToAll().toObject());
    return ye(this, { values: e }, !0);
  }
  /**
   * Convert this Duration into its representation in a different set of units.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).shiftTo('minutes', 'milliseconds').toObject() //=> { minutes: 60, milliseconds: 30000 }
   * @return {Duration}
   */
  shiftTo(...e) {
    if (!this.isValid)
      return this;
    if (e.length === 0)
      return this;
    e = e.map((i) => D.normalizeUnit(i));
    const n = {}, r = {}, s = this.toObject();
    let a;
    for (const i of Ne)
      if (e.indexOf(i) >= 0) {
        a = i;
        let o = 0;
        for (const u in r)
          o += this.matrix[u][i] * r[u], r[u] = 0;
        Te(s[i]) && (o += s[i]);
        const c = Math.trunc(o);
        n[i] = c, r[i] = (o * 1e3 - c * 1e3) / 1e3;
      } else
        Te(s[i]) && (r[i] = s[i]);
    for (const i in r)
      r[i] !== 0 && (n[a] += i === a ? r[i] : r[i] / this.matrix[a][i]);
    return Cn(this.matrix, n), ye(this, { values: n }, !0);
  }
  /**
   * Shift this Duration to all available units.
   * Same as shiftTo("years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds")
   * @return {Duration}
   */
  shiftToAll() {
    return this.isValid ? this.shiftTo(
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    ) : this;
  }
  /**
   * Return the negative of this Duration.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).negate().toObject() //=> { hours: -1, seconds: -30 }
   * @return {Duration}
   */
  negate() {
    if (!this.isValid)
      return this;
    const e = {};
    for (const n of Object.keys(this.values))
      e[n] = this.values[n] === 0 ? 0 : -this.values[n];
    return ye(this, { values: e }, !0);
  }
  /**
   * Get the years.
   * @type {number}
   */
  get years() {
    return this.isValid ? this.values.years || 0 : NaN;
  }
  /**
   * Get the quarters.
   * @type {number}
   */
  get quarters() {
    return this.isValid ? this.values.quarters || 0 : NaN;
  }
  /**
   * Get the months.
   * @type {number}
   */
  get months() {
    return this.isValid ? this.values.months || 0 : NaN;
  }
  /**
   * Get the weeks
   * @type {number}
   */
  get weeks() {
    return this.isValid ? this.values.weeks || 0 : NaN;
  }
  /**
   * Get the days.
   * @type {number}
   */
  get days() {
    return this.isValid ? this.values.days || 0 : NaN;
  }
  /**
   * Get the hours.
   * @type {number}
   */
  get hours() {
    return this.isValid ? this.values.hours || 0 : NaN;
  }
  /**
   * Get the minutes.
   * @type {number}
   */
  get minutes() {
    return this.isValid ? this.values.minutes || 0 : NaN;
  }
  /**
   * Get the seconds.
   * @return {number}
   */
  get seconds() {
    return this.isValid ? this.values.seconds || 0 : NaN;
  }
  /**
   * Get the milliseconds.
   * @return {number}
   */
  get milliseconds() {
    return this.isValid ? this.values.milliseconds || 0 : NaN;
  }
  /**
   * Returns whether the Duration is invalid. Invalid durations are returned by diff operations
   * on invalid DateTimes or Intervals.
   * @return {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this Duration became invalid, or null if the Duration is valid
   * @return {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Duration became invalid, or null if the Duration is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Equality check
   * Two Durations are equal iff they have the same units and the same values for each unit.
   * @param {Duration} other
   * @return {boolean}
   */
  equals(e) {
    if (!this.isValid || !e.isValid || !this.loc.equals(e.loc))
      return !1;
    function n(r, s) {
      return r === void 0 || r === 0 ? s === void 0 || s === 0 : r === s;
    }
    for (const r of Ne)
      if (!n(this.values[r], e.values[r]))
        return !1;
    return !0;
  }
}
const De = "Invalid Interval";
function eo(t, e) {
  return !t || !t.isValid ? B.invalid("missing or invalid start") : !e || !e.isValid ? B.invalid("missing or invalid end") : e < t ? B.invalid(
    "end before start",
    `The end of an interval must be after its start, but you had start=${t.toISO()} and end=${e.toISO()}`
  ) : null;
}
class B {
  /**
   * @private
   */
  constructor(e) {
    this.s = e.start, this.e = e.end, this.invalid = e.invalid || null, this.isLuxonInterval = !0;
  }
  /**
   * Create an invalid Interval.
   * @param {string} reason - simple string of why this Interval is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Interval}
   */
  static invalid(e, n = null) {
    if (!e)
      throw new X("need to specify a reason the Interval is invalid");
    const r = e instanceof ue ? e : new ue(e, n);
    if (Y.throwOnInvalid)
      throw new Sa(r);
    return new B({ invalid: r });
  }
  /**
   * Create an Interval from a start DateTime and an end DateTime. Inclusive of the start but not the end.
   * @param {DateTime|Date|Object} start
   * @param {DateTime|Date|Object} end
   * @return {Interval}
   */
  static fromDateTimes(e, n) {
    const r = Ue(e), s = Ue(n), a = eo(r, s);
    return a ?? new B({
      start: r,
      end: s
    });
  }
  /**
   * Create an Interval from a start DateTime and a Duration to extend to.
   * @param {DateTime|Date|Object} start
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static after(e, n) {
    const r = D.fromDurationLike(n), s = Ue(e);
    return B.fromDateTimes(s, s.plus(r));
  }
  /**
   * Create an Interval from an end DateTime and a Duration to extend backwards to.
   * @param {DateTime|Date|Object} end
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static before(e, n) {
    const r = D.fromDurationLike(n), s = Ue(e);
    return B.fromDateTimes(s.minus(r), s);
  }
  /**
   * Create an Interval from an ISO 8601 string.
   * Accepts `<start>/<end>`, `<start>/<duration>`, and `<duration>/<end>` formats.
   * @param {string} text - the ISO string to parse
   * @param {Object} [opts] - options to pass {@link DateTime#fromISO} and optionally {@link Duration#fromISO}
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {Interval}
   */
  static fromISO(e, n) {
    const [r, s] = (e || "").split("/", 2);
    if (r && s) {
      let a, i;
      try {
        a = N.fromISO(r, n), i = a.isValid;
      } catch {
        i = !1;
      }
      let o, c;
      try {
        o = N.fromISO(s, n), c = o.isValid;
      } catch {
        c = !1;
      }
      if (i && c)
        return B.fromDateTimes(a, o);
      if (i) {
        const u = D.fromISO(s, n);
        if (u.isValid)
          return B.after(a, u);
      } else if (c) {
        const u = D.fromISO(r, n);
        if (u.isValid)
          return B.before(o, u);
      }
    }
    return B.invalid("unparsable", `the input "${e}" can't be parsed as ISO 8601`);
  }
  /**
   * Check if an object is an Interval. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isInterval(e) {
    return e && e.isLuxonInterval || !1;
  }
  /**
   * Returns the start of the Interval
   * @type {DateTime}
   */
  get start() {
    return this.isValid ? this.s : null;
  }
  /**
   * Returns the end of the Interval
   * @type {DateTime}
   */
  get end() {
    return this.isValid ? this.e : null;
  }
  /**
   * Returns whether this Interval's end is at least its start, meaning that the Interval isn't 'backwards'.
   * @type {boolean}
   */
  get isValid() {
    return this.invalidReason === null;
  }
  /**
   * Returns an error code if this Interval is invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Interval became invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Returns the length of the Interval in the specified unit.
   * @param {string} unit - the unit (such as 'hours' or 'days') to return the length in.
   * @return {number}
   */
  length(e = "milliseconds") {
    return this.isValid ? this.toDuration(e).get(e) : NaN;
  }
  /**
   * Returns the count of minutes, hours, days, months, or years included in the Interval, even in part.
   * Unlike {@link Interval#length} this counts sections of the calendar, not periods of time, e.g. specifying 'day'
   * asks 'what dates are included in this interval?', not 'how many days long is this interval?'
   * @param {string} [unit='milliseconds'] - the unit of time to count.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; this operation will always use the locale of the start DateTime
   * @return {number}
   */
  count(e = "milliseconds", n) {
    if (!this.isValid)
      return NaN;
    const r = this.start.startOf(e, n);
    let s;
    return n != null && n.useLocaleWeeks ? s = this.end.reconfigure({ locale: r.locale }) : s = this.end, s = s.startOf(e, n), Math.floor(s.diff(r, e).get(e)) + (s.valueOf() !== this.end.valueOf());
  }
  /**
   * Returns whether this Interval's start and end are both in the same unit of time
   * @param {string} unit - the unit of time to check sameness on
   * @return {boolean}
   */
  hasSame(e) {
    return this.isValid ? this.isEmpty() || this.e.minus(1).hasSame(this.s, e) : !1;
  }
  /**
   * Return whether this Interval has the same start and end DateTimes.
   * @return {boolean}
   */
  isEmpty() {
    return this.s.valueOf() === this.e.valueOf();
  }
  /**
   * Return whether this Interval's start is after the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isAfter(e) {
    return this.isValid ? this.s > e : !1;
  }
  /**
   * Return whether this Interval's end is before the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isBefore(e) {
    return this.isValid ? this.e <= e : !1;
  }
  /**
   * Return whether this Interval contains the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  contains(e) {
    return this.isValid ? this.s <= e && this.e > e : !1;
  }
  /**
   * "Sets" the start and/or end dates. Returns a newly-constructed Interval.
   * @param {Object} values - the values to set
   * @param {DateTime} values.start - the starting DateTime
   * @param {DateTime} values.end - the ending DateTime
   * @return {Interval}
   */
  set({ start: e, end: n } = {}) {
    return this.isValid ? B.fromDateTimes(e || this.s, n || this.e) : this;
  }
  /**
   * Split this Interval at each of the specified DateTimes
   * @param {...DateTime} dateTimes - the unit of time to count.
   * @return {Array}
   */
  splitAt(...e) {
    if (!this.isValid)
      return [];
    const n = e.map(Ue).filter((i) => this.contains(i)).sort((i, o) => i.toMillis() - o.toMillis()), r = [];
    let { s } = this, a = 0;
    for (; s < this.e; ) {
      const i = n[a] || this.e, o = +i > +this.e ? this.e : i;
      r.push(B.fromDateTimes(s, o)), s = o, a += 1;
    }
    return r;
  }
  /**
   * Split this Interval into smaller Intervals, each of the specified length.
   * Left over time is grouped into a smaller interval
   * @param {Duration|Object|number} duration - The length of each resulting interval.
   * @return {Array}
   */
  splitBy(e) {
    const n = D.fromDurationLike(e);
    if (!this.isValid || !n.isValid || n.as("milliseconds") === 0)
      return [];
    let { s: r } = this, s = 1, a;
    const i = [];
    for (; r < this.e; ) {
      const o = this.start.plus(n.mapUnits((c) => c * s));
      a = +o > +this.e ? this.e : o, i.push(B.fromDateTimes(r, a)), r = a, s += 1;
    }
    return i;
  }
  /**
   * Split this Interval into the specified number of smaller intervals.
   * @param {number} numberOfParts - The number of Intervals to divide the Interval into.
   * @return {Array}
   */
  divideEqually(e) {
    return this.isValid ? this.splitBy(this.length() / e).slice(0, e) : [];
  }
  /**
   * Return whether this Interval overlaps with the specified Interval
   * @param {Interval} other
   * @return {boolean}
   */
  overlaps(e) {
    return this.e > e.s && this.s < e.e;
  }
  /**
   * Return whether this Interval's end is adjacent to the specified Interval's start.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsStart(e) {
    return this.isValid ? +this.e == +e.s : !1;
  }
  /**
   * Return whether this Interval's start is adjacent to the specified Interval's end.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsEnd(e) {
    return this.isValid ? +e.e == +this.s : !1;
  }
  /**
   * Return whether this Interval engulfs the start and end of the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  engulfs(e) {
    return this.isValid ? this.s <= e.s && this.e >= e.e : !1;
  }
  /**
   * Return whether this Interval has the same start and end as the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  equals(e) {
    return !this.isValid || !e.isValid ? !1 : this.s.equals(e.s) && this.e.equals(e.e);
  }
  /**
   * Return an Interval representing the intersection of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the maximum start time and the minimum end time of the two Intervals.
   * Returns null if the intersection is empty, meaning, the intervals don't intersect.
   * @param {Interval} other
   * @return {Interval}
   */
  intersection(e) {
    if (!this.isValid)
      return this;
    const n = this.s > e.s ? this.s : e.s, r = this.e < e.e ? this.e : e.e;
    return n >= r ? null : B.fromDateTimes(n, r);
  }
  /**
   * Return an Interval representing the union of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the minimum start time and the maximum end time of the two Intervals.
   * @param {Interval} other
   * @return {Interval}
   */
  union(e) {
    if (!this.isValid)
      return this;
    const n = this.s < e.s ? this.s : e.s, r = this.e > e.e ? this.e : e.e;
    return B.fromDateTimes(n, r);
  }
  /**
   * Merge an array of Intervals into a equivalent minimal set of Intervals.
   * Combines overlapping and adjacent Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static merge(e) {
    const [n, r] = e.sort((s, a) => s.s - a.s).reduce(
      ([s, a], i) => a ? a.overlaps(i) || a.abutsStart(i) ? [s, a.union(i)] : [s.concat([a]), i] : [s, i],
      [[], null]
    );
    return r && n.push(r), n;
  }
  /**
   * Return an array of Intervals representing the spans of time that only appear in one of the specified Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static xor(e) {
    let n = null, r = 0;
    const s = [], a = e.map((c) => [
      { time: c.s, type: "s" },
      { time: c.e, type: "e" }
    ]), i = Array.prototype.concat(...a), o = i.sort((c, u) => c.time - u.time);
    for (const c of o)
      r += c.type === "s" ? 1 : -1, r === 1 ? n = c.time : (n && +n != +c.time && s.push(B.fromDateTimes(n, c.time)), n = null);
    return B.merge(s);
  }
  /**
   * Return an Interval representing the span of time in this Interval that doesn't overlap with any of the specified Intervals.
   * @param {...Interval} intervals
   * @return {Array}
   */
  difference(...e) {
    return B.xor([this].concat(e)).map((n) => this.intersection(n)).filter((n) => n && !n.isEmpty());
  }
  /**
   * Returns a string representation of this Interval appropriate for debugging.
   * @return {string}
   */
  toString() {
    return this.isValid ? `[${this.s.toISO()} – ${this.e.toISO()})` : De;
  }
  /**
   * Returns a string representation of this Interval appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Interval { start: ${this.s.toISO()}, end: ${this.e.toISO()} }` : `Interval { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns a localized string representing this Interval. Accepts the same options as the
   * Intl.DateTimeFormat constructor and any presets defined by Luxon, such as
   * {@link DateTime.DATE_FULL} or {@link DateTime.TIME_SIMPLE}. The exact behavior of this method
   * is browser-specific, but in general it will return an appropriate representation of the
   * Interval in the assigned locale. Defaults to the system's locale if no locale has been
   * specified.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {Object} [formatOpts=DateTime.DATE_SHORT] - Either a DateTime preset or
   * Intl.DateTimeFormat constructor options.
   * @param {Object} opts - Options to override the configuration of the start DateTime.
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(); //=> 11/7/2022 – 11/8/2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL); //=> November 7 – 8, 2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL, { locale: 'fr-FR' }); //=> 7–8 novembre 2022
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString(DateTime.TIME_SIMPLE); //=> 6:00 – 8:00 PM
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> Mon, Nov 07, 6:00 – 8:00 p
   * @return {string}
   */
  toLocaleString(e = gt, n = {}) {
    return this.isValid ? Q.create(this.s.loc.clone(n), e).formatInterval(this) : De;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Interval.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISO(e) {
    return this.isValid ? `${this.s.toISO(e)}/${this.e.toISO(e)}` : De;
  }
  /**
   * Returns an ISO 8601-compliant string representation of date of this Interval.
   * The time components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {string}
   */
  toISODate() {
    return this.isValid ? `${this.s.toISODate()}/${this.e.toISODate()}` : De;
  }
  /**
   * Returns an ISO 8601-compliant string representation of time of this Interval.
   * The date components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISOTime(e) {
    return this.isValid ? `${this.s.toISOTime(e)}/${this.e.toISOTime(e)}` : De;
  }
  /**
   * Returns a string representation of this Interval formatted according to the specified format
   * string. **You may not want this.** See {@link Interval#toLocaleString} for a more flexible
   * formatting tool.
   * @param {string} dateFormat - The format string. This string formats the start and end time.
   * See {@link DateTime#toFormat} for details.
   * @param {Object} opts - Options.
   * @param {string} [opts.separator =  ' – '] - A separator to place between the start and end
   * representations.
   * @return {string}
   */
  toFormat(e, { separator: n = " – " } = {}) {
    return this.isValid ? `${this.s.toFormat(e)}${n}${this.e.toFormat(e)}` : De;
  }
  /**
   * Return a Duration representing the time spanned by this interval.
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example Interval.fromDateTimes(dt1, dt2).toDuration().toObject() //=> { milliseconds: 88489257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('days').toObject() //=> { days: 1.0241812152777778 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes']).toObject() //=> { hours: 24, minutes: 34.82095 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes', 'seconds']).toObject() //=> { hours: 24, minutes: 34, seconds: 49.257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('seconds').toObject() //=> { seconds: 88489.257 }
   * @return {Duration}
   */
  toDuration(e, n) {
    return this.isValid ? this.e.diff(this.s, e, n) : D.invalid(this.invalidReason);
  }
  /**
   * Run mapFn on the interval start and end, returning a new Interval from the resulting DateTimes
   * @param {function} mapFn
   * @return {Interval}
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.toUTC())
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.plus({ hours: 2 }))
   */
  mapEndpoints(e) {
    return B.fromDateTimes(e(this.s), e(this.e));
  }
}
class ot {
  /**
   * Return whether the specified zone contains a DST.
   * @param {string|Zone} [zone='local'] - Zone to check. Defaults to the environment's local zone.
   * @return {boolean}
   */
  static hasDST(e = Y.defaultZone) {
    const n = N.now().setZone(e).set({ month: 12 });
    return !e.isUniversal && n.offset !== n.set({ month: 6 }).offset;
  }
  /**
   * Return whether the specified zone is a valid IANA specifier.
   * @param {string} zone - Zone to check
   * @return {boolean}
   */
  static isValidIANAZone(e) {
    return fe.isValidZone(e);
  }
  /**
   * Converts the input into a {@link Zone} instance.
   *
   * * If `input` is already a Zone instance, it is returned unchanged.
   * * If `input` is a string containing a valid time zone name, a Zone instance
   *   with that name is returned.
   * * If `input` is a string that doesn't refer to a known time zone, a Zone
   *   instance with {@link Zone#isValid} == false is returned.
   * * If `input is a number, a Zone instance with the specified fixed offset
   *   in minutes is returned.
   * * If `input` is `null` or `undefined`, the default zone is returned.
   * @param {string|Zone|number} [input] - the value to be converted
   * @return {Zone}
   */
  static normalizeZone(e) {
    return ke(e, Y.defaultZone);
  }
  /**
   * Get the weekday on which the week starts according to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number} the start of the week, 1 for Monday through 7 for Sunday
   */
  static getStartOfWeek({ locale: e = null, locObj: n = null } = {}) {
    return (n || L.create(e)).getStartOfWeek();
  }
  /**
   * Get the minimum number of days necessary in a week before it is considered part of the next year according
   * to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number}
   */
  static getMinimumDaysInFirstWeek({ locale: e = null, locObj: n = null } = {}) {
    return (n || L.create(e)).getMinDaysInFirstWeek();
  }
  /**
   * Get the weekdays, which are considered the weekend according to the given locale
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number[]} an array of weekdays, 1 for Monday through 7 for Sunday
   */
  static getWeekendWeekdays({ locale: e = null, locObj: n = null } = {}) {
    return (n || L.create(e)).getWeekendDays().slice();
  }
  /**
   * Return an array of standalone month names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @example Info.months()[0] //=> 'January'
   * @example Info.months('short')[0] //=> 'Jan'
   * @example Info.months('numeric')[0] //=> '1'
   * @example Info.months('short', { locale: 'fr-CA' } )[0] //=> 'janv.'
   * @example Info.months('numeric', { locale: 'ar' })[0] //=> '١'
   * @example Info.months('long', { outputCalendar: 'islamic' })[0] //=> 'Rabiʻ I'
   * @return {Array}
   */
  static months(e = "long", { locale: n = null, numberingSystem: r = null, locObj: s = null, outputCalendar: a = "gregory" } = {}) {
    return (s || L.create(n, r, a)).months(e);
  }
  /**
   * Return an array of format month names.
   * Format months differ from standalone months in that they're meant to appear next to the day of the month. In some languages, that
   * changes the string.
   * See {@link Info#months}
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @return {Array}
   */
  static monthsFormat(e = "long", { locale: n = null, numberingSystem: r = null, locObj: s = null, outputCalendar: a = "gregory" } = {}) {
    return (s || L.create(n, r, a)).months(e, !0);
  }
  /**
   * Return an array of standalone week names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the weekday representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @example Info.weekdays()[0] //=> 'Monday'
   * @example Info.weekdays('short')[0] //=> 'Mon'
   * @example Info.weekdays('short', { locale: 'fr-CA' })[0] //=> 'lun.'
   * @example Info.weekdays('short', { locale: 'ar' })[0] //=> 'الاثنين'
   * @return {Array}
   */
  static weekdays(e = "long", { locale: n = null, numberingSystem: r = null, locObj: s = null } = {}) {
    return (s || L.create(n, r, null)).weekdays(e);
  }
  /**
   * Return an array of format week names.
   * Format weekdays differ from standalone weekdays in that they're meant to appear next to more date information. In some languages, that
   * changes the string.
   * See {@link Info#weekdays}
   * @param {string} [length='long'] - the length of the month representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale=null] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @return {Array}
   */
  static weekdaysFormat(e = "long", { locale: n = null, numberingSystem: r = null, locObj: s = null } = {}) {
    return (s || L.create(n, r, null)).weekdays(e, !0);
  }
  /**
   * Return an array of meridiems.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.meridiems() //=> [ 'AM', 'PM' ]
   * @example Info.meridiems({ locale: 'my' }) //=> [ 'နံနက်', 'ညနေ' ]
   * @return {Array}
   */
  static meridiems({ locale: e = null } = {}) {
    return L.create(e).meridiems();
  }
  /**
   * Return an array of eras, such as ['BC', 'AD']. The locale can be specified, but the calendar system is always Gregorian.
   * @param {string} [length='short'] - the length of the era representation, such as "short" or "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.eras() //=> [ 'BC', 'AD' ]
   * @example Info.eras('long') //=> [ 'Before Christ', 'Anno Domini' ]
   * @example Info.eras('long', { locale: 'fr' }) //=> [ 'avant Jésus-Christ', 'après Jésus-Christ' ]
   * @return {Array}
   */
  static eras(e = "short", { locale: n = null } = {}) {
    return L.create(n, null, "gregory").eras(e);
  }
  /**
   * Return the set of available features in this environment.
   * Some features of Luxon are not available in all environments. For example, on older browsers, relative time formatting support is not available. Use this function to figure out if that's the case.
   * Keys:
   * * `relative`: whether this environment supports relative time formatting
   * * `localeWeek`: whether this environment supports different weekdays for the start of the week based on the locale
   * @example Info.features() //=> { relative: false, localeWeek: true }
   * @return {Object}
   */
  static features() {
    return { relative: kr(), localeWeek: xr() };
  }
}
function Mn(t, e) {
  const n = (s) => s.toUTC(0, { keepLocalTime: !0 }).startOf("day").valueOf(), r = n(e) - n(t);
  return Math.floor(D.fromMillis(r).as("days"));
}
function to(t, e, n) {
  const r = [
    ["years", (c, u) => u.year - c.year],
    ["quarters", (c, u) => u.quarter - c.quarter + (u.year - c.year) * 4],
    ["months", (c, u) => u.month - c.month + (u.year - c.year) * 12],
    [
      "weeks",
      (c, u) => {
        const d = Mn(c, u);
        return (d - d % 7) / 7;
      }
    ],
    ["days", Mn]
  ], s = {}, a = t;
  let i, o;
  for (const [c, u] of r)
    n.indexOf(c) >= 0 && (i = c, s[c] = u(t, e), o = a.plus(s), o > e ? (s[c]--, t = a.plus(s), t > e && (o = t, s[c]--, t = a.plus(s))) : t = o);
  return [t, s, o, i];
}
function no(t, e, n, r) {
  let [s, a, i, o] = to(t, e, n);
  const c = e - s, u = n.filter(
    (m) => ["hours", "minutes", "seconds", "milliseconds"].indexOf(m) >= 0
  );
  u.length === 0 && (i < e && (i = s.plus({ [o]: 1 })), i !== s && (a[o] = (a[o] || 0) + c / (i - s)));
  const d = D.fromObject(a, r);
  return u.length > 0 ? D.fromMillis(c, r).shiftTo(...u).plus(d) : d;
}
const sn = {
  arab: "[٠-٩]",
  arabext: "[۰-۹]",
  bali: "[᭐-᭙]",
  beng: "[০-৯]",
  deva: "[०-९]",
  fullwide: "[０-９]",
  gujr: "[૦-૯]",
  hanidec: "[〇|一|二|三|四|五|六|七|八|九]",
  khmr: "[០-៩]",
  knda: "[೦-೯]",
  laoo: "[໐-໙]",
  limb: "[᥆-᥏]",
  mlym: "[൦-൯]",
  mong: "[᠐-᠙]",
  mymr: "[၀-၉]",
  orya: "[୦-୯]",
  tamldec: "[௦-௯]",
  telu: "[౦-౯]",
  thai: "[๐-๙]",
  tibt: "[༠-༩]",
  latn: "\\d"
}, On = {
  arab: [1632, 1641],
  arabext: [1776, 1785],
  bali: [6992, 7001],
  beng: [2534, 2543],
  deva: [2406, 2415],
  fullwide: [65296, 65303],
  gujr: [2790, 2799],
  khmr: [6112, 6121],
  knda: [3302, 3311],
  laoo: [3792, 3801],
  limb: [6470, 6479],
  mlym: [3430, 3439],
  mong: [6160, 6169],
  mymr: [4160, 4169],
  orya: [2918, 2927],
  tamldec: [3046, 3055],
  telu: [3174, 3183],
  thai: [3664, 3673],
  tibt: [3872, 3881]
}, ro = sn.hanidec.replace(/[\[|\]]/g, "").split("");
function so(t) {
  let e = parseInt(t, 10);
  if (isNaN(e)) {
    e = "";
    for (let n = 0; n < t.length; n++) {
      const r = t.charCodeAt(n);
      if (t[n].search(sn.hanidec) !== -1)
        e += ro.indexOf(t[n]);
      else
        for (const s in On) {
          const [a, i] = On[s];
          r >= a && r <= i && (e += r - a);
        }
    }
    return parseInt(e, 10);
  } else
    return e;
}
function oe({ numberingSystem: t }, e = "") {
  return new RegExp(`${sn[t || "latn"]}${e}`);
}
const ao = "missing Intl.DateTimeFormat.formatToParts support";
function I(t, e = (n) => n) {
  return { regex: t, deser: ([n]) => e(so(n)) };
}
const io = " ", Rr = `[ ${io}]`, Ar = new RegExp(Rr, "g");
function oo(t) {
  return t.replace(/\./g, "\\.?").replace(Ar, Rr);
}
function Dn(t) {
  return t.replace(/\./g, "").replace(Ar, " ").toLowerCase();
}
function le(t, e) {
  return t === null ? null : {
    regex: RegExp(t.map(oo).join("|")),
    deser: ([n]) => t.findIndex((r) => Dn(n) === Dn(r)) + e
  };
}
function In(t, e) {
  return { regex: t, deser: ([, n, r]) => bt(n, r), groups: e };
}
function lt(t) {
  return { regex: t, deser: ([e]) => e };
}
function lo(t) {
  return t.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
}
function co(t, e) {
  const n = oe(e), r = oe(e, "{2}"), s = oe(e, "{3}"), a = oe(e, "{4}"), i = oe(e, "{6}"), o = oe(e, "{1,2}"), c = oe(e, "{1,3}"), u = oe(e, "{1,6}"), d = oe(e, "{1,9}"), m = oe(e, "{2,4}"), f = oe(e, "{4,6}"), h = (w) => ({ regex: RegExp(lo(w.val)), deser: ([v]) => v, literal: !0 }), S = ((w) => {
    if (t.literal)
      return h(w);
    switch (w.val) {
      case "G":
        return le(e.eras("short"), 0);
      case "GG":
        return le(e.eras("long"), 0);
      case "y":
        return I(u);
      case "yy":
        return I(m, qt);
      case "yyyy":
        return I(a);
      case "yyyyy":
        return I(f);
      case "yyyyyy":
        return I(i);
      case "M":
        return I(o);
      case "MM":
        return I(r);
      case "MMM":
        return le(e.months("short", !0), 1);
      case "MMMM":
        return le(e.months("long", !0), 1);
      case "L":
        return I(o);
      case "LL":
        return I(r);
      case "LLL":
        return le(e.months("short", !1), 1);
      case "LLLL":
        return le(e.months("long", !1), 1);
      case "d":
        return I(o);
      case "dd":
        return I(r);
      case "o":
        return I(c);
      case "ooo":
        return I(s);
      case "HH":
        return I(r);
      case "H":
        return I(o);
      case "hh":
        return I(r);
      case "h":
        return I(o);
      case "mm":
        return I(r);
      case "m":
        return I(o);
      case "q":
        return I(o);
      case "qq":
        return I(r);
      case "s":
        return I(o);
      case "ss":
        return I(r);
      case "S":
        return I(c);
      case "SSS":
        return I(s);
      case "u":
        return lt(d);
      case "uu":
        return lt(o);
      case "uuu":
        return I(n);
      case "a":
        return le(e.meridiems(), 0);
      case "kkkk":
        return I(a);
      case "kk":
        return I(m, qt);
      case "W":
        return I(o);
      case "WW":
        return I(r);
      case "E":
      case "c":
        return I(n);
      case "EEE":
        return le(e.weekdays("short", !1), 1);
      case "EEEE":
        return le(e.weekdays("long", !1), 1);
      case "ccc":
        return le(e.weekdays("short", !0), 1);
      case "cccc":
        return le(e.weekdays("long", !0), 1);
      case "Z":
      case "ZZ":
        return In(new RegExp(`([+-]${o.source})(?::(${r.source}))?`), 2);
      case "ZZZ":
        return In(new RegExp(`([+-]${o.source})(${r.source})?`), 2);
      case "z":
        return lt(/[a-z_+-/]{1,256}?/i);
      case " ":
        return lt(/[^\S\n\r]/);
      default:
        return h(w);
    }
  })(t) || {
    invalidReason: ao
  };
  return S.token = t, S;
}
const uo = {
  year: {
    "2-digit": "yy",
    numeric: "yyyyy"
  },
  month: {
    numeric: "M",
    "2-digit": "MM",
    short: "MMM",
    long: "MMMM"
  },
  day: {
    numeric: "d",
    "2-digit": "dd"
  },
  weekday: {
    short: "EEE",
    long: "EEEE"
  },
  dayperiod: "a",
  dayPeriod: "a",
  hour12: {
    numeric: "h",
    "2-digit": "hh"
  },
  hour24: {
    numeric: "H",
    "2-digit": "HH"
  },
  minute: {
    numeric: "m",
    "2-digit": "mm"
  },
  second: {
    numeric: "s",
    "2-digit": "ss"
  },
  timeZoneName: {
    long: "ZZZZZ",
    short: "ZZZ"
  }
};
function mo(t, e, n) {
  const { type: r, value: s } = t;
  if (r === "literal") {
    const c = /^\s+$/.test(s);
    return {
      literal: !c,
      val: c ? " " : s
    };
  }
  const a = e[r];
  let i = r;
  r === "hour" && (e.hour12 != null ? i = e.hour12 ? "hour12" : "hour24" : e.hourCycle != null ? e.hourCycle === "h11" || e.hourCycle === "h12" ? i = "hour12" : i = "hour24" : i = n.hour12 ? "hour12" : "hour24");
  let o = uo[i];
  if (typeof o == "object" && (o = o[a]), o)
    return {
      literal: !1,
      val: o
    };
}
function ho(t) {
  return [`^${t.map((n) => n.regex).reduce((n, r) => `${n}(${r.source})`, "")}$`, t];
}
function fo(t, e, n) {
  const r = t.match(e);
  if (r) {
    const s = {};
    let a = 1;
    for (const i in n)
      if (ze(n, i)) {
        const o = n[i], c = o.groups ? o.groups + 1 : 1;
        !o.literal && o.token && (s[o.token.val[0]] = o.deser(r.slice(a, a + c))), a += c;
      }
    return [r, s];
  } else
    return [r, {}];
}
function go(t) {
  const e = (a) => {
    switch (a) {
      case "S":
        return "millisecond";
      case "s":
        return "second";
      case "m":
        return "minute";
      case "h":
      case "H":
        return "hour";
      case "d":
        return "day";
      case "o":
        return "ordinal";
      case "L":
      case "M":
        return "month";
      case "y":
        return "year";
      case "E":
      case "c":
        return "weekday";
      case "W":
        return "weekNumber";
      case "k":
        return "weekYear";
      case "q":
        return "quarter";
      default:
        return null;
    }
  };
  let n = null, r;
  return M(t.z) || (n = fe.create(t.z)), M(t.Z) || (n || (n = new K(t.Z)), r = t.Z), M(t.q) || (t.M = (t.q - 1) * 3 + 1), M(t.h) || (t.h < 12 && t.a === 1 ? t.h += 12 : t.h === 12 && t.a === 0 && (t.h = 0)), t.G === 0 && t.y && (t.y = -t.y), M(t.u) || (t.S = Xt(t.u)), [Object.keys(t).reduce((a, i) => {
    const o = e(i);
    return o && (a[o] = t[i]), a;
  }, {}), n, r];
}
let Vt = null;
function wo() {
  return Vt || (Vt = N.fromMillis(1555555555555)), Vt;
}
function yo(t, e) {
  if (t.literal)
    return t;
  const n = Q.macroTokenToFormatOpts(t.val), r = Br(n, e);
  return r == null || r.includes(void 0) ? t : r;
}
function Zr(t, e) {
  return Array.prototype.concat(...t.map((n) => yo(n, e)));
}
function Hr(t, e, n) {
  const r = Zr(Q.parseFormat(n), t), s = r.map((i) => co(i, t)), a = s.find((i) => i.invalidReason);
  if (a)
    return { input: e, tokens: r, invalidReason: a.invalidReason };
  {
    const [i, o] = ho(s), c = RegExp(i, "i"), [u, d] = fo(e, c, o), [m, f, h] = d ? go(d) : [null, null, void 0];
    if (ze(d, "a") && ze(d, "H"))
      throw new Ee(
        "Can't include meridiem when specifying 24-hour format"
      );
    return { input: e, tokens: r, regex: c, rawMatches: u, matches: d, result: m, zone: f, specificOffset: h };
  }
}
function po(t, e, n) {
  const { result: r, zone: s, specificOffset: a, invalidReason: i } = Hr(t, e, n);
  return [r, s, a, i];
}
function Br(t, e) {
  if (!t)
    return null;
  const r = Q.create(e, t).dtFormatter(wo()), s = r.formatToParts(), a = r.resolvedOptions();
  return s.map((i) => mo(i, t, a));
}
const $t = "Invalid DateTime", En = 864e13;
function ct(t) {
  return new ue("unsupported zone", `the zone "${t.name}" is not supported`);
}
function zt(t) {
  return t.weekData === null && (t.weekData = wt(t.c)), t.weekData;
}
function Rt(t) {
  return t.localWeekData === null && (t.localWeekData = wt(
    t.c,
    t.loc.getMinDaysInFirstWeek(),
    t.loc.getStartOfWeek()
  )), t.localWeekData;
}
function be(t, e) {
  const n = {
    ts: t.ts,
    zone: t.zone,
    c: t.c,
    o: t.o,
    loc: t.loc,
    invalid: t.invalid
  };
  return new N({ ...n, ...e, old: n });
}
function Pr(t, e, n) {
  let r = t - e * 60 * 1e3;
  const s = n.offset(r);
  if (e === s)
    return [r, e];
  r -= (s - e) * 60 * 1e3;
  const a = n.offset(r);
  return s === a ? [r, s] : [t - Math.min(s, a) * 60 * 1e3, Math.max(s, a)];
}
function ut(t, e) {
  t += e * 60 * 1e3;
  const n = new Date(t);
  return {
    year: n.getUTCFullYear(),
    month: n.getUTCMonth() + 1,
    day: n.getUTCDate(),
    hour: n.getUTCHours(),
    minute: n.getUTCMinutes(),
    second: n.getUTCSeconds(),
    millisecond: n.getUTCMilliseconds()
  };
}
function ft(t, e, n) {
  return Pr(vt(t), e, n);
}
function Ln(t, e) {
  const n = t.o, r = t.c.year + Math.trunc(e.years), s = t.c.month + Math.trunc(e.months) + Math.trunc(e.quarters) * 3, a = {
    ...t.c,
    year: r,
    month: s,
    day: Math.min(t.c.day, yt(r, s)) + Math.trunc(e.days) + Math.trunc(e.weeks) * 7
  }, i = D.fromObject({
    years: e.years - Math.trunc(e.years),
    quarters: e.quarters - Math.trunc(e.quarters),
    months: e.months - Math.trunc(e.months),
    weeks: e.weeks - Math.trunc(e.weeks),
    days: e.days - Math.trunc(e.days),
    hours: e.hours,
    minutes: e.minutes,
    seconds: e.seconds,
    milliseconds: e.milliseconds
  }).as("milliseconds"), o = vt(a);
  let [c, u] = Pr(o, n, t.zone);
  return i !== 0 && (c += i, u = t.zone.offset(c)), { ts: c, o: u };
}
function je(t, e, n, r, s, a) {
  const { setZone: i, zone: o } = n;
  if (t && Object.keys(t).length !== 0 || e) {
    const c = e || o, u = N.fromObject(t, {
      ...n,
      zone: c,
      specificOffset: a
    });
    return i ? u : u.setZone(o);
  } else
    return N.invalid(
      new ue("unparsable", `the input "${s}" can't be parsed as ${r}`)
    );
}
function dt(t, e, n = !0) {
  return t.isValid ? Q.create(L.create("en-US"), {
    allowZ: n,
    forceSimple: !0
  }).formatDateTimeFromString(t, e) : null;
}
function At(t, e) {
  const n = t.c.year > 9999 || t.c.year < 0;
  let r = "";
  return n && t.c.year >= 0 && (r += "+"), r += G(t.c.year, n ? 6 : 4), e ? (r += "-", r += G(t.c.month), r += "-", r += G(t.c.day)) : (r += G(t.c.month), r += G(t.c.day)), r;
}
function Fn(t, e, n, r, s, a) {
  let i = G(t.c.hour);
  return e ? (i += ":", i += G(t.c.minute), (t.c.millisecond !== 0 || t.c.second !== 0 || !n) && (i += ":")) : i += G(t.c.minute), (t.c.millisecond !== 0 || t.c.second !== 0 || !n) && (i += G(t.c.second), (t.c.millisecond !== 0 || !r) && (i += ".", i += G(t.c.millisecond, 3))), s && (t.isOffsetFixed && t.offset === 0 && !a ? i += "Z" : t.o < 0 ? (i += "-", i += G(Math.trunc(-t.o / 60)), i += ":", i += G(Math.trunc(-t.o % 60))) : (i += "+", i += G(Math.trunc(t.o / 60)), i += ":", i += G(Math.trunc(t.o % 60)))), a && (i += "[" + t.zone.ianaName + "]"), i;
}
const jr = {
  month: 1,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, ko = {
  weekNumber: 1,
  weekday: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, xo = {
  ordinal: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, Ur = ["year", "month", "day", "hour", "minute", "second", "millisecond"], vo = [
  "weekYear",
  "weekNumber",
  "weekday",
  "hour",
  "minute",
  "second",
  "millisecond"
], bo = ["year", "ordinal", "hour", "minute", "second", "millisecond"];
function No(t) {
  const e = {
    year: "year",
    years: "year",
    month: "month",
    months: "month",
    day: "day",
    days: "day",
    hour: "hour",
    hours: "hour",
    minute: "minute",
    minutes: "minute",
    quarter: "quarter",
    quarters: "quarter",
    second: "second",
    seconds: "second",
    millisecond: "millisecond",
    milliseconds: "millisecond",
    weekday: "weekday",
    weekdays: "weekday",
    weeknumber: "weekNumber",
    weeksnumber: "weekNumber",
    weeknumbers: "weekNumber",
    weekyear: "weekYear",
    weekyears: "weekYear",
    ordinal: "ordinal"
  }[t.toLowerCase()];
  if (!e)
    throw new Yn(t);
  return e;
}
function Wn(t) {
  switch (t.toLowerCase()) {
    case "localweekday":
    case "localweekdays":
      return "localWeekday";
    case "localweeknumber":
    case "localweeknumbers":
      return "localWeekNumber";
    case "localweekyear":
    case "localweekyears":
      return "localWeekYear";
    default:
      return No(t);
  }
}
function Vn(t, e) {
  const n = ke(e.zone, Y.defaultZone), r = L.fromObject(e), s = Y.now();
  let a, i;
  if (M(t.year))
    a = s;
  else {
    for (const u of Ur)
      M(t[u]) && (t[u] = jr[u]);
    const o = yr(t) || pr(t);
    if (o)
      return N.invalid(o);
    const c = n.offset(s);
    [a, i] = ft(t, c, n);
  }
  return new N({ ts: a, zone: n, loc: r, o: i });
}
function $n(t, e, n) {
  const r = M(n.round) ? !0 : n.round, s = (i, o) => (i = en(i, r || n.calendary ? 0 : 2, !0), e.loc.clone(n).relFormatter(n).format(i, o)), a = (i) => n.calendary ? e.hasSame(t, i) ? 0 : e.startOf(i).diff(t.startOf(i), i).get(i) : e.diff(t, i).get(i);
  if (n.unit)
    return s(a(n.unit), n.unit);
  for (const i of n.units) {
    const o = a(i);
    if (Math.abs(o) >= 1)
      return s(o, i);
  }
  return s(t > e ? -0 : 0, n.units[n.units.length - 1]);
}
function zn(t) {
  let e = {}, n;
  return t.length > 0 && typeof t[t.length - 1] == "object" ? (e = t[t.length - 1], n = Array.from(t).slice(0, t.length - 1)) : n = Array.from(t), [e, n];
}
class N {
  /**
   * @access private
   */
  constructor(e) {
    const n = e.zone || Y.defaultZone;
    let r = e.invalid || (Number.isNaN(e.ts) ? new ue("invalid input") : null) || (n.isValid ? null : ct(n));
    this.ts = M(e.ts) ? Y.now() : e.ts;
    let s = null, a = null;
    if (!r)
      if (e.old && e.old.ts === this.ts && e.old.zone.equals(n))
        [s, a] = [e.old.c, e.old.o];
      else {
        const o = n.offset(this.ts);
        s = ut(this.ts, o), r = Number.isNaN(s.year) ? new ue("invalid input") : null, s = r ? null : s, a = r ? null : o;
      }
    this._zone = n, this.loc = e.loc || L.create(), this.invalid = r, this.weekData = null, this.localWeekData = null, this.c = s, this.o = a, this.isLuxonDateTime = !0;
  }
  // CONSTRUCT
  /**
   * Create a DateTime for the current instant, in the system's time zone.
   *
   * Use Settings to override these default values if needed.
   * @example DateTime.now().toISO() //~> now in the ISO format
   * @return {DateTime}
   */
  static now() {
    return new N({});
  }
  /**
   * Create a local DateTime
   * @param {number} [year] - The calendar year. If omitted (as in, call `local()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month, 1-indexed
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @example DateTime.local()                                  //~> now
   * @example DateTime.local({ zone: "America/New_York" })      //~> now, in US east coast time
   * @example DateTime.local(2017)                              //~> 2017-01-01T00:00:00
   * @example DateTime.local(2017, 3)                           //~> 2017-03-01T00:00:00
   * @example DateTime.local(2017, 3, 12, { locale: "fr" })     //~> 2017-03-12T00:00:00, with a French locale
   * @example DateTime.local(2017, 3, 12, 5)                    //~> 2017-03-12T05:00:00
   * @example DateTime.local(2017, 3, 12, 5, { zone: "utc" })   //~> 2017-03-12T05:00:00, in UTC
   * @example DateTime.local(2017, 3, 12, 5, 45)                //~> 2017-03-12T05:45:00
   * @example DateTime.local(2017, 3, 12, 5, 45, 10)            //~> 2017-03-12T05:45:10
   * @example DateTime.local(2017, 3, 12, 5, 45, 10, 765)       //~> 2017-03-12T05:45:10.765
   * @return {DateTime}
   */
  static local() {
    const [e, n] = zn(arguments), [r, s, a, i, o, c, u] = n;
    return Vn({ year: r, month: s, day: a, hour: i, minute: o, second: c, millisecond: u }, e);
  }
  /**
   * Create a DateTime in UTC
   * @param {number} [year] - The calendar year. If omitted (as in, call `utc()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @param {Object} options - configuration options for the DateTime
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} [options.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [options.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.utc()                                              //~> now
   * @example DateTime.utc(2017)                                          //~> 2017-01-01T00:00:00Z
   * @example DateTime.utc(2017, 3)                                       //~> 2017-03-01T00:00:00Z
   * @example DateTime.utc(2017, 3, 12)                                   //~> 2017-03-12T00:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5)                                //~> 2017-03-12T05:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45)                            //~> 2017-03-12T05:45:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, { locale: "fr" })          //~> 2017-03-12T05:45:00Z with a French locale
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10)                        //~> 2017-03-12T05:45:10Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10, 765, { locale: "fr" }) //~> 2017-03-12T05:45:10.765Z with a French locale
   * @return {DateTime}
   */
  static utc() {
    const [e, n] = zn(arguments), [r, s, a, i, o, c, u] = n;
    return e.zone = K.utcInstance, Vn({ year: r, month: s, day: a, hour: i, minute: o, second: c, millisecond: u }, e);
  }
  /**
   * Create a DateTime from a JavaScript Date object. Uses the default zone.
   * @param {Date} date - a JavaScript Date object
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @return {DateTime}
   */
  static fromJSDate(e, n = {}) {
    const r = Qa(e) ? e.valueOf() : NaN;
    if (Number.isNaN(r))
      return N.invalid("invalid input");
    const s = ke(n.zone, Y.defaultZone);
    return s.isValid ? new N({
      ts: r,
      zone: s,
      loc: L.fromObject(n)
    }) : N.invalid(ct(s));
  }
  /**
   * Create a DateTime from a number of milliseconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} milliseconds - a number of milliseconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromMillis(e, n = {}) {
    if (Te(e))
      return e < -En || e > En ? N.invalid("Timestamp out of range") : new N({
        ts: e,
        zone: ke(n.zone, Y.defaultZone),
        loc: L.fromObject(n)
      });
    throw new X(
      `fromMillis requires a numerical input, but received a ${typeof e} with value ${e}`
    );
  }
  /**
   * Create a DateTime from a number of seconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} seconds - a number of seconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromSeconds(e, n = {}) {
    if (Te(e))
      return new N({
        ts: e * 1e3,
        zone: ke(n.zone, Y.defaultZone),
        loc: L.fromObject(n)
      });
    throw new X("fromSeconds requires a numerical input");
  }
  /**
   * Create a DateTime from a JavaScript object with keys like 'year' and 'hour' with reasonable defaults.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.year - a year, such as 1987
   * @param {number} obj.month - a month, 1-12
   * @param {number} obj.day - a day of the month, 1-31, depending on the month
   * @param {number} obj.ordinal - day of the year, 1-365 or 366
   * @param {number} obj.weekYear - an ISO week year
   * @param {number} obj.weekNumber - an ISO week number, between 1 and 52 or 53, depending on the year
   * @param {number} obj.weekday - an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
   * @param {number} obj.localWeekYear - a week year, according to the locale
   * @param {number} obj.localWeekNumber - a week number, between 1 and 52 or 53, depending on the year, according to the locale
   * @param {number} obj.localWeekday - a weekday, 1-7, where 1 is the first and 7 is the last day of the week, according to the locale
   * @param {number} obj.hour - hour of the day, 0-23
   * @param {number} obj.minute - minute of the hour, 0-59
   * @param {number} obj.second - second of the minute, 0-59
   * @param {number} obj.millisecond - millisecond of the second, 0-999
   * @param {Object} opts - options for creating this DateTime
   * @param {string|Zone} [opts.zone='local'] - interpret the numbers in the context of a particular zone. Can take any value taken as the first argument to setZone()
   * @param {string} [opts.locale='system\'s locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromObject({ year: 1982, month: 5, day: 25}).toISODate() //=> '1982-05-25'
   * @example DateTime.fromObject({ year: 1982 }).toISODate() //=> '1982-01-01'
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }) //~> today at 10:26:06
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'utc' }),
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'local' })
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'America/New_York' })
   * @example DateTime.fromObject({ weekYear: 2016, weekNumber: 2, weekday: 3 }).toISODate() //=> '2016-01-13'
   * @example DateTime.fromObject({ localWeekYear: 2022, localWeekNumber: 1, localWeekday: 1 }, { locale: "en-US" }).toISODate() //=> '2021-12-26'
   * @return {DateTime}
   */
  static fromObject(e, n = {}) {
    e = e || {};
    const r = ke(n.zone, Y.defaultZone);
    if (!r.isValid)
      return N.invalid(ct(r));
    const s = L.fromObject(n), a = pt(e, Wn), { minDaysInFirstWeek: i, startOfWeek: o } = xn(a, s), c = Y.now(), u = M(n.specificOffset) ? r.offset(c) : n.specificOffset, d = !M(a.ordinal), m = !M(a.year), f = !M(a.month) || !M(a.day), h = m || f, x = a.weekYear || a.weekNumber;
    if ((h || d) && x)
      throw new Ee(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (f && d)
      throw new Ee("Can't mix ordinal dates with month/day");
    const S = x || a.weekday && !h;
    let w, v, C = ut(c, u);
    S ? (w = vo, v = ko, C = wt(C, i, o)) : d ? (w = bo, v = xo, C = Wt(C)) : (w = Ur, v = jr);
    let T = !1;
    for (const R of w) {
      const $ = a[R];
      M($) ? T ? a[R] = v[R] : a[R] = C[R] : T = !0;
    }
    const F = S ? Ga(a, i, o) : d ? Ja(a) : yr(a), O = F || pr(a);
    if (O)
      return N.invalid(O);
    const z = S ? pn(a, i, o) : d ? kn(a) : a, [W, Z] = ft(z, u, r), b = new N({
      ts: W,
      zone: r,
      o: Z,
      loc: s
    });
    return a.weekday && h && e.weekday !== b.weekday ? N.invalid(
      "mismatched weekday",
      `you can't specify both a weekday of ${a.weekday} and a date of ${b.toISO()}`
    ) : b;
  }
  /**
   * Create a DateTime from an ISO 8601 string
   * @param {string} text - the ISO string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the time to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} [opts.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [opts.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromISO('2016-05-25T09:08:34.123')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00', {setZone: true})
   * @example DateTime.fromISO('2016-05-25T09:08:34.123', {zone: 'utc'})
   * @example DateTime.fromISO('2016-W05-4')
   * @return {DateTime}
   */
  static fromISO(e, n = {}) {
    const [r, s] = Zi(e);
    return je(r, s, n, "ISO 8601", e);
  }
  /**
   * Create a DateTime from an RFC 2822 string
   * @param {string} text - the RFC 2822 string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since the offset is always specified in the string itself, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23:12 GMT')
   * @example DateTime.fromRFC2822('Fri, 25 Nov 2016 13:23:12 +0600')
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23 Z')
   * @return {DateTime}
   */
  static fromRFC2822(e, n = {}) {
    const [r, s] = Hi(e);
    return je(r, s, n, "RFC 2822", e);
  }
  /**
   * Create a DateTime from an HTTP header date
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @param {string} text - the HTTP header date
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since HTTP dates are always in UTC, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with the fixed-offset zone specified in the string. For HTTP dates, this is always UTC, so this option is equivalent to setting the `zone` option to 'utc', but this option is included for consistency with similar methods.
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromHTTP('Sun, 06 Nov 1994 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sunday, 06-Nov-94 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sun Nov  6 08:49:37 1994')
   * @return {DateTime}
   */
  static fromHTTP(e, n = {}) {
    const [r, s] = Bi(e);
    return je(r, s, n, "HTTP", n);
  }
  /**
   * Create a DateTime from an input string and format string.
   * Defaults to en-US if no locale has been specified, regardless of the system's locale. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/parsing?id=table-of-tokens).
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see the link below for the formats)
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromFormat(e, n, r = {}) {
    if (M(e) || M(n))
      throw new X("fromFormat requires an input string and a format");
    const { locale: s = null, numberingSystem: a = null } = r, i = L.fromOpts({
      locale: s,
      numberingSystem: a,
      defaultToEN: !0
    }), [o, c, u, d] = po(i, e, n);
    return d ? N.invalid(d) : je(o, c, r, `format ${n}`, e, u);
  }
  /**
   * @deprecated use fromFormat instead
   */
  static fromString(e, n, r = {}) {
    return N.fromFormat(e, n, r);
  }
  /**
   * Create a DateTime from a SQL date, time, or datetime
   * Defaults to en-US if no locale has been specified, regardless of the system's locale
   * @param {string} text - the string to parse
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @example DateTime.fromSQL('2017-05-15')
   * @example DateTime.fromSQL('2017-05-15 09:12:34')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342+06:00')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles', { setZone: true })
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342', { zone: 'America/Los_Angeles' })
   * @example DateTime.fromSQL('09:12:34.342')
   * @return {DateTime}
   */
  static fromSQL(e, n = {}) {
    const [r, s] = Ji(e);
    return je(r, s, n, "SQL", e);
  }
  /**
   * Create an invalid DateTime.
   * @param {string} reason - simple string of why this DateTime is invalid. Should not contain parameters or anything else data-dependent.
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {DateTime}
   */
  static invalid(e, n = null) {
    if (!e)
      throw new X("need to specify a reason the DateTime is invalid");
    const r = e instanceof ue ? e : new ue(e, n);
    if (Y.throwOnInvalid)
      throw new Ta(r);
    return new N({ invalid: r });
  }
  /**
   * Check if an object is an instance of DateTime. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDateTime(e) {
    return e && e.isLuxonDateTime || !1;
  }
  /**
   * Produce the format string for a set of options
   * @param formatOpts
   * @param localeOpts
   * @returns {string}
   */
  static parseFormatForOpts(e, n = {}) {
    const r = Br(e, L.fromObject(n));
    return r ? r.map((s) => s ? s.val : null).join("") : null;
  }
  /**
   * Produce the the fully expanded format token for the locale
   * Does NOT quote characters, so quoted tokens will not round trip correctly
   * @param fmt
   * @param localeOpts
   * @returns {string}
   */
  static expandFormat(e, n = {}) {
    return Zr(Q.parseFormat(e), L.fromObject(n)).map((s) => s.val).join("");
  }
  // INFO
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example DateTime.local(2017, 7, 4).get('month'); //=> 7
   * @example DateTime.local(2017, 7, 4).get('day'); //=> 4
   * @return {number}
   */
  get(e) {
    return this[e];
  }
  /**
   * Returns whether the DateTime is valid. Invalid DateTimes occur when:
   * * The DateTime was created from invalid calendar information, such as the 13th month or February 30
   * * The DateTime was created by an operation on another invalid date
   * @type {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this DateTime is invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this DateTime became invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Get the locale of a DateTime, such 'en-GB'. The locale is used when formatting the DateTime
   *
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a DateTime, such 'beng'. The numbering system is used when formatting the DateTime
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Get the output calendar of a DateTime, such 'islamic'. The output calendar is used when formatting the DateTime
   *
   * @type {string}
   */
  get outputCalendar() {
    return this.isValid ? this.loc.outputCalendar : null;
  }
  /**
   * Get the time zone associated with this DateTime.
   * @type {Zone}
   */
  get zone() {
    return this._zone;
  }
  /**
   * Get the name of the time zone.
   * @type {string}
   */
  get zoneName() {
    return this.isValid ? this.zone.name : null;
  }
  /**
   * Get the year
   * @example DateTime.local(2017, 5, 25).year //=> 2017
   * @type {number}
   */
  get year() {
    return this.isValid ? this.c.year : NaN;
  }
  /**
   * Get the quarter
   * @example DateTime.local(2017, 5, 25).quarter //=> 2
   * @type {number}
   */
  get quarter() {
    return this.isValid ? Math.ceil(this.c.month / 3) : NaN;
  }
  /**
   * Get the month (1-12).
   * @example DateTime.local(2017, 5, 25).month //=> 5
   * @type {number}
   */
  get month() {
    return this.isValid ? this.c.month : NaN;
  }
  /**
   * Get the day of the month (1-30ish).
   * @example DateTime.local(2017, 5, 25).day //=> 25
   * @type {number}
   */
  get day() {
    return this.isValid ? this.c.day : NaN;
  }
  /**
   * Get the hour of the day (0-23).
   * @example DateTime.local(2017, 5, 25, 9).hour //=> 9
   * @type {number}
   */
  get hour() {
    return this.isValid ? this.c.hour : NaN;
  }
  /**
   * Get the minute of the hour (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30).minute //=> 30
   * @type {number}
   */
  get minute() {
    return this.isValid ? this.c.minute : NaN;
  }
  /**
   * Get the second of the minute (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52).second //=> 52
   * @type {number}
   */
  get second() {
    return this.isValid ? this.c.second : NaN;
  }
  /**
   * Get the millisecond of the second (0-999).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52, 654).millisecond //=> 654
   * @type {number}
   */
  get millisecond() {
    return this.isValid ? this.c.millisecond : NaN;
  }
  /**
   * Get the week year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 12, 31).weekYear //=> 2015
   * @type {number}
   */
  get weekYear() {
    return this.isValid ? zt(this).weekYear : NaN;
  }
  /**
   * Get the week number of the week year (1-52ish).
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2017, 5, 25).weekNumber //=> 21
   * @type {number}
   */
  get weekNumber() {
    return this.isValid ? zt(this).weekNumber : NaN;
  }
  /**
   * Get the day of the week.
   * 1 is Monday and 7 is Sunday
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 11, 31).weekday //=> 4
   * @type {number}
   */
  get weekday() {
    return this.isValid ? zt(this).weekday : NaN;
  }
  /**
   * Returns true if this date is on a weekend according to the locale, false otherwise
   * @returns {boolean}
   */
  get isWeekend() {
    return this.isValid && this.loc.getWeekendDays().includes(this.weekday);
  }
  /**
   * Get the day of the week according to the locale.
   * 1 is the first day of the week and 7 is the last day of the week.
   * If the locale assigns Sunday as the first day of the week, then a date which is a Sunday will return 1,
   * @returns {number}
   */
  get localWeekday() {
    return this.isValid ? Rt(this).weekday : NaN;
  }
  /**
   * Get the week number of the week year according to the locale. Different locales assign week numbers differently,
   * because the week can start on different days of the week (see localWeekday) and because a different number of days
   * is required for a week to count as the first week of a year.
   * @returns {number}
   */
  get localWeekNumber() {
    return this.isValid ? Rt(this).weekNumber : NaN;
  }
  /**
   * Get the week year according to the locale. Different locales assign week numbers (and therefor week years)
   * differently, see localWeekNumber.
   * @returns {number}
   */
  get localWeekYear() {
    return this.isValid ? Rt(this).weekYear : NaN;
  }
  /**
   * Get the ordinal (meaning the day of the year)
   * @example DateTime.local(2017, 5, 25).ordinal //=> 145
   * @type {number|DateTime}
   */
  get ordinal() {
    return this.isValid ? Wt(this.c).ordinal : NaN;
  }
  /**
   * Get the human readable short month name, such as 'Oct'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthShort //=> Oct
   * @type {string}
   */
  get monthShort() {
    return this.isValid ? ot.months("short", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable long month name, such as 'October'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthLong //=> October
   * @type {string}
   */
  get monthLong() {
    return this.isValid ? ot.months("long", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable short weekday, such as 'Mon'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayShort //=> Mon
   * @type {string}
   */
  get weekdayShort() {
    return this.isValid ? ot.weekdays("short", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the human readable long weekday, such as 'Monday'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayLong //=> Monday
   * @type {string}
   */
  get weekdayLong() {
    return this.isValid ? ot.weekdays("long", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the UTC offset of this DateTime in minutes
   * @example DateTime.now().offset //=> -240
   * @example DateTime.utc().offset //=> 0
   * @type {number}
   */
  get offset() {
    return this.isValid ? +this.o : NaN;
  }
  /**
   * Get the short human name for the zone's current offset, for example "EST" or "EDT".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameShort() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "short",
      locale: this.locale
    }) : null;
  }
  /**
   * Get the long human name for the zone's current offset, for example "Eastern Standard Time" or "Eastern Daylight Time".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameLong() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "long",
      locale: this.locale
    }) : null;
  }
  /**
   * Get whether this zone's offset ever changes, as in a DST.
   * @type {boolean}
   */
  get isOffsetFixed() {
    return this.isValid ? this.zone.isUniversal : null;
  }
  /**
   * Get whether the DateTime is in a DST.
   * @type {boolean}
   */
  get isInDST() {
    return this.isOffsetFixed ? !1 : this.offset > this.set({ month: 1, day: 1 }).offset || this.offset > this.set({ month: 5 }).offset;
  }
  /**
   * Get those DateTimes which have the same local time as this DateTime, but a different offset from UTC
   * in this DateTime's zone. During DST changes local time can be ambiguous, for example
   * `2023-10-29T02:30:00` in `Europe/Berlin` can have offset `+01:00` or `+02:00`.
   * This method will return both possible DateTimes if this DateTime's local time is ambiguous.
   * @returns {DateTime[]}
   */
  getPossibleOffsets() {
    if (!this.isValid || this.isOffsetFixed)
      return [this];
    const e = 864e5, n = 6e4, r = vt(this.c), s = this.zone.offset(r - e), a = this.zone.offset(r + e), i = this.zone.offset(r - s * n), o = this.zone.offset(r - a * n);
    if (i === o)
      return [this];
    const c = r - i * n, u = r - o * n, d = ut(c, i), m = ut(u, o);
    return d.hour === m.hour && d.minute === m.minute && d.second === m.second && d.millisecond === m.millisecond ? [be(this, { ts: c }), be(this, { ts: u })] : [this];
  }
  /**
   * Returns true if this DateTime is in a leap year, false otherwise
   * @example DateTime.local(2016).isInLeapYear //=> true
   * @example DateTime.local(2013).isInLeapYear //=> false
   * @type {boolean}
   */
  get isInLeapYear() {
    return Xe(this.year);
  }
  /**
   * Returns the number of days in this DateTime's month
   * @example DateTime.local(2016, 2).daysInMonth //=> 29
   * @example DateTime.local(2016, 3).daysInMonth //=> 31
   * @type {number}
   */
  get daysInMonth() {
    return yt(this.year, this.month);
  }
  /**
   * Returns the number of days in this DateTime's year
   * @example DateTime.local(2016).daysInYear //=> 366
   * @example DateTime.local(2013).daysInYear //=> 365
   * @type {number}
   */
  get daysInYear() {
    return this.isValid ? We(this.year) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2004).weeksInWeekYear //=> 53
   * @example DateTime.local(2013).weeksInWeekYear //=> 52
   * @type {number}
   */
  get weeksInWeekYear() {
    return this.isValid ? Qe(this.weekYear) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's local week year
   * @example DateTime.local(2020, 6, {locale: 'en-US'}).weeksInLocalWeekYear //=> 52
   * @example DateTime.local(2020, 6, {locale: 'de-DE'}).weeksInLocalWeekYear //=> 53
   * @type {number}
   */
  get weeksInLocalWeekYear() {
    return this.isValid ? Qe(
      this.localWeekYear,
      this.loc.getMinDaysInFirstWeek(),
      this.loc.getStartOfWeek()
    ) : NaN;
  }
  /**
   * Returns the resolved Intl options for this DateTime.
   * This is useful in understanding the behavior of formatting methods
   * @param {Object} opts - the same options as toLocaleString
   * @return {Object}
   */
  resolvedLocaleOptions(e = {}) {
    const { locale: n, numberingSystem: r, calendar: s } = Q.create(
      this.loc.clone(e),
      e
    ).resolvedOptions(this);
    return { locale: n, numberingSystem: r, outputCalendar: s };
  }
  // TRANSFORM
  /**
   * "Set" the DateTime's zone to UTC. Returns a newly-constructed DateTime.
   *
   * Equivalent to {@link DateTime#setZone}('utc')
   * @param {number} [offset=0] - optionally, an offset from UTC in minutes
   * @param {Object} [opts={}] - options to pass to `setZone()`
   * @return {DateTime}
   */
  toUTC(e = 0, n = {}) {
    return this.setZone(K.instance(e), n);
  }
  /**
   * "Set" the DateTime's zone to the host's local zone. Returns a newly-constructed DateTime.
   *
   * Equivalent to `setZone('local')`
   * @return {DateTime}
   */
  toLocal() {
    return this.setZone(Y.defaultZone);
  }
  /**
   * "Set" the DateTime's zone to specified zone. Returns a newly-constructed DateTime.
   *
   * By default, the setter keeps the underlying time the same (as in, the same timestamp), but the new instance will report different local times and consider DSTs when making computations, as with {@link DateTime#plus}. You may wish to use {@link DateTime#toLocal} and {@link DateTime#toUTC} which provide simple convenience wrappers for commonly used zones.
   * @param {string|Zone} [zone='local'] - a zone identifier. As a string, that can be any IANA zone supported by the host environment, or a fixed-offset name of the form 'UTC+3', or the strings 'local' or 'utc'. You may also supply an instance of a {@link DateTime#Zone} class.
   * @param {Object} opts - options
   * @param {boolean} [opts.keepLocalTime=false] - If true, adjust the underlying time so that the local time stays the same, but in the target zone. You should rarely need this.
   * @return {DateTime}
   */
  setZone(e, { keepLocalTime: n = !1, keepCalendarTime: r = !1 } = {}) {
    if (e = ke(e, Y.defaultZone), e.equals(this.zone))
      return this;
    if (e.isValid) {
      let s = this.ts;
      if (n || r) {
        const a = e.offset(this.ts), i = this.toObject();
        [s] = ft(i, a, e);
      }
      return be(this, { ts: s, zone: e });
    } else
      return N.invalid(ct(e));
  }
  /**
   * "Set" the locale, numberingSystem, or outputCalendar. Returns a newly-constructed DateTime.
   * @param {Object} properties - the properties to set
   * @example DateTime.local(2017, 5, 25).reconfigure({ locale: 'en-GB' })
   * @return {DateTime}
   */
  reconfigure({ locale: e, numberingSystem: n, outputCalendar: r } = {}) {
    const s = this.loc.clone({ locale: e, numberingSystem: n, outputCalendar: r });
    return be(this, { loc: s });
  }
  /**
   * "Set" the locale. Returns a newly-constructed DateTime.
   * Just a convenient alias for reconfigure({ locale })
   * @example DateTime.local(2017, 5, 25).setLocale('en-GB')
   * @return {DateTime}
   */
  setLocale(e) {
    return this.reconfigure({ locale: e });
  }
  /**
   * "Set" the values of specified units. Returns a newly-constructed DateTime.
   * You can only set units with this method; for "setting" metadata, see {@link DateTime#reconfigure} and {@link DateTime#setZone}.
   *
   * This method also supports setting locale-based week units, i.e. `localWeekday`, `localWeekNumber` and `localWeekYear`.
   * They cannot be mixed with ISO-week units like `weekday`.
   * @param {Object} values - a mapping of units to numbers
   * @example dt.set({ year: 2017 })
   * @example dt.set({ hour: 8, minute: 30 })
   * @example dt.set({ weekday: 5 })
   * @example dt.set({ year: 2005, ordinal: 234 })
   * @return {DateTime}
   */
  set(e) {
    if (!this.isValid)
      return this;
    const n = pt(e, Wn), { minDaysInFirstWeek: r, startOfWeek: s } = xn(n, this.loc), a = !M(n.weekYear) || !M(n.weekNumber) || !M(n.weekday), i = !M(n.ordinal), o = !M(n.year), c = !M(n.month) || !M(n.day), u = o || c, d = n.weekYear || n.weekNumber;
    if ((u || i) && d)
      throw new Ee(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (c && i)
      throw new Ee("Can't mix ordinal dates with month/day");
    let m;
    a ? m = pn(
      { ...wt(this.c, r, s), ...n },
      r,
      s
    ) : M(n.ordinal) ? (m = { ...this.toObject(), ...n }, M(n.day) && (m.day = Math.min(yt(m.year, m.month), m.day))) : m = kn({ ...Wt(this.c), ...n });
    const [f, h] = ft(m, this.o, this.zone);
    return be(this, { ts: f, o: h });
  }
  /**
   * Add a period of time to this DateTime and return the resulting DateTime
   *
   * Adding hours, minutes, seconds, or milliseconds increases the timestamp by the right number of milliseconds. Adding days, months, or years shifts the calendar, accounting for DSTs and leap years along the way. Thus, `dt.plus({ hours: 24 })` may result in a different time than `dt.plus({ days: 1 })` if there's a DST shift in between.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @example DateTime.now().plus(123) //~> in 123 milliseconds
   * @example DateTime.now().plus({ minutes: 15 }) //~> in 15 minutes
   * @example DateTime.now().plus({ days: 1 }) //~> this time tomorrow
   * @example DateTime.now().plus({ days: -1 }) //~> this time yesterday
   * @example DateTime.now().plus({ hours: 3, minutes: 13 }) //~> in 3 hr, 13 min
   * @example DateTime.now().plus(Duration.fromObject({ hours: 3, minutes: 13 })) //~> in 3 hr, 13 min
   * @return {DateTime}
   */
  plus(e) {
    if (!this.isValid)
      return this;
    const n = D.fromDurationLike(e);
    return be(this, Ln(this, n));
  }
  /**
   * Subtract a period of time to this DateTime and return the resulting DateTime
   * See {@link DateTime#plus}
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   @return {DateTime}
   */
  minus(e) {
    if (!this.isValid)
      return this;
    const n = D.fromDurationLike(e).negate();
    return be(this, Ln(this, n));
  }
  /**
   * "Set" this DateTime to the beginning of a unit of time.
   * @param {string} unit - The unit to go to the beginning of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
   * @example DateTime.local(2014, 3, 3).startOf('year').toISODate(); //=> '2014-01-01'
   * @example DateTime.local(2014, 3, 3).startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('day').toISOTime(); //=> '00:00.000-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
   * @return {DateTime}
   */
  startOf(e, { useLocaleWeeks: n = !1 } = {}) {
    if (!this.isValid)
      return this;
    const r = {}, s = D.normalizeUnit(e);
    switch (s) {
      case "years":
        r.month = 1;
      case "quarters":
      case "months":
        r.day = 1;
      case "weeks":
      case "days":
        r.hour = 0;
      case "hours":
        r.minute = 0;
      case "minutes":
        r.second = 0;
      case "seconds":
        r.millisecond = 0;
        break;
    }
    if (s === "weeks")
      if (n) {
        const a = this.loc.getStartOfWeek(), { weekday: i } = this;
        i < a && (r.weekNumber = this.weekNumber - 1), r.weekday = a;
      } else
        r.weekday = 1;
    if (s === "quarters") {
      const a = Math.ceil(this.month / 3);
      r.month = (a - 1) * 3 + 1;
    }
    return this.set(r);
  }
  /**
   * "Set" this DateTime to the end (meaning the last millisecond) of a unit of time
   * @param {string} unit - The unit to go to the end of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
   * @return {DateTime}
   */
  endOf(e, n) {
    return this.isValid ? this.plus({ [e]: 1 }).startOf(e, n).minus(1) : this;
  }
  // OUTPUT
  /**
   * Returns a string representation of this DateTime formatted according to the specified format string.
   * **You may not want this.** See {@link DateTime#toLocaleString} for a more flexible formatting tool. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/formatting?id=table-of-tokens).
   * Defaults to en-US if no locale has been specified, regardless of the system's locale.
   * @param {string} fmt - the format string
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toFormat('yyyy LLL dd') //=> '2017 Apr 22'
   * @example DateTime.now().setLocale('fr').toFormat('yyyy LLL dd') //=> '2017 avr. 22'
   * @example DateTime.now().toFormat('yyyy LLL dd', { locale: "fr" }) //=> '2017 avr. 22'
   * @example DateTime.now().toFormat("HH 'hours and' mm 'minutes'") //=> '20 hours and 55 minutes'
   * @return {string}
   */
  toFormat(e, n = {}) {
    return this.isValid ? Q.create(this.loc.redefaultToEN(n)).formatDateTimeFromString(this, e) : $t;
  }
  /**
   * Returns a localized string representing this date. Accepts the same options as the Intl.DateTimeFormat constructor and any presets defined by Luxon, such as `DateTime.DATE_FULL` or `DateTime.TIME_SIMPLE`.
   * The exact behavior of this method is browser-specific, but in general it will return an appropriate representation
   * of the DateTime in the assigned locale.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param formatOpts {Object} - Intl.DateTimeFormat constructor options and configuration options
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toLocaleString(); //=> 4/20/2017
   * @example DateTime.now().setLocale('en-gb').toLocaleString(); //=> '20/04/2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL); //=> 'April 20, 2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL, { locale: 'fr' }); //=> '28 août 2022'
   * @example DateTime.now().toLocaleString(DateTime.TIME_SIMPLE); //=> '11:32 AM'
   * @example DateTime.now().toLocaleString(DateTime.DATETIME_SHORT); //=> '4/20/2017, 11:32 AM'
   * @example DateTime.now().toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }); //=> 'Thursday, April 20'
   * @example DateTime.now().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> 'Thu, Apr 20, 11:27 AM'
   * @example DateTime.now().toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); //=> '11:32'
   * @return {string}
   */
  toLocaleString(e = gt, n = {}) {
    return this.isValid ? Q.create(this.loc.clone(n), e).formatDateTime(this) : $t;
  }
  /**
   * Returns an array of format "parts", meaning individual tokens along with metadata. This is allows callers to post-process individual sections of the formatted output.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
   * @param opts {Object} - Intl.DateTimeFormat constructor options, same as `toLocaleString`.
   * @example DateTime.now().toLocaleParts(); //=> [
   *                                   //=>   { type: 'day', value: '25' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'month', value: '05' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'year', value: '1982' }
   *                                   //=> ]
   */
  toLocaleParts(e = {}) {
    return this.isValid ? Q.create(this.loc.clone(e), e).formatDateTimeParts(this) : [];
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=false] - add the time zone format extension
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1983, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
   * @example DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
   * @example DateTime.now().toISO({ includeOffset: false }) //=> '2017-04-22T20:47:05.335'
   * @example DateTime.now().toISO({ format: 'basic' }) //=> '20170422T204705.335-0400'
   * @return {string}
   */
  toISO({
    format: e = "extended",
    suppressSeconds: n = !1,
    suppressMilliseconds: r = !1,
    includeOffset: s = !0,
    extendedZone: a = !1
  } = {}) {
    if (!this.isValid)
      return null;
    const i = e === "extended";
    let o = At(this, i);
    return o += "T", o += Fn(this, i, n, r, s, a), o;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's date component
   * @param {Object} opts - options
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1982, 5, 25).toISODate() //=> '1982-05-25'
   * @example DateTime.utc(1982, 5, 25).toISODate({ format: 'basic' }) //=> '19820525'
   * @return {string}
   */
  toISODate({ format: e = "extended" } = {}) {
    return this.isValid ? At(this, e === "extended") : null;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's week date
   * @example DateTime.utc(1982, 5, 25).toISOWeekDate() //=> '1982-W21-2'
   * @return {string}
   */
  toISOWeekDate() {
    return dt(this, "kkkk-'W'WW-c");
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's time component
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=true] - add the time zone format extension
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime() //=> '07:34:19.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34, seconds: 0, milliseconds: 0 }).toISOTime({ suppressSeconds: true }) //=> '07:34Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ format: 'basic' }) //=> '073419.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ includePrefix: true }) //=> 'T07:34:19.361Z'
   * @return {string}
   */
  toISOTime({
    suppressMilliseconds: e = !1,
    suppressSeconds: n = !1,
    includeOffset: r = !0,
    includePrefix: s = !1,
    extendedZone: a = !1,
    format: i = "extended"
  } = {}) {
    return this.isValid ? (s ? "T" : "") + Fn(
      this,
      i === "extended",
      n,
      e,
      r,
      a
    ) : null;
  }
  /**
   * Returns an RFC 2822-compatible string representation of this DateTime
   * @example DateTime.utc(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 +0000'
   * @example DateTime.local(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 -0400'
   * @return {string}
   */
  toRFC2822() {
    return dt(this, "EEE, dd LLL yyyy HH:mm:ss ZZZ", !1);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in HTTP headers. The output is always expressed in GMT.
   * Specifically, the string conforms to RFC 1123.
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @example DateTime.utc(2014, 7, 13).toHTTP() //=> 'Sun, 13 Jul 2014 00:00:00 GMT'
   * @example DateTime.utc(2014, 7, 13, 19).toHTTP() //=> 'Sun, 13 Jul 2014 19:00:00 GMT'
   * @return {string}
   */
  toHTTP() {
    return dt(this.toUTC(), "EEE, dd LLL yyyy HH:mm:ss 'GMT'");
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Date
   * @example DateTime.utc(2014, 7, 13).toSQLDate() //=> '2014-07-13'
   * @return {string}
   */
  toSQLDate() {
    return this.isValid ? At(this, !0) : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Time
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc().toSQL() //=> '05:15:16.345'
   * @example DateTime.now().toSQL() //=> '05:15:16.345 -04:00'
   * @example DateTime.now().toSQL({ includeOffset: false }) //=> '05:15:16.345'
   * @example DateTime.now().toSQL({ includeZone: false }) //=> '05:15:16.345 America/New_York'
   * @return {string}
   */
  toSQLTime({ includeOffset: e = !0, includeZone: n = !1, includeOffsetSpace: r = !0 } = {}) {
    let s = "HH:mm:ss.SSS";
    return (n || e) && (r && (s += " "), n ? s += "z" : e && (s += "ZZ")), dt(this, s, !0);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 Z'
   * @example DateTime.local(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 -04:00'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeOffset: false }) //=> '2014-07-13 00:00:00.000'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeZone: true }) //=> '2014-07-13 00:00:00.000 America/New_York'
   * @return {string}
   */
  toSQL(e = {}) {
    return this.isValid ? `${this.toSQLDate()} ${this.toSQLTime(e)}` : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for debugging
   * @return {string}
   */
  toString() {
    return this.isValid ? this.toISO() : $t;
  }
  /**
   * Returns a string representation of this DateTime appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `DateTime { ts: ${this.toISO()}, zone: ${this.zone.name}, locale: ${this.locale} }` : `DateTime { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns the epoch milliseconds of this DateTime. Alias of {@link DateTime#toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Returns the epoch milliseconds of this DateTime.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? this.ts : NaN;
  }
  /**
   * Returns the epoch seconds of this DateTime.
   * @return {number}
   */
  toSeconds() {
    return this.isValid ? this.ts / 1e3 : NaN;
  }
  /**
   * Returns the epoch seconds (as a whole number) of this DateTime.
   * @return {number}
   */
  toUnixInteger() {
    return this.isValid ? Math.floor(this.ts / 1e3) : NaN;
  }
  /**
   * Returns an ISO 8601 representation of this DateTime appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns a BSON serializable equivalent to this DateTime.
   * @return {Date}
   */
  toBSON() {
    return this.toJSDate();
  }
  /**
   * Returns a JavaScript object with this DateTime's year, month, day, and so on.
   * @param opts - options for generating the object
   * @param {boolean} [opts.includeConfig=false] - include configuration attributes in the output
   * @example DateTime.now().toObject() //=> { year: 2017, month: 4, day: 22, hour: 20, minute: 49, second: 42, millisecond: 268 }
   * @return {Object}
   */
  toObject(e = {}) {
    if (!this.isValid)
      return {};
    const n = { ...this.c };
    return e.includeConfig && (n.outputCalendar = this.outputCalendar, n.numberingSystem = this.loc.numberingSystem, n.locale = this.loc.locale), n;
  }
  /**
   * Returns a JavaScript Date equivalent to this DateTime.
   * @return {Date}
   */
  toJSDate() {
    return new Date(this.isValid ? this.ts : NaN);
  }
  // COMPARE
  /**
   * Return the difference between two DateTimes as a Duration.
   * @param {DateTime} otherDateTime - the DateTime to compare this one to
   * @param {string|string[]} [unit=['milliseconds']] - the unit or array of units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example
   * var i1 = DateTime.fromISO('1982-05-25T09:45'),
   *     i2 = DateTime.fromISO('1983-10-14T10:30');
   * i2.diff(i1).toObject() //=> { milliseconds: 43807500000 }
   * i2.diff(i1, 'hours').toObject() //=> { hours: 12168.75 }
   * i2.diff(i1, ['months', 'days']).toObject() //=> { months: 16, days: 19.03125 }
   * i2.diff(i1, ['months', 'days', 'hours']).toObject() //=> { months: 16, days: 19, hours: 0.75 }
   * @return {Duration}
   */
  diff(e, n = "milliseconds", r = {}) {
    if (!this.isValid || !e.isValid)
      return D.invalid("created by diffing an invalid DateTime");
    const s = { locale: this.locale, numberingSystem: this.numberingSystem, ...r }, a = Ka(n).map(D.normalizeUnit), i = e.valueOf() > this.valueOf(), o = i ? this : e, c = i ? e : this, u = no(o, c, a, s);
    return i ? u.negate() : u;
  }
  /**
   * Return the difference between this DateTime and right now.
   * See {@link DateTime#diff}
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units units (such as 'hours' or 'days') to include in the duration
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  diffNow(e = "milliseconds", n = {}) {
    return this.diff(N.now(), e, n);
  }
  /**
   * Return an Interval spanning between this DateTime and another DateTime
   * @param {DateTime} otherDateTime - the other end point of the Interval
   * @return {Interval}
   */
  until(e) {
    return this.isValid ? B.fromDateTimes(this, e) : this;
  }
  /**
   * Return whether this DateTime is in the same unit of time as another DateTime.
   * Higher-order units must also be identical for this function to return `true`.
   * Note that time zones are **ignored** in this comparison, which compares the **local** calendar time. Use {@link DateTime#setZone} to convert one of the dates if needed.
   * @param {DateTime} otherDateTime - the other DateTime
   * @param {string} unit - the unit of time to check sameness on
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; only the locale of this DateTime is used
   * @example DateTime.now().hasSame(otherDT, 'day'); //~> true if otherDT is in the same current calendar day
   * @return {boolean}
   */
  hasSame(e, n, r) {
    if (!this.isValid)
      return !1;
    const s = e.valueOf(), a = this.setZone(e.zone, { keepLocalTime: !0 });
    return a.startOf(n, r) <= s && s <= a.endOf(n, r);
  }
  /**
   * Equality check
   * Two DateTimes are equal if and only if they represent the same millisecond, have the same zone and location, and are both valid.
   * To compare just the millisecond values, use `+dt1 === +dt2`.
   * @param {DateTime} other - the other DateTime
   * @return {boolean}
   */
  equals(e) {
    return this.isValid && e.isValid && this.valueOf() === e.valueOf() && this.zone.equals(e.zone) && this.loc.equals(e.loc);
  }
  /**
   * Returns a string representation of a this time relative to now, such as "in two days". Can only internationalize if your
   * platform supports Intl.RelativeTimeFormat. Rounds down by default.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} [options.style="long"] - the style of units, must be "long", "short", or "narrow"
   * @param {string|string[]} options.unit - use a specific unit or array of units; if omitted, or an array, the method will pick the best unit. Use an array or one of "years", "quarters", "months", "weeks", "days", "hours", "minutes", or "seconds"
   * @param {boolean} [options.round=true] - whether to round the numbers in the output.
   * @param {number} [options.padding=0] - padding in milliseconds. This allows you to round up the result if it fits inside the threshold. Don't use in combination with {round: false} because the decimal output will include the padding.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelative() //=> "in 1 day"
   * @example DateTime.now().setLocale("es").toRelative({ days: 1 }) //=> "dentro de 1 día"
   * @example DateTime.now().plus({ days: 1 }).toRelative({ locale: "fr" }) //=> "dans 23 heures"
   * @example DateTime.now().minus({ days: 2 }).toRelative() //=> "2 days ago"
   * @example DateTime.now().minus({ days: 2 }).toRelative({ unit: "hours" }) //=> "48 hours ago"
   * @example DateTime.now().minus({ hours: 36 }).toRelative({ round: false }) //=> "1.5 days ago"
   */
  toRelative(e = {}) {
    if (!this.isValid)
      return null;
    const n = e.base || N.fromObject({}, { zone: this.zone }), r = e.padding ? this < n ? -e.padding : e.padding : 0;
    let s = ["years", "months", "days", "hours", "minutes", "seconds"], a = e.unit;
    return Array.isArray(e.unit) && (s = e.unit, a = void 0), $n(n, this.plus(r), {
      ...e,
      numeric: "always",
      units: s,
      unit: a
    });
  }
  /**
   * Returns a string representation of this date relative to today, such as "yesterday" or "next month".
   * Only internationalizes on platforms that supports Intl.RelativeTimeFormat.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.unit - use a specific unit; if omitted, the method will pick the unit. Use one of "years", "quarters", "months", "weeks", or "days"
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar() //=> "tomorrow"
   * @example DateTime.now().setLocale("es").plus({ days: 1 }).toRelative() //=> ""mañana"
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar({ locale: "fr" }) //=> "demain"
   * @example DateTime.now().minus({ days: 2 }).toRelativeCalendar() //=> "2 days ago"
   */
  toRelativeCalendar(e = {}) {
    return this.isValid ? $n(e.base || N.fromObject({}, { zone: this.zone }), this, {
      ...e,
      numeric: "auto",
      units: ["years", "months", "days"],
      calendary: !0
    }) : null;
  }
  /**
   * Return the min of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the minimum
   * @return {DateTime} the min DateTime, or undefined if called with no argument
   */
  static min(...e) {
    if (!e.every(N.isDateTime))
      throw new X("min requires all arguments be DateTimes");
    return vn(e, (n) => n.valueOf(), Math.min);
  }
  /**
   * Return the max of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the maximum
   * @return {DateTime} the max DateTime, or undefined if called with no argument
   */
  static max(...e) {
    if (!e.every(N.isDateTime))
      throw new X("max requires all arguments be DateTimes");
    return vn(e, (n) => n.valueOf(), Math.max);
  }
  // MISC
  /**
   * Explain how a string would be parsed by fromFormat()
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see description)
   * @param {Object} options - options taken by fromFormat()
   * @return {Object}
   */
  static fromFormatExplain(e, n, r = {}) {
    const { locale: s = null, numberingSystem: a = null } = r, i = L.fromOpts({
      locale: s,
      numberingSystem: a,
      defaultToEN: !0
    });
    return Hr(i, e, n);
  }
  /**
   * @deprecated use fromFormatExplain instead
   */
  static fromStringExplain(e, n, r = {}) {
    return N.fromFormatExplain(e, n, r);
  }
  // FORMAT PRESETS
  /**
   * {@link DateTime#toLocaleString} format like 10/14/1983
   * @type {Object}
   */
  static get DATE_SHORT() {
    return gt;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED() {
    return Gn;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED_WITH_WEEKDAY() {
    return Ma;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983'
   * @type {Object}
   */
  static get DATE_FULL() {
    return Jn;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Tuesday, October 14, 1983'
   * @type {Object}
   */
  static get DATE_HUGE() {
    return _n;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_SIMPLE() {
    return Qn;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SECONDS() {
    return Kn;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SHORT_OFFSET() {
    return Xn;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_LONG_OFFSET() {
    return er;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_SIMPLE() {
    return tr;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SECONDS() {
    return nr;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 EDT', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SHORT_OFFSET() {
    return rr;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 Eastern Daylight Time', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_LONG_OFFSET() {
    return sr;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT() {
    return ar;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT_WITH_SECONDS() {
    return ir;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED() {
    return or;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_SECONDS() {
    return lr;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, 14 Oct 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_WEEKDAY() {
    return Oa;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL() {
    return cr;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30:33 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL_WITH_SECONDS() {
    return ur;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE() {
    return dr;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30:33 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE_WITH_SECONDS() {
    return mr;
  }
}
function Ue(t) {
  if (N.isDateTime(t))
    return t;
  if (t && t.valueOf && Te(t.valueOf()))
    return N.fromJSDate(t);
  if (t && typeof t == "object")
    return N.fromObject(t);
  throw new X(
    `Unknown datetime argument: ${t}, of type ${typeof t}`
  );
}
function mt(t, e) {
  return t == null || e == null ? !1 : N.fromJSDate(t).hasSame(N.fromJSDate(e), "day");
}
function To(t, e) {
  return N.fromJSDate(t).hasSame(N.fromJSDate(e), "month");
}
function So(t) {
  const { dayInMonth: e, selectedDay: n, minDate: r, maxDate: s, highlightedDays: a = [], hideWeekends: i = !1, days: o = [], onSelect: c, embedded: u = !1, small: d = !1, className: m } = t, [f, h] = q(e ?? n ?? /* @__PURE__ */ new Date()), x = r != null ? N.fromJSDate(r).startOf("day").toMillis() : Number.MIN_SAFE_INTEGER, S = s != null ? N.fromJSDate(s).endOf("day").toMillis() : Number.MAX_SAFE_INTEGER, w = N.fromJSDate(f).startOf("month").toMillis() > x, v = N.fromJSDate(f).endOf("month").toMillis() < S;
  $e(() => {
    h(e ?? n ?? /* @__PURE__ */ new Date());
  }, [e, n]);
  const C = () => {
    w && h(new Date(f.getFullYear(), f.getMonth() - 1));
  }, T = () => {
    v && h(new Date(f.getFullYear(), f.getMonth() + 1));
  }, F = () => {
    if (!w)
      return;
    let b = new Date(f.getFullYear() - 1, f.getMonth());
    b.getTime() < x && (b = new Date(x)), h(b);
  }, O = () => {
    if (!v)
      return;
    let b = new Date(f.getFullYear() + 1, f.getMonth());
    b.getTime() >= S && (b = new Date(S)), h(b);
  }, z = (b) => a.find((R) => mt(b, R)) != null, W = Yt(() => {
    const R = /* @__PURE__ */ new Date(), $ = new Date(f.getFullYear(), f.getMonth(), 1), E = $.getDay();
    let H = 0;
    i && E === 6 && (H = 7);
    const ge = new Date($.getTime() - E * 864e5 + H * 864e5), Ce = [];
    for (let xe = 0; xe < 42; xe++) {
      const J = new Date(ge.getTime() + xe * 864e5);
      if (xe > 7 && (J.getDay() === 0 || i && J.getDay() === 1) && J.getMonth() !== f.getMonth())
        break;
      if (i && (J.getDay() === 0 || J.getDay() === 6))
        continue;
      const nt = J.getDay() === 0 || J.getDay() === 6, j = To(J, f), _ = mt(J, R), U = mt(J, n), me = o.find((Tt) => mt(J, Tt.date)), he = J.getTime() < x || J.getTime() > S;
      me != null ? Ce.push({ date: J, colorCode: me.colorCode, isCurrentMonth: j, isToday: _, isWeekend: nt, isSelected: U, isOutOfBounds: he }) : Ce.push({ date: J, isCurrentMonth: j, isToday: _, isSelected: U, isWeekend: nt, isOutOfBounds: he });
    }
    return Ce;
  }, [f, o, i, n, x, S]), Z = (b) => {
    c != null && c(b.date);
  };
  return /* @__PURE__ */ g("div", { className: k(m, u ? d ? "w-36" : "w-48" : ""), children: [
    u ? d ? /* @__PURE__ */ g("div", { className: "flex items-center justify-center py-0 my-0", children: [
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !w, Icon: Dt, onClick: F, small: !0, className: "!p-0 mx-1" }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !w, Icon: Je, onClick: C, small: !0, className: "!p-0 mr-1" }),
      /* @__PURE__ */ l("h2", { className: "w-full py-1 text-xs font-semibold leading-6 text-center text-gray-900 dark:text-dark-200", children: N.fromJSDate(f).toFormat("MMM yyyy") }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !v, Icon: Fe, onClick: T, small: !0, className: "!p-0 ml-1" }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !v, Icon: It, onClick: O, small: !0, className: "!p-0 mx-1" })
    ] }) : /* @__PURE__ */ g("div", { className: "flex items-center justify-center", children: [
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !w, Icon: Dt, onClick: F, className: "!p-0 -mr-1 ml-1" }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !w, Icon: Je, onClick: C, className: "!p-0 mr-1" }),
      /* @__PURE__ */ l("h2", { className: "w-full text-xs font-semibold leading-6 text-center text-gray-900 dark:text-dark-200", children: N.fromJSDate(f).toFormat("MMMM yyyy") }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !v, Icon: Fe, onClick: T, className: "!p-0 ml-1" }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !v, Icon: It, onClick: O, className: "!p-0 -ml-1 mr-1" })
    ] }) : /* @__PURE__ */ g("div", { className: "flex items-center justify-center", children: [
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !w, Icon: Dt, onClick: F, className: "!p-0 -mr-1 ml-1" }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !w, Icon: Je, onClick: C, className: "!p-0 mr-1" }),
      /* @__PURE__ */ l("h2", { className: "w-full text-sm font-semibold leading-6 text-center text-gray-900 dark:text-dark-200", children: N.fromJSDate(f).toFormat("MMMM yyyy") }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !v, Icon: Fe, onClick: T, className: "!p-0 ml-1" }),
      /* @__PURE__ */ l(A, { transparent: !0, disabled: !v, Icon: It, onClick: O, className: "!p-0 -ml-1 mr-1" })
    ] }),
    /* @__PURE__ */ g("div", { className: k(
      "grid text-xs leading-6 text-center",
      u ? d ? "mt-0 text-xs" : "mt-1 text-sm" : "mt-2 text-sm",
      i ? "grid-cols-5" : "grid-cols-7"
    ), children: [
      !i && /* @__PURE__ */ l("div", { children: "S" }),
      /* @__PURE__ */ l("div", { children: "M" }),
      /* @__PURE__ */ l("div", { children: "T" }),
      /* @__PURE__ */ l("div", { children: "W" }),
      /* @__PURE__ */ l("div", { children: "T" }),
      /* @__PURE__ */ l("div", { children: "F" }),
      !i && /* @__PURE__ */ l("div", { children: "S" })
    ] }),
    /* @__PURE__ */ l("div", { className: k(
      "grid gap-px bg-gray-200 dark:bg-dark-700 text-gray-700 dark:text-dark-300 rounded-lg isolate ring-1 ring-gray-200 dark:ring-dark-500",
      u ? d ? "mt-0 pt-0 text-xs" : "mt-1 text-sm" : "mt-2 text-sm",
      i ? "grid-cols-5" : "grid-cols-7"
    ), children: W.map((b, R) => /* @__PURE__ */ l(
      "button",
      {
        type: "button",
        className: k(
          "hover:bg-brand-500/20 focus:z-10 border-0 rounded-none p-0",
          u && d ? "py-0.5 px-0 mx-0" : "py-1.5",
          c == null ? "cursor-default" : b.isOutOfBounds ? "cursor-not-allowed" : "cursor-pointer",
          b.isCurrentMonth && !b.isOutOfBounds ? "" : "text-gray-500 dark:text-dark-400",
          b.colorCode ?? (b.isCurrentMonth ? b.isWeekend ? "bg-gray-100 dark:bg-dark-800" : "bg-white dark:bg-dark-900" : "bg-gray-300 dark:bg-dark-700"),
          b.isToday && "text-brand-500 dark:text-brand-400 font-bold",
          b.isSelected && "ring-inset ring-2 ring-brand-600 dark:ring-brand-400 bg-brand-500/15 dark:bg-brand-500/15",
          R === 0 && "rounded-tl-lg",
          R === (i ? 4 : 6) && "rounded-tr-lg",
          R === W.length - (i ? 5 : 7) && "rounded-bl-lg",
          R === W.length - 1 && "rounded-br-lg",
          b.colorCode ?? ""
        ),
        onClick: () => {
          b.isOutOfBounds || Z(b);
        },
        children: /* @__PURE__ */ l(
          "time",
          {
            dateTime: b.date.toDateString(),
            className: k(
              "mx-auto flex items-center justify-center rounded-full",
              u && d ? "h-5 w-5" : "h-7 w-7",
              z(b.date) && b.isToday && "bg-brand-600 text-white",
              z(b.date) && !b.isToday && "bg-gray-900 text-white dark:bg-dark-200 dark:text-dark-900"
            ),
            children: b.date.getDate()
          }
        )
      },
      R
    )) })
  ] });
}
const Co = (t, e) => {
  const { id: n, name: r, type: s = "text", outerClassName: a, minDate: i, maxDate: o, binding: c, label: u, value: d, placeholder: m, showClear: f = !1, showToday: h = !1, className: x, autofocus: S = !1, invalid: w = !1, disabled: v = !1, Icon: C, columns: T = 6, onChange: F } = t, [O, z] = q(!1), W = d ?? (c == null ? void 0 : c[0]) ?? void 0, Z = W != null ? N.fromJSDate(W).toFormat("M / d / yyyy") : "", b = F ?? (c == null ? void 0 : c[1]) ?? void 0, R = k(ae(T), a), $ = k(
    v ? "opacity-50 cursor-not-allowed disabled:cursor-not-allowed" : "",
    // invalid ? 'border focus-visible:border-2 bg-red-100 ring-red-500 border-red-500 focus-visible:border-red-500 focus-visible:ring-red-600' : '',
    w && C == null ? "twc-invalid" : "",
    x,
    C != null ? "twc-inner-input" : ""
  ), E = k(
    "flex items-center twc-pseudoinput",
    w ? "twc-invalid" : ""
  ), H = {};
  n != null && (H.id = n), r != null && (H.name = r), $ !== "" && (H.className = $), m != null && (H.placeholder = m), S && (H.autoFocus = S);
  const ge = () => {
    z(!O);
  }, Ce = () => {
    b != null && b(N.now().startOf("day").toJSDate()), z(!1);
  }, xe = () => {
    b != null && b(void 0), z(!1);
  };
  return /* @__PURE__ */ l(P, { children: /* @__PURE__ */ g("div", { className: R, children: [
    u != null && /* @__PURE__ */ l("label", { htmlFor: n, children: u }),
    /* @__PURE__ */ l("div", { className: u != null ? "mt-2" : "", children: C == null ? /* @__PURE__ */ l(
      "input",
      {
        ref: e,
        type: s,
        disabled: v,
        value: Z,
        onClick: ge,
        ...H
      }
    ) : /* @__PURE__ */ g("div", { className: E, children: [
      /* @__PURE__ */ l(C, { className: "w-5 h-5 mr-2 text-gray-500 dark:text-dark-300" }),
      /* @__PURE__ */ l(
        "input",
        {
          ref: e,
          type: s,
          disabled: v,
          value: Z,
          onClick: ge,
          ...H
        }
      )
    ] }) }),
    /* @__PURE__ */ g(Na, { open: O, className: k("sm:text-sm"), children: [
      /* @__PURE__ */ l(
        So,
        {
          selectedDay: W,
          embedded: !0,
          small: !0,
          minDate: i,
          maxDate: o,
          onSelect: (J) => {
            b != null && b(J), z(!1);
          }
        }
      ),
      (h || f) && /* @__PURE__ */ g("div", { className: "flex flex-row justify-between w-full pt-2", children: [
        h && /* @__PURE__ */ l(A, { label: "Today", small: !0, onClick: Ce }),
        f && /* @__PURE__ */ l(A, { label: "Clear", small: !0, onClick: xe })
      ] })
    ] })
  ] }) });
}, hl = ee(Co);
function fl(t) {
  const {
    className: e,
    button: n,
    buttonClass: r = "text-gray-900 dark:text-dark-200 hover:text-black dark:hover:text-white",
    label: s,
    altText: a,
    Icon: i,
    imageUrl: o,
    widthClass: c = "w-32",
    rightClass: u = "top-0 -right-32 origin-top-left",
    items: d,
    right: m = !1,
    Link: f = Jt ?? An
  } = t, h = k(
    "absolute z-10 rounded-md py-2",
    m ? u : "mt-2.5 origin-top-right",
    "bg-white dark:bg-dark-700 shadow-lg ring-1 ring-gray-900/5 dark:ring-black focus:outline-none",
    c
  ), x = k(
    "-m-1.5 flex items-center p-1.5 focus:outline-none focus:ring-0 hover:ring-0 hover:border-0 border-0",
    r
  ), S = (w, v) => {
    const C = k(
      "block px-3 py-1 text-sm leading-6 cursor-pointer",
      v || w.active ? "text-brand-600 dark:text-brand-400 hover:text-white hover:dark:text-white" : "text-gray-600 dark:text-dark-300 hover:text-white hover:bg-gray-800 hover:dark:bg-dark-800",
      v ? "bg-gray-800 dark:bg-dark-800" : ""
    ), T = /* @__PURE__ */ g(P, { children: [
      w.Icon != null && /* @__PURE__ */ l(w.Icon, { className: "inline-block w-5 h-5 mr-2", "aria-hidden": "true" }),
      w.name != null && /* @__PURE__ */ l("span", { children: w.name })
    ] });
    return /* @__PURE__ */ l(Pe.Item, { children: w.to != null ? /* @__PURE__ */ l(f, { to: w.to, className: C, onClick: w.onClick, children: T }) : w.href != null ? /* @__PURE__ */ l("a", { href: w.href, className: C, onClick: w.onClick, children: T }) : /* @__PURE__ */ l("div", { className: C, onClick: w.onClick, children: T }) });
  };
  return /* @__PURE__ */ g(Pe, { as: "div", className: k("relative", e), children: [
    n != null ? /* @__PURE__ */ l(P, { children: n }) : /* @__PURE__ */ g(Pe.Button, { className: x, children: [
      a != null && /* @__PURE__ */ l("span", { className: "sr-only", children: a }),
      o != null && o.length > 0 && /* @__PURE__ */ l("img", { className: "object-cover w-8 h-8 mr-2 rounded-full bg-gray-50 dark:bg-dark-800", src: o, referrerPolicy: "no-referrer", alt: a }),
      i != null && /* @__PURE__ */ l("span", { className: "p-1 mr-2 rounded-full focus:outline-none focus:ring-0", children: /* @__PURE__ */ l(i, { className: "w-6 h-6", "aria-hidden": "true" }) }),
      s != null && /* @__PURE__ */ l("span", { className: "mr-2", children: s }),
      /* @__PURE__ */ l("span", { className: "hidden mr-2 -ml-1 lg:flex lg:items-center", children: m ? /* @__PURE__ */ l(Fe, { className: "hidden w-5 h-5 xl:block", "aria-hidden": "true" }) : /* @__PURE__ */ l(hs, { className: "hidden w-5 h-5 xl:block", "aria-hidden": "true" }) })
    ] }),
    /* @__PURE__ */ l(
      Le,
      {
        as: Ge,
        enter: "transition ease-out duration-100",
        enterFrom: "transform opacity-0 scale-95",
        enterTo: "transform opacity-100 scale-100",
        leave: "transition ease-in duration-75",
        leaveFrom: "transform opacity-100 scale-100",
        leaveTo: "transform opacity-0 scale-95",
        children: d != null && /* @__PURE__ */ l(Pe.Items, { className: h, children: d.map((w, v) => /* @__PURE__ */ l(Pe.Item, { children: ({ active: C }) => S(w, C) }, w.id ?? w.name ?? v)) })
      }
    )
  ] });
}
var ce = /* @__PURE__ */ ((t) => (t.Light = "light", t.Dark = "dark", t.System = "system", t))(ce || {});
const Mo = (t, e) => {
  const { title: n, titleId: r } = t;
  return /* @__PURE__ */ V(
    "svg",
    Object.assign(
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 18 17.81",
        strokeWidth: 0.75,
        stroke: "currentColor",
        "aria-hidden": "true",
        ref: e,
        "aria-labelledby": r
      },
      t
    ),
    n ? /* @__PURE__ */ V("title", { id: r }, n) : null,
    /* @__PURE__ */ V("path", {
      d: "M13.13.38v1.12M16.31,1.69l-.8.8M17.63,4.88h-1.12M16.31,8.06l-.8-.8M13.13,8.25v1.12M10.74,7.26l-.8.8M9.75,4.88h-1.12M10.74,2.49l-.8-.8M15,4.88c0,1.04-.84,1.88-1.88,1.88s-1.88-.84-1.88-1.88.84-1.88,1.88-1.88,1.88.84,1.88,1.88Z"
    }),
    /* @__PURE__ */ V("path", {
      d: "M9.75,14.43c-.59.25-1.23.37-1.88.37-2.69,0-4.88-2.18-4.88-4.88,0-.66.13-1.3.37-1.88-1.82.76-3,2.53-3,4.5,0,2.69,2.18,4.88,4.88,4.88,1.97,0,3.74-1.18,4.5-3Z"
    }),
    /* @__PURE__ */ V("line", {
      x1: "1.88",
      y1: "3",
      x2: "14.79",
      y2: "15.9"
    })
  );
}, Oo = ee(Mo);
let an = ce.System;
try {
  const t = localStorage.getItem("theme");
  t != null && (an = t);
} catch (t) {
  console.error("Error reading theme from localStorage", t);
}
const Do = Rn({
  mode: an,
  setMode: () => {
    throw new Error("ThemeProviderContext not initialized");
  },
  menu: []
});
function Io() {
  try {
    localStorage.theme = "dark";
  } catch (t) {
    console.error("Error setting dark theme to localStorage", t);
  }
  document.documentElement.classList.add("dark");
}
function Eo() {
  try {
    localStorage.theme = "light";
  } catch (t) {
    console.error("Error setting light theme to localStorage", t);
  }
  document.documentElement.classList.remove("dark");
}
function Lo() {
  try {
    localStorage.removeItem("theme");
  } catch (t) {
    console.error("Error removing theme from localStorage", t);
  }
  window.matchMedia("(prefers-color-scheme: dark)").matches ? document.documentElement.classList.add("dark") : document.documentElement.classList.remove("dark");
}
function gl(t) {
  const { children: e } = t, [n, r] = q(an), s = Yt(() => ({
    mode: n,
    setMode: (a) => {
      switch (a) {
        case ce.Light:
          Eo();
          break;
        case ce.Dark:
          Io();
          break;
        case ce.System:
          Lo();
          break;
      }
      r(a);
    },
    menu: [
      { name: "Light", Icon: As, onClick: () => {
        s.setMode(ce.Light);
      }, active: n === ce.Light },
      { name: "Dark", Icon: Is, onClick: () => {
        s.setMode(ce.Dark);
      }, active: n === ce.Dark },
      { name: "System", Icon: Oo, onClick: () => {
        s.setMode(ce.System);
      }, active: n === ce.System }
    ]
  }), [n, r]);
  return /* @__PURE__ */ l(Do.Provider, { value: s, children: e });
}
const Fo = Rn({
  showWarning: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  },
  showQuestion: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  },
  showInfo: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  },
  showError: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  },
  popupError: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  },
  popupWarning: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  },
  popupInfo: () => {
    throw new Error("WARNING: GlobalDialog.Provider not initialized");
  }
});
let qe = 0, Ie = 0;
function wl(t) {
  const { children: e } = t, [n, r] = q(""), [s, a] = q(""), [i, o] = q(""), [c, u] = q(""), [d, m] = q(!1), [f, h] = q(!1), [x, S] = q(!1), [w, v] = q(), [C, T] = q(), [F, O] = q(!1), [z, W] = q(""), Z = () => {
    m(!1), C != null && C(), v(void 0), T(void 0);
  }, b = () => {
    m(!1), w != null && w(), v(void 0), T(void 0);
  }, R = () => {
    h(!1), C != null && C(), v(void 0), T(void 0);
  }, $ = () => {
    h(!1), w != null && w(), v(void 0), T(void 0);
  }, E = () => {
    S(!1), w != null && w(), v(void 0);
  }, H = () => {
    O(!1), w != null && w(), v(void 0);
  }, ge = (j, _, U, me, he, Tt, qr, Yr, Gr, Jr, _r, Qr, rt = !1) => {
    if (qe >= 3)
      return;
    rt && Ie >= 1 && (U = 3e4);
    const St = document.getElementById("global-toasters");
    if (St != null) {
      let ie = document.createElement("div");
      St.appendChild(ie);
      const Ct = () => {
        ie != null && (ie.innerHTML = "", ie.style.height = "0", setTimeout(() => {
          ie != null && (St.removeChild(ie), ie = null, qe = Math.max(qe - 1, 0), rt && (Ie = Math.max(Ie - 1, 0)));
        }, 150));
      };
      ie.addEventListener("click", Ct), ie.classList.add("transition-[height]", "linear", "delay-100", "h-auto", me, he, "border", Tt, qr, "gap-x-3", "rounded-lg", "p-2", "m-2", "flex", "w-full", "flex-row", "items-center", "justify-items-center"), ie.innerHTML = `
        ${Qr}
        <div class='flex-grow'>
          <div class='${Jr} ${_r} font-bold text-sm'>
            ${j}
          </div>
          <div class='${Yr} ${Gr} text-xs'>
            ${_}
          </div>
          ${rt && Ie >= 1 ? `
          <div class='text-red-800 dark:text-red-100 text-xs'>
            <a class='text-red-800 dark:text-red-300 hover:text-red-500 dark:hover:text-red-500 font-bold cursor-pointer text-xs' onClick='location.reload()'>Reloading page</a> is highly recommended
          </div>
          ` : ""}
        </div>
      `;
      const Mt = document.createElement("div");
      Mt.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 min-w-4 text-gray-400 dark:text-dark-300 hover:text-gray-900 dark:hover:text-white cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>`, Mt.addEventListener("click", Ct), ie.appendChild(Mt), qe = Math.min(qe + 1, 3), rt && (Ie = Math.min(Ie + 1, 3)), setTimeout(Ct, U);
    }
  }, nt = {
    showWarning: (j, _, U, me, he) => {
      r(j), a(_), o(U), v(() => () => {
        me != null && me();
      }), T(() => () => {
        he != null && he();
      }), m(!0);
    },
    showQuestion: (j, _, U, me, he) => {
      r(j), a(_), u(U), v(() => () => {
        me != null && me();
      }), T(() => () => {
        he != null && he();
      }), h(!0);
    },
    showInfo: (j, _, U) => {
      r(j), a(_), v(() => () => {
        U != null && U();
      }), S(!0);
    },
    showError: (j, _, U) => {
      r(j), W(_), v(() => () => {
        U != null && U();
      }), O(!0);
    },
    popupError: (j, _, U = 1e4) => {
      ge(j, _, U, "bg-red-100", "dark:bg-red-800", "border-red-600", "dark:border-red-200", "text-red-800", "dark:text-red-100", "text-red-600", "dark:text-red-300", `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 min-w-6 text-red-600 dark:text-red-300">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
      </svg>
    `, !0);
    },
    popupWarning: (j, _, U = 7e3) => {
      ge(j, _, U, "bg-yellow-100", "dark:bg-yellow-800", "border-yellow-600", "dark:border-yellow-200", "text-yellow-800", "dark:text-yellow-100", "text-yellow-600", "dark:text-yellow-300", `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 min-w-6 text-yellow-600 dark:text-yellow-300">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12V8.25Z" />
      </svg>
    `);
    },
    popupInfo: (j, _, U = 5e3) => {
      ge(j, _, U, "bg-green-100", "dark:bg-green-800", "border-green-600", "dark:border-green-200", "text-green-800", "dark:text-green-100", "text-green-600", "dark:text-green-300", `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 min-w-6 text-green-600 dark:text-green-300">
        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
      </svg>
    `);
    }
  };
  return /* @__PURE__ */ g(Fo.Provider, { value: nt, children: [
    /* @__PURE__ */ l("div", { className: "absolute top-4 right-4 z-50 w-60 sm:w-72 md:w-96", id: "global-toasters" }),
    d && /* @__PURE__ */ l(
      st,
      {
        open: d,
        title: n,
        Icon: Bn,
        onClose: Z,
        children: s,
        iconClassName: "rounded-full text-red-500 bg-yellow-200 dark:text-red-400 dark:bg-yellow-500/30 w-10 h-10 p-1",
        buttons: /* @__PURE__ */ g(P, { children: [
          /* @__PURE__ */ l(A, { className: "sm:ml-3", label: i, warning: !0, onClick: b }),
          /* @__PURE__ */ l(A, { className: "sm:ml-3", label: "Cancel", onClick: (j) => {
            Z(), j.stopPropagation(), j.nativeEvent.stopImmediatePropagation();
          } })
        ] })
      }
    ),
    f && /* @__PURE__ */ l(
      st,
      {
        open: f,
        title: n,
        Icon: $s,
        onClose: R,
        children: s,
        iconClassName: "rounded-full text-brand-600 dark:text-brand-500 w-10 h-10 p-1",
        buttons: /* @__PURE__ */ g(P, { children: [
          /* @__PURE__ */ l(A, { className: "sm:ml-3", label: c, primary: !0, onClick: $ }),
          /* @__PURE__ */ l(A, { className: "sm:ml-3", label: "Cancel", onClick: R })
        ] })
      }
    ),
    x && /* @__PURE__ */ l(
      st,
      {
        open: x,
        title: n,
        Icon: _t,
        onClose: E,
        children: s,
        iconClassName: "rounded-full text-brand-600 dark:text-brand-500 w-10 h-10 p-1",
        buttons: /* @__PURE__ */ l(P, { children: /* @__PURE__ */ l(A, { className: "sm:ml-3", label: "OK", primary: !0, onClick: E }) })
      }
    ),
    F && /* @__PURE__ */ l(
      st,
      {
        open: F,
        title: n,
        message: z,
        Icon: xs,
        iconClassName: "rounded-full text-red-500 bg-red-200 dark:text-red-400 dark:bg-red-500/30 w-10 h-10 p-1",
        onClose: H,
        buttons: /* @__PURE__ */ l(P, { children: /* @__PURE__ */ l(A, { className: "sm:ml-3", label: "OK", primary: !0, onClick: H }) })
      }
    ),
    e
  ] });
}
const yl = {
  HandHoldingMagicIcon: na,
  SpinnerIcon: aa,
  WandMagicSparklesIcon: ma,
  BuildingIcon: pa
}, pl = {
  SpinnerIcon: oa
};
export {
  rl as Breadcrumbs,
  pa as BuildingIcon,
  A as Button,
  el as Card,
  Qo as CheckBox,
  cl as ComboBox,
  Xo as Container,
  il as ContainerItem,
  ol as DarkModeButton,
  hl as DatePickerBox,
  st as Dialog,
  fl as DropDownMenu,
  Jo as EmailBox,
  sl as FileDrop,
  Yo as Form,
  Js as FormButtons,
  Fo as GlobalDialogContext,
  wl as GlobalDialogProvider,
  Jt as GlobalLink,
  na as HandHoldingMagicIcon,
  qo as ImageButton,
  Xs as ImageFileDropButton,
  al as ImageFileDropInput,
  tl as LoadingComponent,
  ca as LoadingIcon,
  ml as Mask,
  on as MessagePage,
  So as MiniCalendar,
  ll as MonthlyCalendar,
  nl as PageHeader,
  Go as PasswordBox,
  Na as Picker,
  Ko as PictureUrlBox,
  dl as RadioButtons,
  ul as SelectBox,
  oa as SpinnerIcon,
  _o as TextArea,
  jn as TextBox,
  Do as ThemeContext,
  ce as ThemeMode,
  gl as ThemeProvider,
  ma as WandMagicSparklesIcon,
  k as classes,
  Ho as columnClass,
  Uo as fileToDataUrl,
  Hn as gridClass,
  Po as lgColumnClass,
  Bo as mdColumnClass,
  yl as outlineIcons,
  Ao as setGlobalLink,
  jo as sleep,
  ae as smColumnClass,
  pl as solidIcons,
  Zo as truncateString
};
