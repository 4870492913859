import { useEffect, type ReactElement, useState, useContext, useMemo, useCallback } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import logo from '../assets/logo.svg'
import { ArrowRightStartOnRectangleIcon, ArrowsRightLeftIcon, CloudArrowUpIcon, Cog6ToothIcon, EnvelopeIcon, LifebuoyIcon, ListBulletIcon, PencilSquareIcon, Square3Stack3DIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/24/outline'
import { type RootState, useAppDispatch } from '../store'
import { AuthState, logout } from '../reducers/authReducer'
import MultiColumnTopBarLayout, { type MenuItemProps } from './MultiColumnTopBarLayout'
import { useSelector } from 'react-redux'
import TenantDialog from '../components/TenantDialog'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { rootLog } from '../logging'
import { type DropDownMenuItem } from '@andyneville/tailwind-react/DropDownMenu'
import { AccountRole, type ISeasonAthleteWithAthlete, TenantPlan, TenantRole, TenantStatus } from '../../../api/api'
import { GlobalDialogContext, ThemeContext } from '@andyneville/tailwind-react'
import { clear as clearSeason } from '../reducers/seasonReducer'
import FeedbackDialog from '../components/FeedbackDialog'
import { LogsDialog } from '../components/LogsDialog'

const currentWebVersion = import.meta.env.VITE_VERSION
const currentWebHash = import.meta.env.VITE_HASH
const checkVersion = import.meta.env.VITE_CHECK_VERSION === 'true'

const log = rootLog.child({ module: 'AppLayout' })

const navigation = [
  { name: 'Roster', route: '/', Icon: ListBulletIcon },
  { name: 'Teams', route: '/teams', Icon: UserGroupIcon },
  { name: 'Settings', route: '/settings', Icon: Cog6ToothIcon }
]

const adminNavigation = [
  { name: 'Users', route: '/admin/users', Icon: UsersIcon },
  { name: 'Orgs', route: '/admin/organizations', Icon: Square3Stack3DIcon }
]

export default function AppLayout ({ hFull = false }): ReactElement {
  const dispatch = useAppDispatch()
  const { authState, isAdmin, pictureUrl, currentTenant, roles = [], webVersion } = useSelector((state: RootState) => state.auth)
  const { tenants, athletes } = useContext(TenantSeasonContext)
  const { menu: themeMenu } = useContext(ThemeContext)
  const globalDialog = useContext(GlobalDialogContext)
  const navigate = useNavigate()
  log.debug('Current web version', currentWebVersion, 'Latest web version', webVersion)

  log.debug('AppLayout: authState', authState, 'isAdmin', isAdmin)
  const [showSwitchTenant, setShowSwitchTenant] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [showLogs, setShowLogs] = useState(false)
  const signout = useCallback(() => {
    globalDialog.showQuestion('Log Out?', 'Are you sure you want to log out?', 'Logout', () => {
      dispatch(clearSeason())
      void dispatch(logout())
    })
  }, [dispatch, globalDialog])

  const navigationMenu: MenuItemProps[] = useMemo(() => {
    if (authState === AuthState.AuthenticatedNeedsTenantChoice || currentTenant?.status === TenantStatus.Onboarding) {
      return []
    }
    if (authState === AuthState.Authenticated && currentTenant?.expiration != null && new Date(currentTenant.expiration) < new Date()) {
      return []
    }
    const items: MenuItemProps[] = [...navigation]
    if (roles.includes(TenantRole.Owner) || roles.includes(TenantRole.Manager) || roles.includes(AccountRole.SuperAdministrator) || roles.includes(AccountRole.Administrator)) {
      items.push({ name: 'Staff', route: '/staff', Icon: UsersIcon })
    }
    return items
  }, [roles, currentTenant, authState])

  const helpMenu: DropDownMenuItem[] = useMemo(() => {
    const items: DropDownMenuItem[] = []
    if (authState === AuthState.Authenticated) {
      items.push({ name: 'Help / Feedback', onClick: () => { setShowFeedback(true) }, Icon: PencilSquareIcon })
    }
    items.push({ name: 'Email Support', href: 'mailto:support@cheersync.app', Icon: EnvelopeIcon })
    items.push({ name: 'Troubleshooting Logs', onClick: () => { setShowLogs(true) }, Icon: LifebuoyIcon })
    items.push({ name: 'Local Videos', to: '/localVideos', Icon: CloudArrowUpIcon })
    return items
  }, [authState])

  const userMenu: DropDownMenuItem[] = useMemo(() => {
    const items: DropDownMenuItem[] = [...themeMenu]
    if (authState === AuthState.Authenticated) {
      items.push({ name: 'Settings', to: '/settings', Icon: Cog6ToothIcon })
    }
    if (tenants != null && tenants.length > 1) {
      items.push({ name: 'Switch Org', Icon: ArrowsRightLeftIcon, onClick: () => { setShowSwitchTenant(true) } })
    }

    if (authState === AuthState.Authenticated) {
      items.push({ name: 'Logout', onClick: () => { signout() }, Icon: ArrowRightStartOnRectangleIcon })
    }
    return items
  }, [signout, tenants, authState, themeMenu])

  const onAthleteSelected = useCallback((athlete: ISeasonAthleteWithAthlete) => {
    if (athlete?.id != null && athlete.id !== '') {
      navigate(`/tryout/${athlete.id}`)
    }
  }, [navigate])

  const adminNavigationItems = isAdmin ? adminNavigation : undefined

  useEffect(() => {
    if (hFull) {
      document.body.classList.add('h-full')
      document.getElementById('root')?.classList.add('h-full')
      // document.body.parentElement?.classList.add('bg-gray-100')
      document.body.parentElement?.classList.add('h-full')
      return () => {
        document.body.classList.remove('h-full')
        document.getElementById('root')?.classList.remove('h-full')
        // document.body.parentElement?.classList.remove('bg-gray-100')
        document.body.parentElement?.classList.remove('h-full')
      }
    }
  }, [hFull])

  // console.log('plan', currentTenant?.plan, currentTenant?.expiration)
  const expiration = currentTenant?.expiration != null ? new Date(currentTenant.expiration) : undefined
  return (
    <MultiColumnTopBarLayout
      hFull={hFull}
      navigation={navigationMenu}
      adminNavigation={adminNavigationItems}
      userMenu={userMenu}
      helpMenu={helpMenu}
      athletes={athletes}
      onSelect={onAthleteSelected}
      trialExpiration={currentTenant?.plan === TenantPlan.Trial ? expiration : undefined}
      planExpiration={currentTenant?.plan !== TenantPlan.New && currentTenant?.plan !== TenantPlan.Trial ? expiration : undefined}
      pictureUrl={pictureUrl}
      orgLogoUrl={currentTenant?.logo}
      hideMenu={authState !== AuthState.Authenticated}
      signout={signout}
      shouldUpgrade={checkVersion && currentWebVersion != null && webVersion != null && currentWebVersion !== webVersion}
      webVersion={`${currentWebVersion}/${currentWebHash}`}
      logo=<Link to="/" className="-m-1.5 p-1.5 bg-brand-100 rounded-full h-10 w-10 flex items-center justify-center bg-gradient-to-br from-brand-50 to-brand-200">
        <span className="sr-only">CheerSync</span>
        <img
          className="w-auto h-6 mt-1"
          src={logo}
          alt=""
        />
      </Link>
      appName='CheerSync'
    >
      <LogsDialog open={showLogs} onClose={() => { setShowLogs(false) }} />
      <FeedbackDialog open={showFeedback} onClose={() => { setShowFeedback(false) }} />
      <TenantDialog open={showSwitchTenant} onClose={() => { setShowSwitchTenant(false) }} />
      <Outlet />
    </MultiColumnTopBarLayout>
  )
}
